<script>
  import { link } from "svelte-routing";
  import {guiText} from "../../tstore/guiText";    
  import {myAuthObject} from "../../tstore/myauth";
  import {checkForRoleAdmin, checkForRoleAudience, checkForRoleProject } from '../../lib/checkAuthObject.js';  

  export let my_lang;

  const roleAdminStatus = checkForRoleAdmin($myAuthObject);
  const roleAudienceStatus = checkForRoleAudience($myAuthObject);
  const roleProjectStatus = checkForRoleProject($myAuthObject);

  let defaultButtonSB = "text-xs py-3 font-bold block prosper_text_a capitalize"; 

  // const gotoProsperConfiguration = () => { 
  //   let myUrl = `${$myAuthObject.uri_prosper_mn}/${$myAuthObject.whoami.uuid}/${$myAuthObject.projectId}/configuration?lang=${urlLang}&useRandomData=onlyData`;    
  //   window.open(myUrl, '_blank').focus(); 
  // };

  // const gotoProsperLandingPage = () => { 
  //   let myUrl = `${$myAuthObject.uri_prosper_mn}/${$myAuthObject.whoami.uuid}/${$myAuthObject.projectId}/prosperland?lang=${urlLang}&status=true&useRandomData=onlyData`;    
  //   window.open(myUrl, '_blank').focus();     
  // };

  // const gotoProsperForum = () => { 
  //   let myUrl = 'https://z327640.invisionservice.com/forum/155-thomson/';    
  //   window.open(myUrl, '_blank').focus();     
  // };

</script>


<!-- Navigation -->

<ul class="md:flex-col md:min-w-full flex flex-col list-none">


  {#if $myAuthObject['shop_status']['status'] === 'open' && ( roleAdminStatus === true || roleProjectStatus === true || roleAudienceStatus === true ) }
    <li class="items-center">
      <a use:link href="/admin/dashboard" class={defaultButtonSB} >
        <i class="fas fa-tv mr-2 text-sm text-blueGray-300"></i>
        {$guiText.general.dashboard[my_lang]} 
      </a> 
    </li>
  {/if}

  {#if $myAuthObject['shop_status']['status'] === 'open' && ( roleAdminStatus === true || roleProjectStatus === true ) }    
    <li class="items-center">
      <a use:link href="/admin/action_and_communication" class={defaultButtonSB} >
        <i class="fas fa-tower-cell mr-2 text-sm text-blueGray-300"></i>
        {$guiText.general.action_and_communication[my_lang]} 
      </a> 
    </li>
  {/if}

  {#if $myAuthObject['shop_status']['status'] === 'open' && ( roleAdminStatus === true || roleAudienceStatus === true || roleProjectStatus === true ) }
    <li class="items-center">
      <a use:link href="/admin/happiness" class={defaultButtonSB} >
        <i class="fas fa-chart-pie mr-2 text-sm text-blueGray-300"></i>
        {$guiText.general.happiness_index[my_lang]}
      </a>
    </li>          
  {/if}

  {#if $myAuthObject['shop_status']['status'] === 'open' && ( roleAdminStatus === true || roleProjectStatus === true ) }  
    <li class="items-center">
      <a use:link href="/admin/momentum_cx" class={defaultButtonSB}>
        <i class="fas fa-list-check mr-2 text-sm text-blueGray-300"></i>
          {$guiText.general.momentum_cx[my_lang]}
      </a>
    </li>
  {/if}

  {#if $myAuthObject['shop_status']['status'] === 'open' && ( roleAdminStatus === true || roleProjectStatus === true ) }  
    <li class="items-center">
      <a use:link href="/admin/momentum_ex" class={defaultButtonSB}>
        <i class="fas fa-list-check mr-2 text-sm text-blueGray-300"></i>
          {$guiText.general.momentum_ex[my_lang]}
      </a>
    </li>
  {/if}

  {#if $myAuthObject['shop_status']['status'] === 'open' && ( roleAdminStatus === true || roleAudienceStatus === true || roleProjectStatus === true) }  
    <hr  class="my-4 md:min-w-full">
    <h6 class="md:min-w-full text-blueGray-500 text-xs uppercase font-bold block pt-1 pb-4 no-underline"> 
      {$guiText.register.general.configure_your_product_label_x[my_lang]}
    </h6>

      <li class="items-center">
        <a use:link href="/admin/landingpage" class={defaultButtonSB}>
          <i class="fas fa-map mr-2 text-sm text-blueGray-300"></i>
          {$guiText.general.ecosystem[my_lang]} 
        </a>
      </li>
  {/if}

  {#if $myAuthObject['shop_status']['status'] === 'open' && ( roleAdminStatus === true || roleProjectStatus === true ) }  
    <li class="items-center">
      <a use:link href="/admin/configuration" class={defaultButtonSB}>
        <i class="fas fa-gear mr-2 text-sm text-blueGray-300"></i>
        {$guiText.general.configuration[my_lang]}
      </a>
    </li>
    
  {/if}

  {#if $myAuthObject['shop_status']['status'] === 'open' && ( roleAdminStatus === true || roleAudienceStatus === true || roleProjectStatus === true ) }
    <hr  class="my-4 md:min-w-full">          

    <li class="items-center">
      <a use:link href="/admin/knowledge_base" class={defaultButtonSB} >
        <i class="fas fa-wrench mr-2 text-sm text-blueGray-300" ></i>            
        {$guiText.general.knowledge_base[my_lang]}
      </a>
    </li>

    <li class="items-center">
      <a use:link href="/admin/faq" class={defaultButtonSB} >
        <i class="fas fa-question-circle mr-2 text-sm text-blueGray-300" ></i>            
        {$guiText.general.faq[my_lang]}
      </a>
    </li>

    <hr  class="my-4 md:min-w-full">          
  {/if}

  {#if $myAuthObject['shop_status']['status'] === 'open' && ( roleAdminStatus === true ) }
    <li class="items-center">
      <a use:link href="/admin/audience" class={defaultButtonSB} >
        <i class="fas fa-users mr-2 text-sm text-blueGray-300" ></i>            
        {$guiText.general.audience[my_lang]}
      </a>
    </li>
  {/if}

  {#if $myAuthObject['shop_status']['status'] === 'open' && ( roleAdminStatus === true || roleAudienceStatus === true || roleProjectStatus === true) }
    <li class="items-center">
      <a use:link href="/admin/profile" class={defaultButtonSB} >
        <i class="fas fa-user mr-2 text-sm text-blueGray-300"></i>
        {$guiText.general.profil[my_lang]}
      </a>
    </li>     
    
    <li class="items-center">
      <a use:link href="/admin/my_prosperland" class={defaultButtonSB} >
        <div style="overflow: hidden; text-overflow: ellipsis; white-space: nowrap;">
          <img alt="my propserland" class="max-w-full rounded-lg mr-2" src="../../assets/img/prosper3.svg" 
            width="16px" height="16px" style="float:left;  fill:#cbd5e1;" /> 
          {$guiText.general.my_prosperland[my_lang]} 
        </div>
      </a> 
    </li>


    <!--  
    <li class="items-center">
      <a use:link href="/admin/test_frame" class={defaultButtonSB} style="color:#ff0000;" >
        <div style="overflow: hidden; text-overflow: ellipsis; white-space: nowrap;">
        <i class="fas fa-check mr-2 text-sm"></i> 
            test_frame
        </div>
      </a>
    </li>
    -->  
      
  {/if}

</ul>

