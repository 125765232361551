<script>
  import { Router, Route } from "svelte-routing";

  // components for this layout
  import AuthNavbar from "components/Navbars/AuthNavbar.svelte";
  import FooterSmall from "components/Footers/FooterSmall.svelte";

  // pages for this layout
  import Login from "views/auth/Login.svelte";
  import Register from "views/auth/Register.svelte";
  import Otp from "views/auth/Otp.svelte";  
  import Password from "views/auth/Password.svelte";  
  import Company from "views/auth/Company.svelte";  
  import Project from "views/auth/Project.svelte";  
  import Product from "views/auth/Product.svelte";  
  import Template from "views/auth/Template.svelte"; 
  import User from "views/auth/User.svelte";  
  import Newpass from "views/auth/Newpass.svelte";  

  export let my_lang;

  let registerBg2 = "/assets/img/landingpage_bg_en.jpg";
  if (my_lang == "de") {
    registerBg2 = "/assets/img/landingpage_bg_de.jpg";        
    // registerBg2 = "/assets/img/register_bg_2.jpg";    
  }
  
</script>

<div>
  <AuthNavbar my_lang={my_lang}/>
  <main>
    <section class="relative w-full h-full py-40 min-h-screen">
      <div
        class="absolute top-0 w-full h-full bg-center bg-cover"
        style="background-image: url({registerBg2});"
      >
        <span
          id="blackOverlay"
          class="w-full h-full absolute opacity-50 bg-black"
        ></span>
      </div>
      <Router url="auth">
        <Route path="/"><Login my_lang={my_lang}/> </Route>
        <Route path="login"><Login my_lang={my_lang}/> </Route>
        <Route path="register"><Register my_lang={my_lang}/></Route>
        <Route path="otp"><Otp my_lang={my_lang}/></Route>
        <Route path="password"><Password my_lang={my_lang}/></Route>
        <Route path="company"><Company my_lang={my_lang}/></Route>
        <Route path="project"><Project my_lang={my_lang}/></Route>
        <Route path="product"><Product my_lang={my_lang}/></Route>
        <Route path="template"><Template my_lang={my_lang}/></Route>
        <Route path="user"><User my_lang={my_lang}/></Route>
        <Route path="newpass/:passtoken" let:params>
          <Newpass passtoken="{params.passtoken}" my_lang={my_lang}/>
        </Route>      
      </Router>
      <FooterSmall absolute="true" />
    </section>
  </main>
</div>
