import {apiMaster} from "../api/ApiMaster";  

export async function otpLoginPost (data, verificationDigits) {
    data.authO.loading = true;

    let otpString = '';
    for (let i = 0; i < verificationDigits.length; i++) {
        otpString += verificationDigits[i];
    }

    let requestOptions = {
        method: 'POST',
        headers: { 
            'Content-Type': 'application/json', 
            'X-Custom-Header': 'application/json' 
        },
        body: JSON.stringify({ 
          'userUuid': data.authO.userUuid, 
          'otp': otpString
          })
      };

    let resp = await apiMaster(
        data,
        `${data.authO.uri_prosper_api}/auth/otplogin`, 
        requestOptions
    );

    return resp;
}