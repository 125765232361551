<script>
  import { link } from "svelte-routing";

  // core components
  // import PagesDropdown from "components/Dropdowns/PagesDropdown.svelte";

  export let my_lang;

  let navbarOpen = false;
  // const img_logo_b = "../../assets/img/prosperland_logo.jpg"; 
  const img_logo_b = "../../assets/img/PPL-koml_ws_trans_edited.webp";

  function setNavbarOpen() {
    navbarOpen = !navbarOpen;
  }
</script>

<nav
  class="top-0 absolute z-50 w-full flex flex-wrap items-center justify-between px-2 py-1 navbar-expand-lg bg-white shadow"
>
  <div
    class="container px-4 mx-auto flex flex-wrap items-center justify-between"    
  >
    
    <!-- {my_lang} -->

    <div
    class="w-full lg:w-6/12 px-4 relative flex justify-between lg:w-auto lg:static lg:block lg:justify-start">
   


    <a
    use:link
    class="text-blueGray-700 text-sm font-bold leading-relaxed inline-block mr-4 py-1 whitespace-nowrap uppercase"
    href="/" >

    <img
      alt="propserland"
      class="max-w-full rounded-lg"
      src="{img_logo_b}"
      width="50%"
    />

  </a>
      <button
        class="cursor-pointer text-xl leading-none px-3 py-1 border border-solid border-transparent rounded bg-transparent block lg:hidden outline-none focus:outline-none"
        type="button"
        on:click="{setNavbarOpen}"
      >
        <i class="text-white fas fa-bars"></i>
      </button>
    </div>

  </div>
</nav>
