import {apiMaster} from "../api/ApiMaster";  

export async function surveysGet (data) {
    data.authO.loading = true;

    let requestOptions = {
        method: 'GET',
        headers: { 
            'Content-Type': 'application/json', 
            'X-Custom-Header': 'application/json',
            'Authorization' : `Bearer ${data.authO.bearer}`
        }    
    };

    let resp = await apiMaster(
        data,
        `${data.authO.uri_prosper_api}/surveys`, 
        requestOptions
    );

    let surveysAry = await resp.data;

    resp['by_tenant'] = {};
    resp['by_tenant_survey'] = {};
    resp['by_tenant_bot'] = {};

    // das folgende if ... funktioniert lokal nicht !??
    // if (surveysAry > 0 ) {
    surveysAry.forEach( function(e) {
        if ( resp['by_tenant'].hasOwnProperty(e.tenant) === false ) {
            resp['by_tenant'][e.tenant] = []; }

        if ( resp['by_tenant_survey'].hasOwnProperty(e.tenant) === false ) {
            resp['by_tenant_survey'][e.tenant] = {}; }

        if ( resp['by_tenant_bot'].hasOwnProperty(e.tenant) === false ) {
            resp['by_tenant_bot'][e.tenant] = {}; }

        resp['by_tenant'][e.tenant].push(e);
        resp['by_tenant_survey'][e.tenant][e.surveyId] = e;
        resp['by_tenant_bot'][e.tenant][e.bot] = e;        

    });
    // } 

    return resp;
}
