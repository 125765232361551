import {apiMaster} from "../api/ApiMaster";  

export async function registrationPost (data) {
    data.authO.loading = true;

    let requestOptions = {
        method: 'POST',
        headers: { 
            'Content-Type': 'application/json', 
            'X-Custom-Header': 'application/json' 
        },
        body: JSON.stringify({ 
            'email': data.authO.email, 
            'firstName': data.authO.firstname, 
            'lastName': data.authO.lastname
            })
    };

    let resp = await apiMaster(
        data,
        `${data.authO.uri_prosper_api}/auth/register`, 
        requestOptions
    );
 
    return resp;
}