<script>
  import { navigate } from "svelte-routing";
  import {selectTextOnFocus, blurOnEscape} from '../../lib/inputDirectives.js';  
  import {myAuthObject} from "../../tstore/myauth";  
  import {guiText} from "../../tstore/guiText";
  import LightGreyButon from "../../components/Buttons/LightGreyButon.svelte";  
  import {passwordPut} from "../../api/PasswordPut";    
  // import {shopCustomerCreate} from "../../api/ShopCustomerCreate";

  export let my_lang;
  let myPassword = '';  
  let inputErrorMessage = '';  
  $myAuthObject.password = false;
  $myAuthObject.loading = false;

  const passwdRegex = RegExp( /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{7,}$/ );

  const checkRegPasswordInput = () => {
    inputErrorMessage = "";
    $myAuthObject.password = false;

    if ( passwdRegex.test(myPassword) === true ) {
      $myAuthObject.password = true;
      inputErrorMessage = "";        
    }        
    else {
      $myAuthObject.password = false;
      inputErrorMessage = $guiText.register.general.password_min_seven[my_lang];
    }
  }

  const checkButtonClick = () => {
    $myAuthObject.loading = true;
    inputErrorMessage = '';
    apiRunner();
  }

const apiRunner = async () => {
  let rawresp = await passwordPut({'authO': $myAuthObject, 'guiTxt': $guiText }, myPassword);
  let resp = await rawresp.data;

  if ( rawresp.status === true ) {
    $myAuthObject.passwordSet = true;
    $myAuthObject.userUuid = resp.uuid;
    $myAuthObject.passwordRaw = myPassword;

    /**  
    let shopRawResp = await shopCustomerCreate({'authO': $myAuthObject, 'guiTxt': $guiText });
    let shopResp = await shopRawResp.data;

    if ( shopRawResp.status === true && shopResp.status === 'success' ) {
      $myAuthObject.shopCusId = shopResp.customer_id;
      navigate('/auth/company', { replace: true });
    } else {
      inputErrorMessage = $guiText.register.general.failed_register_user_shop[my_lang];
      $myAuthObject.loading = false;
      $myAuthObject.inputReady = false;              
    }
    */

    // Fake, umgeht stripekomplett!
    const alphabet = "abcdefghijklmnopqrstuvwxyz0123456789";
    // wortlänge = 18
    let word = "";
    for (let i = 0; i < 18; i++) {
      word += alphabet[Math.floor(Math.random() * alphabet.length)];
    }
    $myAuthObject.shopCusId = word;
    navigate('/auth/company', { replace: true });
    
  } 
  else { 
    inputErrorMessage = rawresp.message; 
    $myAuthObject.loading = false; 
    $myAuthObject.password = false;
  }
}  

</script>

  <div class="container mx-auto px-4 h-full">
    <div class="flex content-center items-center justify-center h-full">
      <div class="w-full lg:w-6/12 px-4">
        <div class="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg bg-blueGray-200 border-0" >
          <div class="flex-auto px-4 lg:px-10 py-10 pt-0"> 
            <form>

              <div class="relative w-full py-5"> 
                <h3 class="text-2xl mb-2 font-semibold leading-none">
                  {$guiText.register.general.set_password_for_account[my_lang]}
                </h3>
              </div>

              <div class="relative w-full mb-3">              
                <label
                  class="block text-blueGray-400 text-sm mb-2"
                  for="grid-password"
                >                  
                {$guiText.register.general.choose_secure_password[my_lang]}
                </label>
                <input
                  id="grid-password"
                  type="password"
                  class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-900 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                  placeholder="{$guiText.general.password[my_lang]}"
                  bind:value={myPassword}
                  on:input={checkRegPasswordInput}
                  use:selectTextOnFocus 
                  use:blurOnEscape                   
                />
              </div>

              <div class="text-center mt-6">

                {#if $myAuthObject.password == true}
                  {#if $myAuthObject.loading === true}

                    <LightGreyButon bg_color = "bg-blueGray-200">
                      <div slot="button_text">... {$guiText.general.sending[my_lang]}</div>
                    </LightGreyButon>

                  {:else}

                    <button
                      class="bg-blueGray-800 text-white active:bg-blueGray-600 text-sm font-bold px-6 py-3 
                          rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 w-full ease-linear 
                          transition-all duration-150"
                      type="button"
                      on:click={checkButtonClick}                       
                    > 
                      {$guiText.general.letsgo[my_lang]}
                    </button>

                  {/if}
                {:else}

                  <LightGreyButon bg_color = "bg-blueGray-200">
                    <div slot="button_text">{$guiText.general.letsgo[my_lang]}</div>
                  </LightGreyButon>

                {/if}

              </div>

              {#if inputErrorMessage.length > 0 }
                <div>
                  <span class="block ml-2 text-xs text-red-400">
                    {inputErrorMessage}
                  </span>                  
                </div>
              {/if}

            </form>

          </div>
        </div>
      </div>
    </div>
  </div>
  
