<script>
    import { navigate } from "svelte-routing";    
    import {myAuthObject} from "../../tstore/myauth";
    import {guiText} from "../../tstore/guiText"; 
    import {selectTextOnFocus, blurOnEscape} from '../../lib/inputDirectives.js';    
    import LightGreyButon from "../../components/Buttons/LightGreyButon.svelte";

    export let my_lang;

    let inputErrorMessage = ''; 

    $myAuthObject.loading = false;

    const checkProjectInput = () => {
      if ( $myAuthObject.project.length > 3 && $myAuthObject.project.length <= 64) {        
        $myAuthObject.projectState = true;
        inputErrorMessage = '';
      } 
      else if ( $myAuthObject.project.length > 64 ){
        inputErrorMessage = $guiText.general.max_64_chr[my_lang];
        $myAuthObject.projectState = false;
      }
      else {
        inputErrorMessage = `${$guiText.general.min_4_chr[my_lang]} ${$guiText.general.max_64_chr[my_lang]}`;       
        $myAuthObject.projectState = false;
      }      
    }

    const checkButtonClick = () => {
      $myAuthObject.loading = true;
      if ( $myAuthObject.inputReady === true ) { apiRunner(); }
    }

    const apiRunner = async () => { navigate('/auth/product', { replace: true }); }

    </script>

    <div class="container mx-auto px-4 h-full">
      <div class="flex content-center items-center justify-center h-full">
        <div class="w-full lg:w-6/12 px-4">
          <div class="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg bg-blueGray-200 border-0" >
            <div class="flex-auto px-4 lg:px-10 py-10 pt-0">
              <form>
                <div class="relative w-full mb-3 py-5">

                  <h3 class="text-2xl mb-2 font-semibold leading-none">
                    {$guiText.register.general.first_project_on_prosper[my_lang]}
                  </h3>

                  <label
                    class="block text-blueGray-400 text-sm mb-2"
                    for="grid-project"
                  >
                    {$guiText.register.general.give_project_name_to_create[my_lang]} 
                  </label>
                  <input
                    id="grid-project"
                    type="text"
                    class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-900 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                    placeholder="{$guiText.register.general.project_placeholder[my_lang]}"
                    bind:value={$myAuthObject.project} 
                    on:input={checkProjectInput}
                    use:selectTextOnFocus 
                    use:blurOnEscape                    
                  />                  

                </div>
    
                <div class="text-center mt-6">

                  {#if $myAuthObject.projectState === true}
                    {#if $myAuthObject.loading === true}

                      <LightGreyButon bg_color = "bg-blueGray-200">
                        <div slot="button_text">... {$guiText.general.sending[my_lang]}</div>
                      </LightGreyButon>

                    {:else}

                      <button
                        class="bg-blueGray-800 text-white active:bg-blueGray-600 text-sm font-bold px-6 py-3 
                            rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 w-full ease-linear 
                            transition-all duration-150"
                        type="button"
                        on:click={checkButtonClick}                                             
                      > 
                        {$guiText.general.continue[my_lang]}
                      </button>
                    {/if}

                  {:else}

                    <LightGreyButon bg_color = "bg-blueGray-200">
                      <div slot="button_text">{$guiText.general.continue[my_lang]}</div>
                    </LightGreyButon>

                  {/if}

                </div>

                {#if inputErrorMessage.length > 0 }
                  <div>
                    <span class="block ml-2 text-xs text-red-400">
                      {inputErrorMessage}
                    </span>                  
                  </div>
                {/if}

              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
    