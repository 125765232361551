<script>
  // core components
  import RecipientsTable from "components/Cards/RecipientsTable.svelte";
  import { navigate } from "svelte-routing";  
  import { onMount } from "svelte";

  import {myAuthObject} from "../../tstore/myauth";
  import {checkForRoleAdmin} from '../../lib/checkAuthObject.js';   
  export let my_lang;

  const roleAdminStatus = checkForRoleAdmin($myAuthObject);

  onMount(async () => { 
    if ( roleAdminStatus === false ) {
      navigate('/admin/dashboard', { replace: true }); 
    }
  });


</script>

<div class="flex flex-wrap border-0"> <!-- mt-4 -->
  <div class="w-full px-4"> <!-- mb-12 -->

    {#if $myAuthObject['shop_status']['status'] === 'open' && roleAdminStatus === true} 
      <RecipientsTable my_lang="{my_lang}" />
    {/if}

  </div>
</div>
