<script>
  import { Pulse } from 'svelte-loading-spinners';     
  import { onMount } from "svelte";
  export let myTUrl;
  export let myHeight = "520px";

  onMount(async () => {

    const stringOnLoad = `
      const oneIslandBody = document.getElementById(\"one_island_body\");
      oneIslandBody.style.zIndex = 0;
      oneIslandBody.querySelectorAll(\"#loadingIndicator\").forEach(el => el.remove());
      `;

    const stringOnError = `
      document.getElementById(\"errorIndicator\").innerHTML = \"<i class="fa fa-bomb fa-5x text-red-600"></i>\"
      `;

    function onLoad(){
      let ifhb = document.querySelector(" #one_island_body_iframe > #document > html > body");
      // console.log( ifhb );
      ifhb.style.opacity = 0;
    };

    function run_iframe(){
      document.getElementById("one_island_body").innerHTML = `<iframe 
        src='${myTUrl}' 
        onload='${stringOnLoad}' 
        onerror='${stringOnError}'
        title='Island'
        width='100%' 
        height='${myHeight}'
        id='one_island_body_iframe'
        ></iframe>`; 
    };

    setTimeout(run_iframe, 2000);


    // eleIFrame = document.querySelector("iframe > html > body");
    // eleIFrame.style.background_color = "rgb(255, 255, 255, .1)";

  });

</script>

<style>

.prosper_one_island_overlay {
  position: relative;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;  
  z-index: 45; /* Specify a stack order in case you're using a different order for other elements */
  cursor: pointer; /* Add a pointer on hover */
  align-items: center;
  justify-items: center;
}

#loadingIndicator {
  z-index: 46; 
  place-items: center;
  align-items: center ;
  justify-content: center;
  display: grid;
  grid-template-columns: 100%;
  grid-template-rows: 15vw 2vw;    
  grid-gap: 0.5em; 
}

.loadingIndicatorVisibleGridA { grid-column: 1; grid-row: 1;}
.loadingIndicatorVisibleGridB { grid-column: 1; grid-row: 2; }

</style>

<div id="one_island_body" class="prosper_one_island_overlay">
  <div id="loadingIndicator">
    
    <div id="errorIndicator" class="loadingIndicatorVisibleGridA"></div>
    
    <div class="loadingIndicatorVisibleGridB">
      <Pulse size="60" color="#FF3E00" unit="px" duration="1s"/>
    </div>

  </div>
</div>
