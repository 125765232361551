<script>
  import { Router, Route, navigate } from "svelte-routing";

  // components for this layout
  import AdminNavbar from "components/Navbars/AdminNavbar.svelte";
  import Sidebar from "components/Sidebar/Sidebar.svelte";

  import HeaderStats from "components/Headers/HeaderStats.svelte";
  import FooterAdmin from "components/Footers/FooterAdmin.svelte";

  // pages for this layout
  import Dashboard from "views/admin/Dashboard.svelte";

  import ExportResultsFrame from "views/admin/ExportResultsFrame.svelte";
  import TableOfResultsFrame from "views/admin/TableOfResultsFrame.svelte";

  import Administration from "views/admin/Administration.svelte";
  import Pieboard from "views/admin/Pieboard.svelte";
  import Momentumboard from "views/admin/Momentumboard.svelte";
  import Momentumexboard from "views/admin/Momentumexboard.svelte";
  import ActionCommunicationBoard from "views/admin/ActionCommunicationBoard.svelte";

  import LandingpageFrame from "views/admin/LandingpageFrame.svelte";
  import ConfigurepageFrame from "views/admin/ConfigurepageFrame.svelte"; 
  import MyProsperlandFrame from "views/admin/MyProsperlandFrame.svelte";  
  
  import TestFrame from "views/admin/TestFrame.svelte";  
  
  import Services from "views/admin/Services.svelte";
  import FaqsFrame from "views/admin/FaqsFrame.svelte";    
  import DiscussionForumFrame from "views/admin/DiscussionForumFrame.svelte";    
  
  import Profil from "views/admin/Profil.svelte";  
  import Recipients from "views/admin/Recipients.svelte";

  import {myAuthObject} from "../tstore/myauth";
  import {checkWhoAmI} from "../lib/checkAuthObject";

  // export let location;
  export let my_lang;

  if ( checkWhoAmI($myAuthObject) === false ) {
    navigate('/auth/login', { replace: true }); 
  }  


  /** 
   * ACHTUNG!!!! statische Weret!!! 
   * muessen ausgelagert werden!!!!
  */

  /** 
  if ( $myAuthObject.projectId === '55109280-13fc-11ee-9534-376b5dabbac4' ) {
    my_lang = 'de';
  }
  */

</script>

<style>
.element {
  position: relative;
}

.element::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-image: url("../assets/img/bg_a.png");  
  background-size: cover;
  background-repeat: no-repeat;
  opacity: 0.25;
}

</style>

<div>
  <Sidebar my_lang={my_lang}/>
  <div class="relative md:ml-64 bg-blueGray-100 element">
    <AdminNavbar my_lang={my_lang}/>
    <HeaderStats />
    <div id="prosper_admin" class="px-4 md:px-10 mx-auto w-full -m-24">
      <Router url="admin">

        <Route path="dashboard"><Dashboard my_lang={my_lang}/> </Route>
        <Route path="export_results"><ExportResultsFrame my_lang={my_lang}/> </Route>
        <Route path="table_of_results"><TableOfResultsFrame my_lang={my_lang}/> </Route>        
        
        <Route path="administration"><Administration my_lang={my_lang}/> </Route>

        <Route path="pieboard"><Pieboard my_lang={my_lang}/> </Route>     
        <Route path="happiness"><Pieboard my_lang={my_lang}/> </Route>     

        <Route path="momentum_cx"><Momentumboard  my_lang={my_lang}/></Route>   
        <Route path="momentum_ex"><Momentumexboard  my_lang={my_lang}/></Route>   
        
        <Route path="action_and_communication"><ActionCommunicationBoard my_lang={my_lang} my_ac_action="checkup"/></Route> 
        <Route path="action_and_communication_action"><ActionCommunicationBoard my_lang={my_lang} my_ac_action="action"/></Route>
        <Route path="action_and_communication_feedback"><ActionCommunicationBoard my_lang={my_lang} my_ac_action="feedback"/></Route>
        <Route path="action_and_communication_publication"><ActionCommunicationBoard my_lang={my_lang} my_ac_action="publication"/></Route>
        <Route path="action_and_communication_now"><ActionCommunicationBoard my_lang={my_lang} my_ac_action="now"/></Route>          

        <Route path="landingpage"><LandingpageFrame my_lang={my_lang}/></Route>
        <Route path="configuration"><ConfigurepageFrame my_lang={my_lang}/></Route>
        <Route path="my_prosperland"><MyProsperlandFrame my_lang={my_lang}/></Route>

        <Route path="knowledge_base"><Services my_lang={my_lang} /></Route>
        <Route path="faq"><FaqsFrame my_lang={my_lang} /></Route>  
        
        <Route path="discussion_forum"><DiscussionForumFrame my_lang={my_lang} /></Route>          

        <Route path="recipients"><Recipients my_lang={my_lang} /></Route>
        <Route path="audience"><Recipients my_lang={my_lang} /></Route>        
        
        <Route path="profile"><Profil my_lang={my_lang} /></Route>    
        
        
        <Route path="test_frame"><TestFrame my_lang={my_lang} /></Route>


      </Router>
      <FooterAdmin />
    </div>
  </div>
</div>
