<script>
  import { navigate } from "svelte-routing";
  import {myAuthObject} from "../../tstore/myauth";
  import {guiText} from "../../tstore/guiText"; 
  import {selectTextOnFocus, blurOnEscape} from '../../lib/inputDirectives.js';      
  import LightGreyButon from "../../components/Buttons/LightGreyButon.svelte";
  import {whoAmIGet} from "../../api/WhoAmIGet";  
  import {companyPost} from "../../api/CompanyPost";  
  import {logInPost} from "../../api/LogInPost";  

  export let my_lang;

  let inputErrorMessage = '';
  $myAuthObject.loading = false;  

  const checkCompanyInput = () => {
    if ( $myAuthObject.company.length > 3 && $myAuthObject.company.length <= 64 ) {        
      $myAuthObject.companyState = true;
      inputErrorMessage = '';
    } 
    else if ( $myAuthObject.company.length > 64 ){
        inputErrorMessage = $guiText.general.max_64_chr[my_lang];
        $myAuthObject.projectState = false;
      }    
    else {
      inputErrorMessage = `${$guiText.general.min_4_chr[my_lang]} ${$guiText.general.max_64_chr[my_lang]}`;        
      $myAuthObject.companyState = false;
    }
  }

  const checkButtonClick = () => {
    if ( $myAuthObject.inputReady === true ) {
      apiRunner();
    }
  };   

  const whoAmIGetStatus = async () => {    
    // das muss eine option werden, oder??
    $myAuthObject.agb = true;
    $myAuthObject.loading = true;

    let rawresp = await whoAmIGet({'authO': $myAuthObject, 'guiTxt': $guiText });
    let resp = await rawresp.data;

    if ( rawresp.status === true && resp.uuid.length > 30 && resp.email.length >= 7 ) { 
      $myAuthObject.firstname = resp.firstName;
      $myAuthObject.lastname = resp.lastName;
      $myAuthObject.email = resp.email;      
      $myAuthObject.userUuid = resp.uuid;

      if ( resp.tenantRoles.length > 0 ) {
        if ( resp.tenantRoles[0]['tenantUuid'] ) {
          $myAuthObject.tenantUuid = resp.tenantRoles[0]['tenantUuid'];
        } 
        if ( resp.tenantRoles[0]['roles'] ) {
          $myAuthObject.tenantRole = resp.tenantRoles[0]['roles'];
        }    
      }

      $myAuthObject.project = '';
      return true;
    } 
    else {
      $myAuthObject.loading = false;
      inputErrorMessage = rawresp.message; 
      return false;
    }
  }

  const getBearerWithCompanyRole = async () => {
    let rawresp = await logInPost({'authO': $myAuthObject, 'guiTxt': $guiText }, $myAuthObject.passwordRaw);
    let resp = await rawresp.data;

    if ( rawresp.status === true && resp.type === 'Bearer ' && resp.token.length > 30 ) {
      $myAuthObject.bearer = resp.token;
      $myAuthObject.passwordRaw = '';

      let whoami = await whoAmIGetStatus();
      if ( whoami === true) {
        // whoami => tenant !!!
        return true;
      } 
      else { return false ; }
    } 
    else { return false; }
  };

  const apiRunner = async () => {
    let rawresp = await companyPost({'authO': $myAuthObject, 'guiTxt': $guiText });
    let resp = await rawresp.data;
  
    if ( resp.tenantUuid && resp.tenantUuid.length > 30) {
      $myAuthObject.tenantUuid = resp.tenantUuid;

      /* nachdem die company angelegt wurde, muss ein neuer beaer abgeholt werden.
        der alte bearer besitzt nicht die rechte für den zugriff auf die neu erstellte
        company. erst der neue bearer, welcher nach der erstellung der comapny ausgeliefert 
        wurde, besitzt die codierten rechte für den zugriff auf die company.
      */

      let nuBearer = await getBearerWithCompanyRole();
      if ( nuBearer === true ) {
        navigate('/auth/project', { replace: true });      
      } 
      else {
        $myAuthObject.companyState = false;
        $myAuthObject.inputReady = false;  
        $myAuthObject.loading = false;
      }
    }
    else { 
      inputErrorMessage = rawresp.message; 
      $myAuthObject.companyState = false;
      $myAuthObject.inputReady = false;  
      $myAuthObject.loading = false;
    }

  }

  </script>

  <div class="container mx-auto px-4 h-full">
    <div class="flex content-center items-center justify-center h-full">
      <div class="w-full lg:w-6/12 px-4">
        <div class="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg bg-blueGray-200 border-0" >
          <div class="flex-auto px-4 lg:px-10 py-10 pt-0">              

            <form>
              <div class="relative w-full mb-3 py-5">

                <h3 class="text-2xl mb-2 font-semibold leading-none">                    
                  {$guiText.register.general.company_name_plaese[my_lang]}
                </h3>

                <label
                  class="block text-blueGray-400 text-sm mb-2"
                  for="grid-company"
                >
                  {$guiText.register.general.company_orga_on_prosper[my_lang]} 
                  {$guiText.register.general.orga_manage_user[my_lang]}                    
                </label>
                <input
                  id="grid-company"
                  type="text"
                  class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-900 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                  placeholder="{$guiText.register.general.company_placeholder[my_lang]}"
                  bind:value={$myAuthObject.company}
                  on:input={checkCompanyInput}
                  use:selectTextOnFocus 
                  use:blurOnEscape                  
                />
              </div>
  
              <div class="text-center mt-6">

                {#if $myAuthObject.companyState === true}
                  {#if $myAuthObject.loading === true}

                    <LightGreyButon bg_color = "bg-blueGray-200">
                      <div slot="button_text">... {$guiText.general.sending[my_lang]}</div>
                    </LightGreyButon>

                  {:else}

                    <button
                      class="bg-blueGray-800 text-white active:bg-blueGray-600 text-sm font-bold px-6 py-3 
                          rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 w-full ease-linear 
                          transition-all duration-150"
                      type="button"
                      on:click={checkButtonClick}                                             
                    > 
                      {$guiText.general.continue[my_lang]}
                    </button>
                  {/if}

                {:else}

                  <LightGreyButon bg_color = "bg-blueGray-200">
                    <div slot="button_text">{$guiText.general.continue[my_lang]}</div>
                  </LightGreyButon>

                {/if}

              </div>

              {#if inputErrorMessage.length > 0 }
                <div>
                  <span class="block ml-2 text-xs text-red-400">
                    {inputErrorMessage}
                  </span>                  
                </div>
              {/if}

            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
  
