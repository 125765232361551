<script>

import {myAuthObject} from "../../tstore/myauth";
import {guiText} from "../../tstore/guiText"; 
import NoRights from "components/Cards/NoRights.svelte";

export let roleAdminStatus = false;
export let my_lang;
export let color = "light";

let defaultClass = "relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded ";
if (color === 'light' ) { 
  defaultClass += 'bg-white' } 
else { defaultClass += 'bg-red-800 text-white' }

</script>

{#if $myAuthObject['shop_status']['status'] === 'closed' }
  <div class="flex flex-wrap mt-4">
    <div class="w-full mb-12 px-4">    
      <div class="{defaultClass}">
        {#if roleAdminStatus === true }
          <NoRights my_lang="{my_lang}" 
          my_msg_head="{$guiText.general.closed_project[my_lang]}" 
          my_msg_body="{$guiText.general.project_was_closed_contact_team[my_lang]}"
          my_msg_link="" 
          my_msg_link_txt="" />        
        {:else}
          <NoRights my_lang="{my_lang}" 
          my_msg_head="{$guiText.general.closed_project[my_lang]}" 
          my_msg_body="{$guiText.general.project_is_actually_closed[my_lang]}"
          my_msg_link="" 
          my_msg_link_txt="" />                  
        {/if}
      </div>
    </div>
  </div>      
{/if}

{#if $myAuthObject['shop_status']['status'] === 'init'}
  <div class="flex flex-wrap mt-4">
    <div class="w-full mb-12 px-4">
    <!--           my_msg_body="{$guiText.general.configure_your_bank_data_in_stripe[my_lang]}"  -->

      <div class="{defaultClass}">              
        {#if roleAdminStatus === true && $myAuthObject.shop_status.respondent === $myAuthObject.whoami.email}
          <NoRights my_lang="{my_lang}" 
          my_msg_head="{$guiText.general.configure_bank_data[my_lang]}" 
          my_msg_body="{$guiText.general.configure_your_account[my_lang]}"          
          my_msg_link="{$myAuthObject.uri_ext_account}?prefilled_email={$myAuthObject.whoami.email}"
          my_msg_link_txt="{$guiText.general.here[my_lang]}"
          />
        {:else} 
          <NoRights my_lang="{my_lang}" 
          my_msg_head="{$guiText.general.welcome[my_lang]}" 
          my_msg_body="{$guiText.general.project_will_start_soon[my_lang]}"
          my_msg_link="" 
          my_msg_link_txt="" />
        {/if}              
      </div>
    </div>
  </div>
{/if}

{#if $myAuthObject['shop_status']['status'] === false || $myAuthObject['shop_status']['status'] === 'unknown'}
  <div class="flex flex-wrap mt-4">
    <div class="w-full mb-12 px-4">    
      <div class="{defaultClass}">                  
        <NoRights my_lang="{my_lang}" 
        my_msg_head="{$guiText.general.unknown_project[my_lang]}" 
        my_msg_body="{$guiText.general.contact_your_manager[my_lang]}"
        my_msg_link="" 
        my_msg_link_txt="" />
      </div>
   </div>
  </div>      
{/if}