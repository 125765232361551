import {apiMaster} from "../api/ApiMaster";  

export async function companyPost (data) {    
    data.authO.loading = true;

    let requestOptions = {
        method: 'POST',
        headers: { 
            'Content-Type': 'application/json', 
            'X-Custom-Header': 'application/json',
            'Authorization' : `Bearer ${data.authO.bearer}`
        },
        body: JSON.stringify({ 
            'tenant' : data.authO.company })
    };

    let resp = await apiMaster(
        data, 
        `${data.authO.uri_prosper_api}/tenant`, 
        requestOptions
    );
    
    return resp;
}