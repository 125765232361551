<!-- App.svelte -->
<script>
  import { Router, Route } from "svelte-routing";

  // Admin Layout
  import Admin from "./layouts/Admin.svelte";
  // Auth Layout
  import Auth from "./layouts/Auth.svelte";

  // No Layout Pages
  import Landing from "./views/Landing.svelte";
  import Profile from "./views/Profile.svelte";

  export let url = "";
  // let my_lang = "de";
  export let my_lang;

</script>

<Router url="{url}">
  <!-- admin layout -->
  <Route path="admin/*admin"> <Admin my_lang={my_lang}/> </Route>
  <!-- auth layout -->
  <Route path="auth/*auth"><Auth my_lang={my_lang}/></Route>

  <!-- no layout pages -->
  <Route path="landing"><Landing my_lang={my_lang}/></Route>
  <Route path="profile"><Profile my_lang={my_lang}/> </Route>
  <Route path="/"><Landing my_lang={my_lang}/></Route>  
</Router>
