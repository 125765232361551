import {apiMaster} from "../api/ApiMaster";  

export async function userPut (data, edit_usr) {
    data.authO.loading = true;

    let requestOptions = {
        method: 'PUT',
        headers: { 
            'Content-Type': 'application/json', 
            'X-Custom-Header': 'application/json',
            'Authorization' : `Bearer ${data.authO.bearer}`
        },
        body: JSON.stringify({ 
            'firstName' : edit_usr.firstname,
            'lastName' :  edit_usr.lastname
        })    
    };

    let my_uri = `${data.authO.uri_prosper_api}/user`;
    if ( edit_usr.uuid && edit_usr.uuid.length > 5 ) {
        my_uri += `/${edit_usr.uuid}`;
    }

    let resp = await apiMaster( data, my_uri, requestOptions );

    return resp;
}
