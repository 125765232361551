<script>
    export let bg_color = '';
</script>

<button 
    class="
        ${bg_color} 
        text-white 
        active:bg-blueGray-600 
        text-sm 
        font-bold 
        px-6 py-3 
        rounded shadow 
        hover:shadow-lg 
        outline-none 
        focus:outline-none 
        mr-1 mb-1 
        w-full 
        ease-linear 
        transition-all 
        duration-150"
    type="button"
    >
    <slot name="button_text"></slot>
</button>