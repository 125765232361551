<script>
  import { link } from "svelte-routing";
  import {guiText} from "../../tstore/guiText";  
  import {myAuthObject} from "../../tstore/myauth";    

  // core components
  // import IndexDropdown from "components/Dropdowns/IndexDropdown.svelte";

  export let my_lang;

  // const img_logo_b = "../../assets/img/prosperland_logo.jpg"; 
  const img_logo_b = "../../assets/img/PPL-koml_ws_trans_edited.webp"; 
  let navbarOpen = false;
  let loginText = $guiText.general.login[my_lang];
  let loginTextLink = '/auth/login';

  $: loginText && checkHeaderText();

  const checkHeaderText = () => {

    if ( $myAuthObject.firstname && $myAuthObject.firstname.length > 0 ) {
      if ( $myAuthObject.lastname && $myAuthObject.lastname.length > 0 ) {
        loginText = `${$myAuthObject.firstname} ${$myAuthObject.lastname}`;
      } 
      else { loginText = `${$myAuthObject.firstname}`; }
      loginTextLink = '/admin/dashboard';
    } 
    else if ( $myAuthObject.lastname && $myAuthObject.lastname.length > 0 ) {
      loginText = `${$myAuthObject.lastname}`;
      loginTextLink = '/admin/dashboard';
    }

  }

  function setNavbarOpen() {
    navbarOpen = !navbarOpen;
  }


</script>

<nav
  class="top-0 fixed z-50 w-full flex flex-wrap items-center justify-between px-2 py-1 navbar-expand-lg bg-white shadow"
>

  <div
    class="container px-4 mx-auto flex flex-wrap items-center justify-between"
  >

    <div
      class="w-full lg:w-6/12 px-4 relative flex justify-between lg:static lg:block lg:justify-start">
      <a
        use:link
        class="text-blueGray-700 text-sm font-bold leading-relaxed inline-block mr-4 py-1 whitespace-nowrap uppercase"
        href="/" >

        <img
          alt="propserland"
          class="max-w-full rounded-lg"
          src="{img_logo_b}"
          width="50%"
        />

      </a>
      <button
        class="cursor-pointer text-xl leading-none px-3 py-1 border border-solid border-transparent rounded bg-transparent block lg:hidden outline-none focus:outline-none"
        type="button"
        on:click="{setNavbarOpen}" >
        <i class="fas fa-bars"></i>
      </button>
    </div>

    <div
      class="lg:flex flex-grow items-center {navbarOpen ? 'block':'hidden'}"
      id="example-navbar-warning"
    >
     
      <ul class="flex flex-col lg:flex-row list-none lg:ml-auto">

        {#if $myAuthObject.lastname == ''} 

          <li class="flex items-center">
            <a
              use:link
              href="/auth/register"
              class="text-sm py-2 px-4 font-normal block w-full whitespace-nowrap bg-transparent text-blueGray-700 text-transform: capitalize"
            >
              {$guiText.register.general.registration[my_lang]}
            </a>
          </li>

          {/if}

        <li class="flex items-center" >
          <a
            use:link
            href="{loginTextLink}"
            class="text-sm py-2 px-4 font-normal block w-full whitespace-nowrap bg-transparent text-blueGray-700 text-transform: capitalize"
          >
            {loginText}
          </a>
        </li>

        <!-- li class="flex items-center">
          <IndexDropdown />
        </li //-->

      </ul>
    </div>

  </div>
</nav>
