import {apiMaster} from "../api/ApiMaster";  

export async function tokenPost (data, passtoken) {
    data.authO.loading = true;

    let requestOptions = {
        method: 'POST',
        headers: { 
            'Content-Type': 'application/json', 
            'X-Custom-Header': 'application/json',
        },
        body: JSON.stringify({ 'token': passtoken })      
    };

    let resp = await apiMaster(
        data,
        `${data.authO.uri_prosper_api}/auth/token`, 
        requestOptions
    );

    return resp;
}
