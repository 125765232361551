<script>
  import { link } from "svelte-routing";
  import {myAuthObject} from "../tstore/myauth";  
  import {guiText} from "../tstore/guiText";   
  // core components
  import IndexNavbar from "components/Navbars/IndexNavbar.svelte";
  import Footer from "components/Footers/Footer.svelte";

  const img_a = "../../assets/img/insel.jpg";
  const img_b = "../../assets/img/hausinseln.png"; 
  // const img_ppl = "../../assets/img/ppl_mn.jpg";
  const img_ppl = "../../assets/img/ppl_mn2.png";    
  const img_button_a = "../../assets/img/guide.png";
  const img_button_b = "../../assets/img/workout.png";  
  const img_button_c = "../../assets/img/key.png";

  export let my_lang;

  let img_c = "../../assets/img/landingpage_bg_en.jpg"; 
  let registerBg2 = "../../assets/img/landingpage_bg_en.jpg";
  if (my_lang == "de") {
    img_c = "../../assets/img/landingpage_bg_de.jpg";
    registerBg2 = "/assets/img/landingpage_bg_de.jpg";    
    // registerBg2 = "/assets/img/register_bg_2.jpg";        
  }

</script>

<div>
  <IndexNavbar my_lang={my_lang}/>  
  <main>
    <div
      class="relative flex content-center items-center justify-center min-h-screen"
    >
      <div
        class="absolute top-0 w-full h-full bg-center bg-cover"

        style="background-image: url({registerBg2});"
      >
        <span
          id="blackOverlay"
          class="w-full h-full absolute opacity-50 bg-black"
        ></span>
      </div>
      <div class="container relative mx-auto">
        <div class="items-center flex flex-wrap">
          <div class="w-full lg:w-6/12 px-4 ml-auto mr-auto text-center">
            <div class="pr-12">
              <h1 class="text-white font-bold text-5xl">                
                {$guiText.landing.welcome_to_prosper[my_lang]}
              </h1>
              <p class="mt-4 text-2xl font-bold text-blueGray-200">
                {$guiText.landing.let_us_show[my_lang]}  
              </p>
            </div>
          </div>
        </div>
      </div>
      <div
        class="top-auto bottom-0 left-0 right-0 w-full absolute pointer-events-none overflow-hidden h-70-px"
        style="transform: translateZ(0);"
      >
        <svg
          class="absolute bottom-0 overflow-hidden"
          xmlns="http://www.w3.org/2000/svg"
          preserveAspectRatio="none"
          version="1.1"
          viewBox="0 0 2560 100"
          x="0"
          y="0"
        >
          <polygon
            class="text-blueGray-200 fill-current"
            points="2560 0 2560 100 0 100"
          ></polygon>
        </svg>
      </div>
    </div>

    <section class="bg-blueGray-200 pb-20 -mt-24">

      <div class="container mx-auto px-4">
        <div class="flex flex-wrap">

          <div class="lg:pt-12 pt-6 w-full md:w-4/12 px-4 text-center items-center justify-center">
            <div
              class="relative items-center justify-center flex flex-col min-w-0 break-words bg-white w-full mb-8 shadow-lg rounded-lg"
            >
              <a
                class="py-5"
                href="{$myAuthObject.uri_prosper_island}"
                target="_blank"
              >            
                <img
                  alt="guide"
                  src="{img_button_a}"
                  class="w-16 rounded-t-lg"
                />
              </a>                
              <a href="{$myAuthObject.uri_prosper_island}" target="_blank" >
                <h6 class="text-xl font-semibold">
                  {$guiText.landing.your_travel_guide[my_lang]}
                </h6>
                <p class="mt-2 mb-4 text-blueGray-500">
                  {$guiText.landing.prosper_landscape[my_lang]}
                </p>
              </a>
            </div>            
          </div>

          <div class="w-full md:w-4/12 px-4 text-center items-center justify-center">
            <div
              class="relative items-center justify-center flex flex-col min-w-0 break-words bg-white w-full mb-8 shadow-lg rounded-lg"
            >
              <a
                class="py-5"
                href="{$myAuthObject.uri_prosper_workout}"
                target="_blank"
              >               
                <img
                  alt="workout"
                  src="{img_button_b}"
                  class="w-16 rounded-t-lg"
                />
              </a>
              <a href="{$myAuthObject.uri_prosper_workout}" target="_blank" >               
                <h6 class="text-xl font-semibold">
                  {$guiText.landing.your_workout[my_lang]}
                </h6>
                <p class="mt-2 mb-4 text-blueGray-500">
                  {$guiText.landing.what_you_can_do[my_lang]}
                </p>
              </a>
            </div>
          </div>

          <div class="pt-6 w-full md:w-4/12 px-4 text-center items-center justify-center">
            <div
              class="relative items-center justify-center flex flex-col min-w-0 break-words bg-white w-full mb-8 shadow-lg rounded-lg"
            >            
              <a
                use:link
                href="/auth/login"
                class="py-5"
              >               
                <img
                  alt="key"
                  src="{img_button_c}"
                  class="w-16 rounded-t-lg"
                />
              </a>
              <a
                use:link
                href="/auth/login"
              >              
              <h6 class="text-xl font-semibold">
                {$guiText.landing.your_key[my_lang]}
              </h6>
              <p class="mt-2 mb-4 text-blueGray-500">
                {$guiText.landing.step_into_prosper[my_lang]}
              </p>
              </a>
            </div>
          </div>
        </div>

        <div class="flex flex-wrap items-center mt-32">
          <div class="w-full md:w-5/12 px-4 mr-auto ml-auto">
            <a 
              href="{$myAuthObject.uri_prosper_circle}"
              target="_blank"
            >

              <h3 class="text-3xl mb-2 font-semibold leading-normal">
                {$guiText.landing.ecosystem[my_lang]}
              </h3>

              <p
                class="text-base font-light leading-relaxed mt-4 mb-4 text-blueGray-900"
              >
                {$guiText.landing.idea_behind[my_lang]}
              </p>
              <p
                class="text-sm font-light leading-relaxed mt-0 mb-4 text-blueGray-900"
              >
                {$guiText.landing.idea_behind_long[my_lang]}
              </p>

            </a>
          </div>

          <div class="w-full md:w-6/12 px-4 mr-auto ml-auto">
            <a 
              href="{$myAuthObject.uri_prosper_circle}"
              target="_blank"
            >

              <div
                class="relative flex flex-col min-w-0 break-words bg-white w-full mb-6 shadow-lg rounded-lg bg-red-400"
              >
                <img
                  alt="iland"
                  src="{img_a}"
                  class="w-full align-middle rounded-t-lg"
                />

              </div>
            </a>
          </div>          
        </div>
      
      </div>

    </section>

    <section class="relative py-20">
      <div
        class="bottom-auto top-0 left-0 right-0 w-full absolute pointer-events-none overflow-hidden -mt-20 h-20"
        style="transform: translateZ(0);"
      >
        <svg
          class="absolute bottom-0 overflow-hidden"
          xmlns="http://www.w3.org/2000/svg"
          preserveAspectRatio="none"
          version="1.1"
          viewBox="0 0 2560 100"
          x="0"
          y="0"
        >
          <polygon
            class="text-white fill-current"
            points="2560 0 2560 100 0 100"
          ></polygon>
        </svg>
      </div>

      <div class="container mx-auto px-4">
        <div class="items-center flex flex-wrap">
          <div class="w-full md:w-6/12 ml-auto mr-auto px-4">
            <img
              alt="..."
              class="max-w-full rounded-lg shadow-lg"
              src="{img_b}"
              />
          </div>
          <div class="w-full md:w-5/12 ml-auto mr-auto px-4">
            <div class="md:pr-12">

              <h3 class="text-3xl font-semibold">
                {$guiText.landing.distant_teams[my_lang]}
              </h3>
              <p class="mt-4 text-sm leading-relaxed text-blueGray-800">
                {$guiText.landing.distant_teams_long[my_lang]}
              </p>

            </div>
          </div>
        </div>
      </div>
    </section>

    <section class="relative py-10 content-center px-4 mx-auto flex flex-wrap">
      <div class="container mx-auto px-4">
        <div class="items-center flex flex-wrap">
          <div class="w-full md:w-8/12 ml-auto mr-auto px-4">
            <img
              alt="propserland"
              class="max-w-full rounded-lg"
              src="{img_ppl}"
            />                      
          </div>
        </div>
      </div>
    </section>

    <!-- section class="relative py-10 content-center px-4 mx-auto flex flex-wrap">
      <div class="container mx-auto px-4">
        <div class="items-center flex flex-wrap">
          <div class="w-full md:w-4/12 ml-auto mr-auto px-4 items-right justify-right">
            <img
              alt="propserland"
              class="max-w-full rounded-lg"
              src="{img_ppl_a}"
            />
          </div>
          <div class="w-full md:w-4/12 ml-auto mr-auto px-4 items-center justify-center">
          <img
              alt="ein digitales Produkt"
              class="max-w-full rounded-lg"
              src="{img_ppl_b}"
            />
          </div>
          <div class="w-full md:w-4/12 ml-auto mr-auto px-4 items-left justify-left">
          <img
              alt="Metrinomics"
              class="max-w-full rounded-lg"
              src="{img_ppl_c}"
            />                        
          </div>
        </div>
      </div>
    </section //-->

    <section class="relative block py-1 lg:pt-1 bg-blueGray-800">

      <div
        class="absolute top-0 w-full h-full bg-center bg-cover lg:pt-1 lg:pb-1"
        style="background-image: url({img_c});"
      >
        <span
          id="blackOverlay"
          class="w-full h-full absolute opacity-50 bg-black"
        ></span>
      </div>

      <div class="container mx-auto px-4 lg:pt-1 lg:pb-1">
      </div>

    <!-- 

      sobald eine api zur verfügung steht, soll das folgende einkommentiert werden und die 
      daten sollen verschickt werden

      <div class="container mx-auto px-4">
        <div class="flex flex-wrap justify-center lg:-mt-1 -mt-1">
          <div class="w-full lg:w-6/12 px-4">
            <div
              class="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg bg-blueGray-200"
            >

              <div class="flex-auto p-5 lg:p-10">
                <h4 class="text-2xl font-semibold">
                  {$guiText.landing.wanna_have_too[my_lang]} <br/>
                  {$guiText.landing.for_your_teams[my_lang]} <br/>
                  {$guiText.landing.for_your_people[my_lang]} <br/>
                  {$guiText.landing.for_your_employees[my_lang]} 
                </h4>
                <p class="leading-relaxed mt-1 mb-4 text-blueGray-500">
                  {$guiText.landing.write_us_and[my_lang]}
                </p>

                <div class="relative w-full mb-3 mt-8">
                  <label
                    class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                    for="firstname"
                  >
                    {$guiText.general.firstname[my_lang]}
                  </label>
                  <input
                    id="firstname"
                    type="text"
                    class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                    placeholder="{$guiText.general.firstname[my_lang]}"
                  />
                </div>

                <div class="relative w-full mb-3 mt-8">
                  <label
                    class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                    for="lastname"
                  >
                  {$guiText.general.lastname[my_lang]}
                  </label>
                  <input
                    id="lastname"
                    type="text"
                    class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                    placeholder="{$guiText.general.lastname[my_lang]}"
                  />
                </div>

                <div class="relative w-full mb-3">
                  <label
                    class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                    for="email"
                  >
                  {$guiText.general.email[my_lang]}
                  </label>
                  <input
                    id="email"
                    type="email"
                    class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                    placeholder="{$guiText.general.email[my_lang]}"
                  />
                </div>

                <div class="relative w-full mb-3">
                  <label
                    class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                    for="message"
                  >
                    {$guiText.general.message[my_lang]}                  
                  </label>
                  <textarea
                    id="message"
                    rows="4"
                    cols="80"
                    class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full"
                    placeholder="{$guiText.general.write_message[my_lang]}..."
                  />
                </div>
                <div class="text-center mt-6">
                  <button
                    class="bg-blueGray-800 text-white active:bg-blueGray-600 text-sm font-bold uppercase px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                    type="button"
                  >
                    {$guiText.general.send[my_lang]}                  
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    -->

    </section>

  </main>
  <Footer />
</div>
