<script>
  import { onMount } from "svelte";
  export let myTUrl;
  
  onMount(() => {
    document.getElementById('main_popup_body').innerHTML = `<iframe src="${myTUrl}" width="412" height="915"></iframe>`;
  });

</script>

<div> </div>