<script>
  import { navigate } from "svelte-routing";
  import { onMount } from "svelte";    
  import {guiText} from "../../tstore/guiText";
  import {servicesData} from "../../tstore/servicesData";
  import {myAuthObject} from "../../tstore/myauth";
  import {checkForRoleAdmin, checkForRoleAudience, checkForRoleProject} from '../../lib/checkAuthObject.js'; 
  import {tenantsGet} from "../../api/TenantsGet";
  import {surveysGet} from "../../api/SurveysGet";  
  import DashHeader from "components/Dashboards/DashHeader.svelte";
  import MomentumBPButton from "components/Dashboards/MomentumBPButton.svelte";
  import ServicesModal from "components/Dashboards/ServicesModal.svelte";

  export let my_lang;
  export let color = "light";

  let showModal = false;
  let bLink;

  // let button_popup_class = "bg-red-400 text-white float-right active:bg-red-500 font-bold uppercase text-xs px-4 py-2 rounded shadow "; 
  let defTxtCol = 'bg-red-800 text-white';
  if (color === 'light'){ defTxtCol= 'bg-white'; }

  const flex_starter = "relative content-top items-top justify-top w-full heightdefs ";
  const flex_ender = "mr-auto text-center break-words shadow-lg rounded " + defTxtCol;
  let defClassItem = flex_starter + "lg:w-12/12 mb-2 " + flex_ender;

  let buttonBase = "text-emerald-500 background-transparent font-bold capitalize px-4 ";
  buttonBase += "hover:text-emerald-600 text-xs outline-none focus:outline-none ";
  buttonBase += "mr-1 mb-1 mt-1 ease-linear transition-all duration-150 "; 

  let tenantsLabel = '';
  let surveysLabel = ''; 
  let tenantsAry = []; 
  let surveys_by_tenant = {};

  const roleAdminStatus = checkForRoleAdmin($myAuthObject);
  const roleAudienceStatus = checkForRoleAudience($myAuthObject);
  const roleProjectStatus = checkForRoleProject($myAuthObject);
  
  const toggleModalWindow = (e) => {     
    showModal = !showModal;
    bLink = e;
  };

  onMount(async () => { 
    if ( roleAdminStatus === false && roleAudienceStatus === false && roleProjectStatus === false ) { navigate('/auth/login', { replace: true }); };

    let tenantsRawResp = await tenantsGet({'authO': $myAuthObject, 'guiTxt': $guiText });
    tenantsAry = await tenantsRawResp.data; 
    let surveysRawResp = await surveysGet({'authO': $myAuthObject, 'guiTxt': $guiText });
    surveys_by_tenant = await surveysRawResp.by_tenant;

    tenantsLabel = tenantsAry[0].label;
    surveysLabel = surveys_by_tenant[tenantsAry[0].uuid][0]['label'];    

    if ( $myAuthObject.projectId === '55109280-13fc-11ee-9534-376b5dabbac4' ) {
      tenantsLabel = 'Grabow';
      surveysLabel = 'Grabow';
    }

    let eleT = document.getElementById('admin_navbar_tenant');
    eleT.innerHTML = '';
    eleT.insertAdjacentHTML('beforeend', '<i class="fas fa-industry mr-2 text-sm text-blueGray-400"></i>');
    eleT.insertAdjacentText('beforeend', tenantsLabel);
    
    let eleP = document.getElementById('admin_navbar_project');
    eleP.innerHTML = '';
    eleP.insertAdjacentHTML('beforeend', '<i class="fas fa-square-poll-horizontal mr-2 text-sm text-blueGray-400"></i>');
    eleP.insertAdjacentText('beforeend', surveysLabel);      
  });

</script>

{#if showModal}  
  <ServicesModal 
      color={color}  
      toggleModalWindow={toggleModalWindow}  
      header_text={$guiText.general.knowledge_base[my_lang]}
      bLink={bLink}
  />
{/if}

{#if $myAuthObject.whoami.valid === true }

<momentum_main>
  <div id="prosper_momentum_header">
    <DashHeader 
      color="ffffff" 
      background_color="076623" 
      headline={$guiText.general.knowledge_base[my_lang]} show_logo="show" />
  </div>

  <div id="prosper_momentum_subheader">
    <DashHeader 
      color="ffffff" 
      background_color="A6CD8A" 
      headline={$guiText.general.tutorial_videos_and_faqs[my_lang]} />
  </div>

  {#each $servicesData.cx as item}

    <div id="prosper_momentum_txt_{item.pos}">
      <div class="heightdefs">
        <div class="{defClassItem}">           
          {#if roleAdminStatus === true || roleProjectStatus === true } 

          <div id="prosper_momentum_box_main_2" class="heightdefs">

            <div id="prosper_momentum_box_a">
              <div class=" text-black text-sm font-bold pt-2 mt-2 pb-2 mb-2">              
                  {item.lbl} 
              </div>
            </div>

            <div id="prosper_momentum_box_b">
              <div class="align-text-black text-sm"> 
                {#if item.exp === ''} 
                  &nbsp; <br/> &nbsp; <br/> &nbsp;
                {:else}
                  <div class="py-4">
                    <MomentumBPButton 
                      bText={$guiText.general.open_faq[my_lang]}   
                      bind:showModal={showModal} 
                      bind:bLink={item.exp}
                      toggleModalWindow={toggleModalWindow}                  
                    />
                  </div>
                {/if}
              </div>
            </div>

            <div id="prosper_momentum_box_c" class="pb-2 mb-2">
              {#if item.lnk === ''}
                &nbsp; 
              {:else}
                <MomentumBPButton 
                  bText={$guiText.general.watch_video[my_lang]}   
                  bind:showModal={showModal} 
                  bind:bLink={item.lnk}
                  toggleModalWindow={toggleModalWindow}                  
                />
                {/if}
            </div>

          </div>

          {/if}          
        </div>
      </div>
    </div>
  {/each}

</momentum_main>

{/if}
