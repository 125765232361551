import {apiMaster} from "../api/ApiMaster";  

export async function participantCreatePost (data, myUsers) {
    data.authO.loading = true;

    const beautifyUsers = function ( users) {
        let intr = users.split(",");
        let resp = intr.map( (x) => { return { 'email' : x.trim() } } );
        return resp;
    }

    let requestOptions = {
        method: 'POST',
        headers: { 
            'Content-Type': 'application/json', 
            'X-Custom-Header': 'application/json',
            'Authorization' : `Bearer ${data.authO.bearer}`
        },
        body: JSON.stringify({ 
            "bot": data.authO.projectId,
            "emails": beautifyUsers(myUsers) 
        })
    };

    let resp = await apiMaster(
        data,
        `${data.authO.uri_prosper_api}/participant/create`, 
        requestOptions
    );

    return resp;
}