import { writable} from "svelte/store";
const uriExtAccount = URI_EXT_ACCOUNT;
const uriProsper = URI_PROSPER;
const uri_rwnapi_api = URI_RWNAPI;
const uriProsperApi = URI_PROSPER_API;
const uriProsperMn = URI_PROSPER_MN;
const uriProsperIsland = URI_PROSPER_ISLAND;
const uriProsperWorkout = URI_PROSPER_WORKOUT;
const uriProsperCircle = URI_PROSPER_CIRCLE;
const uri_mn_contact = URI_MN_CONTACT;
const uri_mn_cookie = URI_MN_COOKIE;
const uri_mn_datenschutzerklaerung = URI_MN_DATENSCHUTZERKLAERUNG;
const uri_mn_agb = URI_MN_AGB;

export const myAuthObject = writable({
    'uri_ext_account' : uriExtAccount,
    'uri_path' : uriProsper,
    'uri_rwnapi_api': uri_rwnapi_api,  
    'uri_prosper_api': uriProsperApi,
    'uri_prosper_mn' : uriProsperMn,
    'uri_prosper_island' : uriProsperIsland,
    'uri_prosper_workout' : uriProsperWorkout,
    'uri_prosper_circle' : uriProsperCircle,
    'uri_mn_contact' : uri_mn_contact,
    'uri_mn_cookie' : uri_mn_cookie,
    'uri_mn_datenschutzerklaerung' : uri_mn_datenschutzerklaerung,
    'uri_mn_agb' : uri_mn_agb,
    'defLang' : 'de',
    'firstname' : '',
    'lastname' : '',
    'email' : '',
    'inputReady' : false,
    'loading' : false,
    'agb' : false,
    'newsletter' : false,
    'otpState' : false,
    'company' : '',
    'companyState' : false,
    'tenantUuid' : '',
    'project' : '',
    'projectId' : '',    
    'projectState' : false,
    'surveyId' : '',
    'password' : false,
    'passwordInput' : false,
    'passwordRaw' : '',    
    'passwordSet' : false,
    'passwordtxt' : '',
    'userUuid' : '',
    'bearer' : '',
    'whoami' : {}
})