<script>
  // import {guiText} from "../../tstore/guiText";
  import {myAuthObject} from "../../tstore/myauth";  

  export let color = "ffffff";
  export let background_color = "076623";
  export let headline;
  export let show_logo = "hide";

  const logo_blanco = '/assets/img/logo_blanko.png';
  let fileLogo = '/assets/img/logo_blanko.png';
  /** 
   * nur die hier statisch hinterlegten projekte bekommen logo angezeigt
   * nächster schritt: prüfe ob in /assets/img/ ein projectId.jpg vorliegt
   * wenn ja, zeige es an, sonst nicht
  */
  if ( $myAuthObject.projectId === 'aa7c4d06-98e3-11ee-a700-02420a010442' ) {
    fileLogo = `/assets/img/${$myAuthObject.projectId}.jpg`;

  }

</script>

<div class="heightdefs">
  <div class="relative flex content-center items-center justify-center heightdefs w-full lg:w-auto mb-2 text-xs shadow-lg rounded" 
    style="color: #{color}; background-color: #{background_color};  z-Index: 1;"
  >    

      {#if show_logo === "show" && fileLogo !== '' }

        <div style="display: flex; width: 100%; align-items: center;">             

          <div style="margin-right: 10px;" class="flex lg:w-3/12 text-base align-middle">      
            <img src="{fileLogo}" style="height: 40px; float: left;" alt="logo"  />
          </div>

          <div style="justify-content: center;" class="flex lg:w-6/12 font-bold uppercase text-base align-middle">
            {headline} 
          </div>

          <div style="margin-right: 10px;" class="flex lg:w-3/12 text-base align-middle">      
            <img src="{logo_blanco}" style="height: 40px; float: right;" alt="blanco"  />
          </div>
          

        </div>

      {:else}

        <div class="flex lg:w-12/12 items-center justify-center">             
          <div class="font-bold uppercase text-base align-middle">
            {headline} 
          </div>
        </div>        

      {/if}

  </div>
</div>

