<script>
  import { link } from "svelte-routing";
  import {myAuthObject} from "../../tstore/myauth";

  import SidebarNavigation from "components/Sidebar/SidebarNavigation.svelte";

  // core components
  // import NotificationDropdown from "components/Dropdowns/NotificationDropdown.svelte";
  // import UserDropdown from "components/Dropdowns/UserDropdown.svelte";


  export let my_lang;

  let collapseShow = "hidden";

  const img_logo_b = "../../assets/img/PPL-koml_ws_trans_edited.webp";

  function toggleCollapseShow(classes) { collapseShow = classes; }

  if ( $myAuthObject.hasOwnProperty('shop_status') === false ) { 
    $myAuthObject['shop_status'] = { 'status' : 'closed' }; }

  if ( $myAuthObject.shop_status.hasOwnProperty('status') === false ) { 
    $myAuthObject.shop_status.status = 'closed'; }

</script>

<nav class="md:left-0 md:block md:fixed md:top-0 md:bottom-0 md:overflow-y-auto md:flex-row 
        md:flex-nowrap md:overflow-hidden shadow-xl bg-white flex flex-wrap items-center 
        justify-between relative md:w-64 z-10 py-4 px-6" >
   <div class="md:flex-col md:items-stretch md:min-h-full md:flex-nowrap px-0 flex flex-wrap 
          items-center justify-between w-full mx-auto" >
    <!-- Toggler -->
    <button 
      class="cursor-pointer text-black opacity-50 md:hidden px-3 py-1 text-xl 
            leading-none bg-transparent rounded border border-solid border-transparent"
      type="button"
      on:click={() => toggleCollapseShow('bg-white m-2 py-3 px-6')}
    >
      <i class="fas fa-bars"></i>
    </button>
    <!-- Brand -->
    <a
      use:link
      class="md:block text-left md:pb-2 text-blueGray-600 mr-0 inline-block whitespace-nowrap text-sm uppercase font-bold p-4 px-0"
      href="/"
    >
      <img
        alt="propserland"
        class="max-w-full rounded-lg"
        src="{img_logo_b}"
        width="60%"
      />
    </a>

    <!-- Collapse -->
    <div class="md:flex md:flex-col md:items-stretch md:opacity-100 md:relative md:mt-4 md:shadow-none 
            shadow absolute top-0 left-0 right-0 z-40 overflow-y-auto overflow-x-hidden h-auto items-center 
            flex-1 rounded {collapseShow}" >

      <!-- Collapse header -->
      <div class="md:min-w-full md:hidden block pb-4 mb-4 border-b border-solid border-blueGray-200" >
        <div class="flex flex-wrap">
          <div class="w-6/12">
            <a use:link
              class="md:block text-left md:pb-2 text-blueGray-600 mr-0 inline-block whitespace-nowrap 
                    text-sm uppercase font-bold p-4 px-0"
              href="/"
            >
              <img alt="propserland" class="max-w-full rounded-lg" src="{img_logo_b}" width="60%" />
            </a>
          </div>

          <div class="w-6/12 flex justify-end">
            <button
              type="button"
              class="cursor-pointer text-black opacity-50 md:hidden px-3 py-1 text-xl leading-none bg-transparent rounded border 
                    border-solid border-transparent"
              on:click={() => toggleCollapseShow('hidden')}
            >
              <i class="fas fa-times"></i>
            </button>
          </div>   

        </div>
      </div>
      
      <!-- Navigation -->
      <SidebarNavigation my_lang={my_lang}/>

    </div>
  </div>
</nav>
