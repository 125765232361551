import {apiMaster} from "../api/ApiMaster";  

export async function shopProductsPrice (data) {
    data.authO.loading = true;

    let requestOptions = {
        method: 'POST',
        headers: { 
            'Content-Type': 'application/json', 
            'X-Custom-Header': 'application/json' 
        },
        body: JSON.stringify(data.selected_product)
    };

    let resp = await apiMaster(
        data,
        `${data.authO.uri_rwnapi_api}/shop/products/price`, 
        requestOptions
    );

    return resp;
}