<script>
  import { navigate } from "svelte-routing";
  import { onMount } from "svelte";  
  import HeaderAndFrameP from "components/Dashboards/HeaderAndFrameP.svelte";
  import NoRights from "components/Cards/NoRights.svelte";
  import CopyrightFooter from "components/Cards/CopyrightFooter.svelte";  
  
  import {guiText} from "../../tstore/guiText";

  import {myAuthObject} from "../../tstore/myauth";
  import {checkWhoAmI, checkForRoleAdmin, checkForRoleAudience, checkForRoleProject} from '../../lib/checkAuthObject.js'; 

  export let my_lang;
  export let color = "light";

  if ( checkWhoAmI($myAuthObject) === false || $myAuthObject.whoami.valid === false ) {
    navigate('/auth/login', { replace: true }); 
  }

  // https://prosper-pp.prosperland.io/mn-prosper-eval/%7BuserId%7D/%7BsurveyBot%7D/prosperland/individual?useRandomData=onlyData&lang=en
  // status=true&
  let frameUrl = `${$myAuthObject.uri_prosper_mn}/${$myAuthObject.whoami.uuid}/${$myAuthObject.projectId}/prosperland/individual?useRandomData=onlyData`;

  const roleAdminStatus = checkForRoleAdmin($myAuthObject);
  const roleAudienceStatus = checkForRoleAudience($myAuthObject);
  const roleProjectStatus = checkForRoleProject($myAuthObject);

  onMount(async () => { 
    
    $myAuthObject.shop_status.status 
    if ( $myAuthObject.shop_status.hasOwnProperty('status') === false ) { navigate('/auth/login', { replace: true }); }
    if ( roleAdminStatus === false && roleAudienceStatus === false && roleProjectStatus === false ) { navigate('/auth/login', { replace: true }); };
  });

</script>

    {#if $myAuthObject['shop_status']['status'] === 'open' }
      {#if roleAdminStatus === true || roleAudienceStatus === true || roleProjectStatus === true } 

        <HeaderAndFrameP my_lang="{my_lang}" frame_url="{frameUrl}" />

      {:else}
        <div class="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg r
                    ounded {color === 'light' ? 'bg-white' : 'bg-red-800 text-white'}">                
          <NoRights my_lang="{my_lang}" 
          my_msg_head="{$guiText.general.no_rights[my_lang]}!" 
          my_msg_body="{$guiText.general.no_rights_to_log_in[my_lang]}."
          my_msg_link="" 
          my_msg_link_txt="" />          
        </div>
      {/if}        
    {/if}

    <CopyrightFooter 
      roleAdminStatus={roleAdminStatus}
      my_lang={my_lang}
      color={color}
    />