import {apiMaster} from "../api/ApiMaster";  

export async function whoAmIGet (data) {

    let requestOptions = {
        method: 'GET',
        headers: { 
            'Content-Type': 'application/json', 
            'X-Custom-Header': 'application/json',
            'Authorization': `Bearer ${data.authO.bearer}`
        }        
    };

    let resp = await apiMaster(
        data,
        `${data.authO.uri_prosper_api}/user/whoami`, 
        requestOptions
    );

    return resp;
}
