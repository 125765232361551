<script>
  // core components
  import { navigate, link } from "svelte-routing";
  import { onMount } from "svelte";  

  // import {selectTextOnFocus, blurOnEscape} from '../../lib/inputDirectives.js';  

  import {userPut} from "../../api/UserPut";  
  import {whoAmIGet} from "../../api/WhoAmIGet";  

  import {checkWhoAmI} from "../../lib/checkAuthObject";  
  import {guiText} from "../../tstore/guiText"; 
  import {myAuthObject} from "../../tstore/myauth";

  if ( checkWhoAmI($myAuthObject) === false || $myAuthObject.whoami.valid === false ) {
    navigate('/auth/login', { replace: true }); 
  }

  // $myAuthObject.whoami.email: "r.poljak@metrinomics.de"
  // $myAuthObject.whoami.enableAt: "2022-07-06T10:46:27Z"
  // $myAuthObject.whoami.firstName: "Ruwen"
  // $myAuthObject.whoami.gender: "unknown"
  // $myAuthObject.whoami.lastName: "Participant von A"
  // $myAuthObject.whoami.respondentIdent: "3f7b0226da55200bab33d4fb6e62554112f9b0d5cd6d861c367d28cc035e3488"
  // $myAuthObject.whoami.tenantRoles: [
  //   {
  //     tenantUuid: "d3b2d07f-8e46-3b6d-815c-d251e0ac4b3f", roles: "ROLE_PARTICIPANT"
  //   }
  // ]
  // $myAuthObject.whoami.uuid: "57dbbe47-d394-3820-819f-ea3ccfec901d"
  // $myAuthObject.whoami.valid: true

  export let my_lang;
  export let my_msg_head;
  export let my_msg_body;
  export let my_msg_link;
  export let my_msg_link_txt;
  export let color = "light";

  let isFirstnameOk = false;
  let isLastnameOk = false;
  let inputErrorMessage = '';

  $myAuthObject.loading = false;
  $myAuthObject.inputReady = false; 

  $: $myAuthObject.firstname && checkInputFirstname();
  $: $myAuthObject.lastname && checkInputLastname();  

  let editProfile = false;
  function toggleEditProfile(e=true){ editProfile = !editProfile; };

  const userPutRun = async () => {
    let edit_usr = {
      'firstname' : $myAuthObject.firstname ? $myAuthObject.firstname : '',
      'lastname' : $myAuthObject.lastname ? $myAuthObject.lastname : '',
      'uuid' : $myAuthObject.userUuid ? $myAuthObject.userUuid : ''
    }

    let rawresp = await userPut({'authO': $myAuthObject, 'guiTxt': $guiText }, edit_usr);
    let resp = await rawresp.data;
    
    if ( rawresp.status === true ) {
      return true;
    }
    else {
        $myAuthObject.loading = false;
        inputErrorMessage = rawresp.message; 
        return false;
      }
  } 

  const saveProfile = () => { 
    if ( $myAuthObject.inputReady === true ) {    
      apiRunner(); 
    }
    toggleEditProfile();
  };

  const authObjectUpdate = (resp) => {
    $myAuthObject.firstname = resp.firstName;
    $myAuthObject.lastname = resp.lastName;
    $myAuthObject.email = resp.email;
    $myAuthObject.userUuid = resp.uuid;
  };

  const whoAmIGetStatus = async () => {
    $myAuthObject.loading = true;
    let rawresp = await whoAmIGet({'authO': $myAuthObject, 'guiTxt': $guiText });
    let resp = await rawresp.data;

    if ( rawresp.status === true && resp.uuid.length > 30 && resp.email === $myAuthObject.email && resp.email.length >= 7 ) { 
      authObjectUpdate(resp);

      if ( resp.tenantRoles.length > 0 ) {
        if ( resp.tenantRoles[0]['tenantUuid'] ) {
          $myAuthObject.tenantUuid = resp.tenantRoles[0]['tenantUuid'];
        } 
        if ( resp.tenantRoles[0]['roles'] ) {
          $myAuthObject.tenantRole = resp.tenantRoles[0]['roles'];
        }    
      }

      $myAuthObject.project = '';
      $myAuthObject.whoami = resp;
      return true;
    } 
    else {
      logInFailedMessage = $guiText.general.login_failed[my_lang];
      return false;
    }
  }

  const apiRunner = async () => {
    let userPost = await userPutRun();
    if ( userPost === true ) {
      await whoAmIGetStatus();
    }

	};

  const checkInputTot = () => { 
    $myAuthObject.loading === false;
    if ( isLastnameOk === true && isFirstnameOk === true) {
      $myAuthObject.inputReady = true; 
    } else {
      $myAuthObject.inputReady = false; 
    }
  
  };

  const checkInputLastname = () => { 
    inputErrorMessage = ''; 

    if ( $myAuthObject.lastname.length === 0 ) { 
      isLastnameOk = false; 
      $myAuthObject.inputReady = false;
    }     
    else if ( $myAuthObject.lastname.length < 2 ) { 
      inputErrorMessage = `${$guiText.general.lastname[my_lang]} ${$guiText.register.general.must_have_min_2_chrs[my_lang]}`;
      isLastnameOk = false;      
      $myAuthObject.inputReady = false;
    } else {
      isLastnameOk = true;
      checkInputTot();
    }

  };  

  const checkInputFirstname = () => { 
    inputErrorMessage = '';

    if ( $myAuthObject.firstname.length === 0 ) { 
      isFirstnameOk = false; 
      $myAuthObject.inputReady = false;
    }     
    else if ( $myAuthObject.firstname.length < 2 ) { 
      inputErrorMessage = `${$guiText.general.firstname[my_lang]} ${$guiText.register.general.must_have_min_2_chrs[my_lang]}`;
      isFirstnameOk = false;      
      $myAuthObject.inputReady = false;
    } else {
      isFirstnameOk = true;      
      checkInputTot();
    }
  }

  onMount(async () => { 
    $myAuthObject.lastname = $myAuthObject.whoami.lastName;
    $myAuthObject.firstname = $myAuthObject.whoami.firstName;
    $myAuthObject.email = $myAuthObject.whoami.email;

  });

</script>

<div class="rounded-t mb-0 px-4 py-3 border-0">
  <div class="flex flex-wrap mt-4 items-center">
    <div class="relative w-full xl:w-12/12 mb-12 xl:mb-0 px-4">
      <div class="text-center flex justify-between">
        
        <div class="flex flex-wrap items-center!">
          
          <div class="relative w-full max-w-full flex-grow flex-1">
          <!-- px-4 -->

            <!-- <div class="fa-stack fa-lg"> -->
              <!-- i class="fa fa-square fa-stack-2x" style="color: white;"></i           -->              
              <!-- <i class="fa fa-exclamation fa-stack-1x fa-inverse" style="color:red;"></i> -->
            <!-- </div> -->

            <!-- <span class="fa-stack fa-md"> -->
              <!-- i class="fa fa-exclamation-triangle fa-stack-2x" style="color: #C00000" aria-hidden="true"></i -->
              <!-- <i class="fa fa-exclamation fa-stack-1x fa-inverse fa-lg" style="color:#C00000;padding-top:2px;" ></i> -->
              <!-- style="color:black;padding-top:2px;" -->
            <!-- </span> -->

            <h3 class="font-semibold text-center text-red-500 " >
              <!-- {color === 'light' ? 'text-blueGray-700' : 'text-white'} -->
              {my_msg_head === '' ? 'Welcome' : my_msg_head }
            </h3>

          </div>

          <!-- <div class="relative w-full max-w-full flex-grow flex-1 text-right">
            <i class="fas fa-exclamation-triangle"></i>

          </div> -->

        </div>

      </div>
      <div class="flex flex-wrap items-center!">

        <div class="p-4 flex-auto"> 
          {my_msg_body === '' ? 'Show your messages here' : my_msg_body }
          {#if my_msg_link && my_msg_link.length > 1}            
            <a 
              use:link  
              href='{my_msg_link}'
              target="_blank"
              class="text-base py-3 font-bold block text-lightBlue-200 hover text-lightBlue-400 capitalize" >
              {my_msg_link_txt === '' ? 'Link' : my_msg_link_txt }
            </a>
          {/if}
        </div>

      </div>
    </div>
  </div>
</div>




