<script>
  import { onMount } from "svelte";
  
  import Chart from "chart.js";
  // import Chart from 'chart.js/auto';
  import * as Gauge from "chartjs-gauge";
  // import ChartDataLabels from "chartjs-plugin-datalabels.js";
  // import "chartjs-plugin-labels";

  export let mySpeedOMeter;
  export let myTitle;
  export let somData = [];
  export let somBGColor = [];
  export let somNeedle;
  export let somLabels = [];


  onMount(async () => {
    let data_som = { 
      data : JSON.parse(somData), 
      value : somNeedle, 
      bgColor : somBGColor,
      myLabels : somLabels
    };

    let config = {
        type: "gauge",
        data: { 
          labels: data_som.myLabels,
          datasets: [ { 
            label: myTitle, 
            data: data_som.data, 
            value: data_som.value, 
            minValue: 0, 
            backgroundColor: data_som.bgColor, 
            borderWidth: 2 } ] },
          options: { 
            legend: { display: false, position: "bottom", labels: { generateLabels: {} } }, 
            responsive: true, 
            title: { display: false, text: myTitle }, 
            layout: { padding: { bottom: 10, top: 10 } }, 
            needle: { radiusPercentage: 1, widthPercentage: 1, lengthPercentage: 60, color: "rgba(0, 0, 0, 1)" }, 
            valueLabel: { 
              fontSize: 12, 
              formatter: function (value, context) { return value + "X"; // return '< ' + Math.round(value); 
              } 
            },
            plugins: { 
              datalabels: {
                display: true,
                formatter:  function (value, context) { return context.chart.data.labels[context.dataIndex]; },
                //color: function (context) {
                //  return context.dataset.backgroundColor;
                //},
                color: 'rgba(0, 0, 0, 1.0)',
                //color: 'rgba(255, 255, 255, 1.0)',
                backgroundColor: null,
                font: { size: 10, weight: 'bold' }
              }

              // datalabels: { 
              //   display: "auto", 
              //   formatter: function (value, context) { return context.chart.data.labels[context.dataIndex]; },
              //   color: function (context) { return "white"; },
              //   font: function (context) {
              //     var innerRadius = Math.round(context.chart.innerRadius);
              //     console.log(innerRadius);
              //     var size = Math.round(innerRadius / 8);
              //     return { weight: "normal", size: size };
              //   }
              // }



            }
          }
      };
    


    let ctx = document.getElementById(mySpeedOMeter).getContext("2d");
    window.myBar = new Chart(ctx, config);  
  });
</script>

