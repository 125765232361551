import {apiMaster} from "../api/ApiMaster";  

export async function userDelete (data, deluuid) {
    data.authO.loading = true;

    let requestOptions = {
        method: 'DELETE',
        headers: { 
            'Content-Type': 'application/json', 
            'X-Custom-Header': 'application/json',
            'Authorization' : `Bearer ${data.authO.bearer}`
        }    
    };

    let resp = await apiMaster(
        data,
        `${data.authO.uri_prosper_api}/user/${deluuid}`, 
        requestOptions
    );

    return resp;
}
