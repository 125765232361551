import {apiMaster} from "../api/ApiMaster";  

export async function logInPost (data, myPassword) {
    data.authO.loading = true;
    
    data.authO.bearer = '';
    data.authO.tenantUuid = '';

    let requestOptions = {
        method: 'POST',
        headers: { 
            'Content-Type': 'application/json', 
            'X-Custom-Header': 'application/json' 
        },
        body: JSON.stringify({ 
            'email': data.authO.email, 
            'password': myPassword
            })
    };

    let resp = await apiMaster(
        data,
        `${data.authO.uri_prosper_api}/auth/login`, 
        requestOptions
    );

    return resp;
}