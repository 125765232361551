import {apiMaster} from "../api/ApiMaster";  

export async function templatesGet (data) {
    data.authO.loading = true;

    let requestOptions = {
        method: 'GET',
        headers: { 
            'Content-Type': 'application/json', 
            'X-Custom-Header': 'application/json',
            'Authorization' : `Bearer ${data.authO.bearer}`
        }    
    };

    let resp = await apiMaster(
        data,
        `${data.authO.uri_prosper_api}/templates`, 
        requestOptions
    );

    let templatesAry = await resp.data;
    resp['for_select'] = [];

    if ( resp.status === true ) {
        templatesAry.forEach(e => { 
            
            let rx_count_of_islands = 0;
            /** prios 
            /* e.text[0]['description'][0-1]
            /* e.text[0]['label'][0-1]
            /* e.countOfIslands - 1
            */

            try {
                rx_count_of_islands = parseInt( e.text[0]['description'].substring(0,1) );
                if ( Number.isNaN(rx_count_of_islands) ) {
                    try {
                        rx_count_of_islands = parseInt( e.text[0]['label'].substring(0,1) );
                        if ( Number.isNaN(rx_count_of_islands) ) { rx_count_of_islands = e.countOfIslands; }
                    }
                    catch (error) { rx_count_of_islands = e.countOfIslands; }
                }
            } catch (error) {
                try {
                    rx_count_of_islands = parseInt( e.text[0]['label'].substring(0,1) );
                    if ( Number.isNaN(rx_count_of_islands) ) { rx_count_of_islands = e.countOfIslands; }
                }
                catch (error) { rx_count_of_islands = e.countOfIslands; }
            }

            resp['for_select'].push({
                'group' : 'template',
                'label': `${e.countOfIslands} ${data.guiTxt.register.general.configure_your_product_label_y[data.authO.defLang]} (id: ${e.surveyId})`,
                'value' : e.bot, 
                'description' : e.countOfIslands,
                'surveyId' : e.surveyId,
                'text' : e.text,
                'rx_count_of_islands' : rx_count_of_islands
            }
        ) } );
    } else {
        let mparts = resp.message.split('.');
        resp['for_select'].push({
                'group' : 'template',
                'label': mparts[0],
                'value' : 'error', 
                'description' : mparts[0],
                'surveyId' : 0,
                'text' : mparts[0],
                'rx_count_of_islands' : 1
            }
        )        
    }

    return resp;
}
