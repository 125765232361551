<script>
  export let color = "light";
  export let toggleModalWindow;
  export let header_text;
  export let bLink;

</script>

<style>
  #main_popup {
    display: grid;
    grid-template-columns: 100%;
    grid-template-rows: auto auto auto auto;
    position: relative; 
    z-index: 50; 
  }

  #main_popup_header { grid-column: 1; grid-row: 1;}
  #main_popup_body { grid-column: 1; grid-row: 2;}
  #main_popup_note { 
    grid-column: 1; 
    grid-row: 3;
    background-color: #ffffff; 
  }
  #main_popup_legend { grid-column: 1; grid-row: 4;}

  #main_popup_body_iframe { 
    width: 960px; 
    height: 680px;
  }

  @media (max-width: 640px) {
    #main_popup_body_iframe { 
      width: 460px; 
      height: 720px;
    }
  }

</style>

<div id="prosper_overlay"></div>     

<div class="relative flex flex-col min-w-0 break-words w-full shadow-lg rounded {color === 'light' ? 'bg-white' : 'bg-red-800 text-white'}">  
  <div class="overflow-x-hidden overflow-y-auto fixed z-50" >
    <div class="border-0 rounded-lg shadow-lg w-full h-full bg-white" >                    
      <div class="mb-0 border-0">

        <div id="main_popup">

          <div id="main_popup_header" class="flex">
            <div class="lg:w-10/12 w-full prosper_text_l2_strong_weak block font-semibold text-center 
                      {color === 'light' ? 'text-blueGray-700' : 'text-white'}">
              {header_text}
            </div>
            
            <div>
              <button 
                class="lg:w-2/12 bg-red-400 text-white float-right active:bg-red-500 font-bold uppercase 
                        text-xs px-4 py-2 rounded shadow " 
                type="button"                   
                on:click={toggleModalWindow} > x </button>
            </div>

          </div>

          <div id="main_popup_body"> 
            <iframe title="momentum survey" id="main_popup_body_iframe" src={bLink} ></iframe>
          </div>

          <div id="main_popup_note">
            <div class="text-blueGray-900 text-center text-sm mb-2 px-2"> 
            </div>
          </div>          

          <div id="main_popup_legend">  </div>

        </div>

      </div>
    </div>
  </div>
</div>