// import {apiMaster} from "./ApiMaster";  

// export async function filterGet (data) {
export async function filterGet (data) {    
    data.authO.loading = true;

    /** 
    let requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ 
            "surveyBot": data.authO.projectId,
            "userId": data.authO.userUuid,
            "language": data.authO.selectedLang
        })
    };

    let resp = await apiMaster(
        data,
        `${data.authO.uri_prosper_mn}/api/momentumSurveys`, 
        requestOptions
    );
    */

    return {
        'data' : [ 
            { "value": "female", "text": data.guiTxt.general.female[data.authO.selectedLang],  "question": "female" },
            { "value": "male", "text": data.guiTxt.general.male[data.authO.selectedLang],  "question": "male" },
            { "value": "single", "text": data.guiTxt.general.single[data.authO.selectedLang],  "question": "single" },
            { "value": "single parent", "text": data.guiTxt.general.single_parent[data.authO.selectedLang],  "question": "single parent" },
            { "value": "has family", "text":  data.guiTxt.general.has_family[data.authO.selectedLang],  "question": "has family" },
            { "value": "under 30 years", "text": data.guiTxt.general.under_30_years[data.authO.selectedLang],  "question": "under 30 years" },
            { "value": "over 40 years", "text": data.guiTxt.general.over_40_years[data.authO.selectedLang],  "question": "over 40 years" }  
          ]
    };
}
