import { writable} from "svelte/store";

let myGeneral = {
    'no_rights' : { 'de': 'Keine Berechtigung', 'en': 'No rights'},
    'no_rights_to_log_in' : { 'de': 'LogIn aufgrund fehlender Rechte, nicht möglich', 'en': 'You have no rights to log in'},
    'here' : { 'de' : 'hier', 'en': 'here'},
    'account' : { 'de' : 'account', 'en': 'account'},
    'project_was_closed_contact_team' : {'de' : 'Das Projekt wurde geschlossen, bitte kontaktiere dein Team.',
        'en' : 'The project was closed, please contact your team!' },
    'project_is_actually_closed' : { 'de' : 'das projekt ist momentan gesperrt', 'en' : 'This project is actually closed' },
    'closed_project' : { 'de' : 'Gesperrtes Projekt', 'en' : 'Closed project'},
    'configure_bank_data' : { 'de' : 'Bankdaten konfigurieren', 'en' : 'Configure bank data' },
    'configure_your_account' : { 'de' : 'Bitte konfiguriere Deinen Account', 'en' : 'Please configure your account'},
    'welcome' : { 'de' : 'Willkommen', 'en' : 'Welcome'},
    'project_will_start_soon' : { 'de' : 'Das Projekt wird bald starten', 'en' : 'This project will start soon'},
    'unknown_project' : { 'de' : 'Unbekanntes Projekt!', 'en' : 'Unknown project!'},
    'agb' : { 'de' : 'Allgemeinen Geschäftsbedingungen', 'en' : 'terms and conditions' },
    'approved' : { 'de' : 'genehmigt', 'en' : 'approved' },
    'check_your_email' : { 'de' : 'Bitte prüfe Dein E-Mail Postfach', 'en' : 'Please check your inbox'  },
    'checking' : { 'de' : 'prüfe', 'en' : 'checking'  },
    'continue' : { 'de' : 'Weiter', 'en' : 'Next'  },
    'description' : { 'de' : 'Beschreibung', 'en' : 'Description'  },
    'contact_your_manager' : { 'de' : 'Bitte kontaktiere Deinen Manager.', 'en' : 'Please contact your manager.'  },
    'datenschutzerklaerung' : { 'de' : 'Datenschutzerklärung', 'en' : 'privacy policy' },    
    'email' : { 'de' : 'Email', 'en' : 'Email'  }, 
    'firstname' : { 'de' : 'Vorname', 'en' : 'Firstname'  },
    'error_occured' : {  'de' : "Ein Fehler ist aufgetreten.", 'en' : 'An error occurred.'  },
    'error_unknown_occured' : { 'de' : "Ein unbekannter Fehler ist aufgetreten.", 'en' : 'An unknown error occurred.'  },    
    'error_occured_check_input' : { 'de' : "Ein Fehler ist aufgetreten, bitte Eingaben überprüfen.", 
        'en' : 'An error occurred, please check your inputs,'  },
    'invite_persons' : { 'de' : 'Teilnehmer einladen', 'en' : 'Invite participants'  },
    'lastname' : { 'de' :  'Nachname', 'en' : 'Lastname'  },
    'letsgo' : { 'de' : "Los geht's", 'en' : "Let's go"  },
    'loading' : { 'de' : 'lade Daten', 'en' : 'loading data'  },
    'login' : { 'de'  :'Login', 'en' : 'Login'  },
    'login_failed' : { 'de' : 'Login gescheitert, bitte versuche es erneut', 'en' : 'Login failed, please try again'  },
    'login_failed_missing_auth'  : { 'de' : 'Login gescheitert aufgrund fehlender Autorisierung! Bitte wende Dich an Deinen Manager.',
        'en' : 'Login failed due to missing authorization! Please contact your admin.'  },
    'message': { 'de' : 'Nachricht', 'en' : 'message'  },
    'min_4_chr' : { 'de' : 'Gib bitte mindestens 4 Zeichen ein.', 'en' : 'Please enter at least 4 characters.'  },
    'max_64_chr' : {  'de' : 'Zulässig sind maximal 64 Zeichen.', 'en' : 'A maximum of 64 characters is allowed.'  },    
    'view_collected_data_on_prosperland' : { 'de' : 'Betrachte Deine gesammelten Daten im Prosperland Projekt', 
        'en' : 'view your collected data on the Prosperland project'},    
    'name' : { 'de' : 'Name', 'en' : 'name'  },
    'or' : {  'de' : 'oder', 'en' : 'or'  },
    'role' : { 'de' : 'Rolle', 'en' : 'role' },            
    'recipient' : { 'de' : 'Empfänger', 'en' : 'recipient' },
    'audience' : { 'de' : 'Audience', 'en' : 'audience' },    
    'recipients' : { 'de' : 'Empfänger', 'en' : 'Recipients' },
    'administration' : { 'de' : 'Administration', 'en' : 'administration' },
    'profil' :  { 'de' : 'Profil', 'en' : 'Profile' },       
    'dashboard' : { 'de' : 'Dashboard', 'en' : 'Dashboard' }, 
    'services' : { 'de' : 'Services', 'en' : 'services' },    
    'tutorial_videos' : { 'de' : 'Tutorial Videos', 'en' : 'Tutorial videos' }, 
    'tutorial_videos_and_faqs' : { 
        'de' : 'Tutorial Videos and FAQs', 'en' : 'Tutorial videos and FAQs' },         
    'watch_now' : { 'de' : 'Watch now', 'en' : 'Watch now' },    
    'watch_video' : { 'de' : 'Watch Video', 'en' : 'Watch video' }, 
    'go_to_faq' : { 'de' : 'Go to FAQ', 'en' : 'Go to FAQ' }, 
    'open_faq' : { 'de' : 'Open FAQ', 'en' : 'Open FAQ' },
    'faq' : { 'de' : 'FAQ', 'en' : 'FAQ' },
    'knowledge_base' : { 'de' : 'Wissensbasis', 'en' : 'Knowledge base' },    
    'error_sending_data' : {
        'de' : 'Fehler beim versenden der Daten',
        'en' : 'Error sending data'
    },
    'data_was_sent_successfully' : { 
        'de' : 'Daten wurden erfolgreich verschickt!', 
        'en' : 'Data was sent successfully!' },
    'lorem_ipsum' : { 
        'de' : 'Lorem ipsum dolor sit amet, consectetur adipisici elit, ...', 
        'en' : 'Lorem ipsum dolor sit amet, consectetur adipisici elit, ...' },
    'go_to_the_survey': { 'de' : 'Zur Umfrage', 'en' : 'Go to the survey' }, 
    'go_to_the_chart': { 'de' : 'Geh zum Diagramm', 'en' : 'Go to the chart' },         
    'your_prosperland_at_your_fingertips' : {
        'de' : "Prosperland, immer zur Hand",
        'en' : "Your Prosperland at your fingertips"
    },
    'fiber_emea_bilbao' : {
        'de' : "Der Durchschnittswert der Antworten auf die Frage:",
        'en' : "Fiber emea - Bilbao - 2024 Feb 21st"
    },
    'action_and_communication_center' : { 
        'de' : 'Aktions & Kommunikationscenter', 
        'en' : 'Action & Communication Center' },
    'action_and_communication' : { 
        'de' : 'Aktion & Kommunikation', 
        'en' : 'Action & Communication' },   
    'checklist' : { 'de' : 'Checkliste', 'en' : 'Checklist' },
    'start_date' : { 'de' : 'Startdatum', 'en' : 'Start date' },
    'invitation_date' : { 'de' : 'Invitation date', 'en' : 'Invitation date' },
    'reminder_date' : { 'de' : 'Reminder date', 'en' : 'Reminder date' },    
    'publishing_date' : { 'de' : 'Publikationsdatum', 'en' : 'Publishing date' },
    'right_now' : { 'de' : 'Right now', 'en' : 'Right now' },            
    'right_now_start_1' : { 'de' : 'Right now (Start 1)', 'en' : 'Right now (start 1)' },   
    'inform_email_start' : { 'de' : 'Infomail zum Beginn', 'en' : 'Inform email at start' },   
    'inform_email_end' : { 'de' : 'Infomail zum Ende', 'en' : 'Inform email at end' },       
    'end_date' : { 'de' : 'Enddatum', 'en' : 'End date' },    
    'cancel_date' : { 'de' : 'Enddatum', 'en' : 'Cancel date' },
    'a_later_date' : { 'de' : 'A later date', 'en' : 'A later date' },            
    'a_later_date_start_2' : { 'de' : 'A later date (Start 2)', 'en' : 'A later date (start 2)' },                
    'theme' : { 'de' : 'Thema', 'en' : 'Theme' },
    'title' : { 'de' : 'Titel', 'en' : 'Title' },
    'checkup' : { 'de' : 'Checkup', 'en' : 'Checkup' },
    'checkup_dashboard' : { 'de' : 'Checkup Dashboard', 'en' : 'Checkup Dashboard' },
    'state_of_the_community' : { 'de' : 'Wie es uns geht...', 'en' : 'State of the community' },
    'prosperland_tree' : { 'de' : 'Prosperland Tree', 'en' : 'Prosperland Tree' },
    'get_everyone_involved' : { 'de' : 'Aktivieren', 'en' : 'Get everyone involved' },
    'available_checkups' : { 'de' : 'Available Checkups', 'en' : 'Available Checkups' },
    'improve_learn_and_adapt' : { 'de' : 'Verbessern und anpassen', 'en' : 'Improve, learn and adapt' },
    'checkup_history' : { 'de' : 'Checkup History', 'en' : 'Checkup History' },
    'our_track_record_this_quarter' : { 'de' : 'Our track record this quarter', 'en' : 'Our track record this quarter' },
    'sense_of_belonging' : { 'de' : 'Bester Ort zu leben?', 'en' : 'Sense of belonging' },
    'work_life_balance' : { 'de' : 'Zukunftsfähig?', 'en' : 'Work life balance' },  
    'positive_challenges' : { 'de' : 'Zusammenhalt?', 'en' : 'Positive challenges' },
    'view_details' : { 'de' : 'View details', 'en' : 'View details' },  
    'what_moves_us' : { 'de' : 'What moves us?', 'en' : 'What moves us?' },  
    'start_feedback' : { 'de' : 'Start feedback', 'en' : 'Start feedback' },
    'plan_feedback' : { 'de' : 'Updates planen', 'en' : 'Plan feedback' },
    'start_activity' : { 'de' : 'Start activity', 'en' : 'Start activity' },  
    'plan_action' : { 'de' : 'Eine Aktion starten', 'en' : 'Plan action' },
    'company': { 'de' : 'Unternehmen', 'en' : 'Company' },
    'project' : { 'de' : 'Projekt', 'en' : 'Project'  },
    'pieboard' : { 'de' : 'Pieboard', 'en' : 'Pieboard' },
    'how_do_we_see_our_workday' : { 'de' : 'Wie sehen wir unseren Arbeitstag?', 'en' : 'How do we see our workday?' },
    'our_state_of_mind' : { 'de' : 'Glück und Unglück', 'en' : 'Our state of mind' },  
    'our_state_of_mind_and_what_has_shaped_it' : { 
        'de' : 'Unsere Verfassung und was sie geprägt hat', 
        'en' : 'Our state of mind and what has shaped it' },    
    'what_makes_us' : { 'de' : 'Was macht uns', 'en' : 'What makes us' },
    'in_our_workday_reality': { 'de' : 'in unserer Arbeitsrealität', 'en' : 'in our workday reality' },
    'pie_charts' : { 'de' : 'Tortendiagramm', 'en' : 'Pie chart' },
    'edit_recipient' : { 'de' : 'Empfänger bearbeiten', 'en' : 'Edit recipient' },    
    'add_recipients' : { 'de' : 'Empfänger hinzufügen', 'en' : 'Add recipients' },
    'upload_csv' : { 'de' : 'CSV hochladen', 'en' : 'upload CSV' },    
    'landingpage' : { 'de' : 'Landingpage', 'en' : 'Landingpage' },
    'go_to_landingpage' : { 'de' : 'Go to Landingpage', 'en' : 'Go to Landingpage' },  
    'ecosystem' : { 'de': 'Ökosystem', 'en' : 'Ecosystem'  },  
    'my_prosperland' : { 'de': 'Mein Prosperland', 'en' : 'My Prosperland'  },  
    'table_of_results' : { 'de' : 'Alle Ergebnisse als Tabelle', 'en' : 'Table of results'  },
    'configuration' : { 'de' : 'Konfiguration', 'en' : 'configuration'  },
    'calendar' : { 'de' : 'Kalender', 'en' : 'calendar'  },
    'action' : { 'de' : 'Action', 'en' : 'Action'  },
    'topic' : { 'de' : 'Topic', 'en' : 'Topic'  },
    'sub_head' : { 'de' : 'Untertitel', 'en' : 'Sub head'  },
    'sub_text' : { 'de' : 'Subtext', 'en' : 'Sub text'  },    
    'body_text' : { 'de' : 'Haupttext', 'en' : 'Body text'  },
    'kind_of_feedback' : { 'de' : 'Art der Rückmeldung', 'en' : 'Kind of feedback'  },
    'kind_of_action' : { 'de' : 'Kind of action', 'en' : 'Kind of action'  },    
    'headline' : { 'de' : 'Kopfzeile', 'en' : 'Headline'  },
    'do_something_now' : { 'de' : 'Do something now', 'en' : 'Do something now'  },
    'discussion_forum' : { 'de' : 'Diskussions- und Entscheidungsforum', 'en' : 'Discussion forum'  },
    'kpi' : { 'de' : 'KPI', 'en' : 'KPI'  },
    'general_kpi' : { 'de' : 'General KPI', 'en' : 'General KPI'  },
    'export_results' : { 'de' : 'Ergebnisse exportieren', 'en' : 'Export results'  },
    'why_are_people' : { 'de' : 'Warum sind Menschen', 'en' : 'Why are people'  },
    'we_who_are' : { 'de' : 'Wir, die wir', 'en' : 'We, who we are'  },
    'sind' : { 'de' : 'sind', 'en' : ''  },
    'for_how_many_of_us_is_this_true' : { 'de' : 'Für wie viele von uns ist das gültig', 'en' : 'For how many of us is this true'  },
    'happy' : { 'de' : 'glücklich', 'en' : 'happy' },
    'happiness_index': { 'de' : 'Glücksindex', 'en' : 'Happiness index' },
    'happiness': { 'de' : 'Glück', 'en' : 'happiness' },
    'satisfied' : { 'de' : 'zufrieden', 'en' : 'satisfied' },
    'satisfaction' : { 'de' : 'Zufriedenheit', 'en' : 'satisfaction' },
    'unsatisfied' : { 'de' : 'unzufrieden', 'en' : 'unsatisfied' },
    'unsatisfaction' : { 'de' : 'unsatisfaction', 'en' : 'unsatisfaction' },
    'dissatisfied' :  { 'de' : 'unzufrieden', 'en' : 'dissatisfied' },
    'dissatisfaction' :  { 'de' : 'Unzufriedenheit', 'en' : 'dissatisfaction' },
    'unhappy' : { 'de' : 'unglücklich', 'en' : 'unhappy' },
    'female' : { 'de' : 'weiblich', 'en' : 'female' },
    'male' : { 'de' : 'männlich', 'en' : 'male' },
    'under_30_years' : { 'de' : 'unter 30 Jahre', 'en' : 'under 30 years' },
    'over_40_years' : { 'de' : 'über 40 Jahre', 'en' : 'over 40 years' },
    'single' : { 'de' : 'alleinstehend', 'en' : 'single' },
    'has_family' : { 'de' : 'hat Familie', 'en' : 'has family' },
    'single_parent' : { 'de' : 'alleinerziehend', 'en' : 'single parent' },
    'unhappiness' : { 'de' : 'Unglück', 'en' : 'unhappiness' },
    'the_top_reasons_that_are_key' : { 'de' : 'Die wichtigesten Gründe um', 'en' : 'the top reasons that are key' },
    'the_top_reasons_that_are_key_to' : { 'de' : 'Die wichtigesten Gründe um', 'en' : 'The top reasons that are key to' },
    'among_ourselves' : { 'de' : 'zu sein', 'en' : 'among ourselves' },
    'by_relative_impact_and_experience_range' : { 'de' : 'Durch relative Wirkung und Erfahrungsbereich', 'en' : 'By relative impact and experience range' },
    'strongest' : { 'de' : 'stärstes', 'en' : 'strongest' },
    'weakest' : { 'de' : 'schwächstes', 'en' : 'weakest' },
    'campaigns' : { 'de' : 'Kampagnen', 'en' : 'Campaigns' }, 
    'flash_feedback' : { 'de' : 'Flash-Feedback', 'en' : 'Flash-Feedback' }, 
    'momentum' : { 'de' : 'Puls', 'en' : 'Momentum' },
    'momentum_survey' : { 'de' : 'Puls-Befragung', 'en' : 'Momentum survey' },
    'momentum_surveys' : { 'de' : 'Puls-Befragung', 'en' : 'Momentum surveys' },
    'momentum_surveys_cx' : { 'de' : 'Puls-Befragungen CX', 'en' : 'Momentum surveys CX' },  
    'momentum_surveys_ex' : { 'de' : 'Puls-Befragungen EX', 'en' : 'Momentum surveys EX' },  
    'momentum_cx' : { 'de' : 'Puls-Befragung CX', 'en' : 'Momentum CX' },
    'momentum_ex' : { 'de' : 'Puls-Befragung EX', 'en' : 'Momentum EX' },
    'momentum_checklist' : { 'de' : 'Puls Checkliste', 'en' : 'Momentum checklist' },
    'respondents_selection' : { 'de' : 'Respondents selection', 'en' : 'Respondents selection' },
    'audience_selection' : { 'de' : 'Teilnehmer Auswahl', 'en' : 'Audience selection' },    
    'leadership' : { 'de' : 'Leadership', 'en' : 'Leadership' },
    'team_spirit' : { 'de' : 'Team spirit', 'en' : 'Team spirit' },    
    'clear_targets' : { 'de' : 'Clear targets', 'en' : 'Clear targets' },   
    'clear_values' : { 'de' : 'Clear values', 'en' : 'Clear values' },
    'open_culture_of_discussion' : { 'de' : 'Open culture of discussion', 'en' : 'Open culture of discussion' }, 
    'empowerment': { 'de' : 'Empowerment', 'en' : 'Empowerment'  },
    'tools_procedures': { 'de' : 'Tools - Procedures', 'en' : 'Tools - procedures'  },
    'availability': { 'de' : 'Availability', 'en' : 'Availability'  },
    'usability' : { 'de' : 'Usability', 'en' : 'Usability'  },
    'efficiency' : { 'de' : 'Efficiency', 'en' : 'Efficiency'  },
    'roadmap' : { 'de' : 'Roadmap', 'en' : 'Roadmap'  },    
    'process_of_change' : { 'de' : 'Process of change', 'en' : 'Process of change'  },
    'reasonable_speed' : { 'de' : 'Reasonable speed', 'en' : 'Reasonable speed'  },
    'icp_information_and_communication_providers' : { 
        'de' : 'ICP - Informations- und Kommunikationsanbieter', 'en' : 'ICP - Information and Communication Providers'},
    'our_workday_ecosystem_topics' : { 'de' : 'Unsere Ökosystemthemen des Arbeitsalltags', 'en' : 'Our workday ecosystem topics'},
    'overall_performance' : { 'de' : 'Overall Performance', 'en' : 'Overall Performance' },
    'overall' : { 'de' : 'Overall', 'en' : 'Overall' },    
    'network_planning_design_optimization' : { 'de' : 'Network Planning, Design & Optimization', 'en' : 'Network Planning, Design & Optimization' },
    'password': { 'de' : 'Passwort', 'en' : 'Password'  },
    'campagne': { 'de' : 'Kampagne', 'en' : 'Campagne'  },
    'publishing_calendar': { 'de' : 'Publishing Calendar', 'en' : 'Publishing calendar'  },
    'plan_communication' : { 'de' : 'Plan communication', 'en' : 'Plan communication'  },
    'plan_publication' : { 'de' : 'Ergebnis publizieren', 'en' : 'Plan publication'  },
    'publication_checklist' : { 'de' : 'Checkliste Publikation', 'en' : 'Publication checklist'  },
    'forum' : { 'de' : 'Forum', 'en' : 'forum'  },
    'send' : { 'de' : 'Senden', 'en' : 'send'  },
    'sending' : {  'de' : 'sende', 'en' : 'sending'  },
    'edit' : { 'de' : 'bearbeiten', 'en' : 'edit'  },
    'save' : { 'de' : 'sichern', 'en' : 'Save'  }, 
    'delete' : { 'de' : 'löschen', 'en' : 'Delete'  },  
    'deactivate' : { 'de' : 'deaktivieren', 'en' : 'Deactivate' },
    'your_profile' : { 'de' : 'Dein Profil', 'en' : 'Your profile'  },
    'project_is_created' : { 'de' : 'Projekt wird angelegt', 'en' : 'creating project'  },  
    'skip_this_step' : { 'de' : 'Diesen Schritt überspringen', 'en' : 'Skip this step'  },
    'unknown' : { 'de' : 'Unbekannt', 'en' : 'Unknown'  },
    'user_unknown' : { 'de' : 'Der Benutzer ist noch nicht bekannt.', 'en' : 'The user is unknown.'  },
    'use_normal_login' : { 'de' : "Falls Du schon registriert bist, dann logge dich bitte regulär unter 'Login', ein.",
        'en' : "If you are already registered, please log in under 'Login'"  },
    'weekly_newsletter' : { 'de' : 'Ich möchte den wöchentlichen Newsletter zu Themen rund um die digitale Arbeit u.ä. erhalten.',
        'en' : 'I would like to receive the weekly newsletter on topics related to digital work.'  },
    'write_message': { 'de' : 'Schreib uns eine Nachricht', 'en' : 'Please leave us a message'  }
}

let meRegister = {
    'general' : {
        'accept_agb' : { 'de' : 'Bitte akzeptiere unsere Datenschutzerklärung und die Allgemeinen Geschäftsbedingungen.',
            'en' : 'Please accept our privacy policy, and the terms and conditions.'  },
        'change_email' : { 'de' : 'Email ändern', 'en' : 'change email'  },
        'choose_secure_password' : { 
            'de' : 'Wähle ein sicheres Passwort, um Dein Benutzerkonto gut zu schützen.', 'en' : 'Set a password for your account.'  },
        'check' : { 'de' : 'Prüfe', 'en' : 'Checking'  },
        'company_placeholder' : { 'de' : 'z.B. Deinunternehmen GmbH', 'en' : 'Your company Ltd.'  },
        'company_orga_on_prosper' : { 'de' : 'Dieser Name wird genutzt, um eine Organisation auf Prosperland zu erstellen.',
            'en' : 'This name is used to create an organization on Prosperland.'  },
        'company_name_plaese' : { 'de' : 'Wie heißt Dein Unternehmen/Team?', 'en' : 'What is the name of your company/team?'  },
        'company_failed' : { 'de' : 'Organisation konnte nicht angelegt werden!', 'en' : 'Could not create organization!'  },
        'create_account_accept' : { 'de' : 'Mit dem Erstellen des Accounts akzeptiere ich die', 'en' : 'By creating the account I accept the'  },
        'create_account' : { 'de' : 'Account erstellen', 'en' : 'Create account'  },
        'enter_valid_email_format' : { 'de' : 'Bitte gib eine gültige Emailadresse an.', 'en' : 'Please enter a valid email address'  },
        'registration' : { 'de' : 'Registrierung', 'en' : 'registration'  },
        'failed_to_send_users' : {  'de' : 'Versenden der Userliste ist gescheitert.', 'en' : 'Failed to send user list'  },
        'give_project_name_to_create' : { 
            'de' : 'Gib den Namen eines aktuellen Projekts oder einer Abteilung ein - wir erstellen direkt den ersten Arbeits-Raum für Dich!',
            'en' : 'Enter the name of a current project or department - we will create the first workspace for you right away!'  },
        'first_project_on_prosper': { 
            'de' : 'Wie soll Dein erstes Projekt auf Prosperland benannt werden?', 'en' : 'What is the name of your first project on Prosperland?'  },
        'select_your_template': { 'de' : 'Vorlage für dein Prosperland Projekt', 'en' : 'Template for your Prosperland project'  },  
        'select_fitting_template' : { 
            'de' : 'Wähle eine Vorlage welche am besten zu Deinem Projekt passt.', 'en' : 'Choose a template that best suits your project.'  },
        'configure_your_product': { 'de' : 'Konfiguriere Dein erstes Prosperland Projekt', 'en' : 'Configure your first Prosperland project'  },  
        'configure_your_product_defaults' : { 'de' : 'Verwende die voreingestellten Werte, oder passe sie an Deine Bedürfnisse an.', 
            'en' : 'Use the default values ​​or adapt them to your needs.'  },
        'what_do_you_think_about_the_service' : { 'de' : 'Wie schätzt du das Angebot ein?', 'en' : 'What do you think about the service?'},
        'configure_your_product_label_1': { 'de' : 'Insel', 'en' : 'Island'  },  
        'configure_your_product_label_x': {  'de' : 'Inseln', 'en' : 'Islands'  },  
        'configure_your_product_label_y': { 'de' : 'Insel/n', 'en' : 'Island/s'  },  
        'configure_your_product_version' : { 'de' : 'Version', 'en' : 'Version'  },
        'configure_your_product_employee' : { 'de' : 'Mitarbeiter / Teilnehmer', 'en' : 'Employees / Participants'  },
        'configure_your_product_languages' : {  'de' : 'Sprachen', 'en' : 'Languages'  },
        'configure_your_product_admins' : {  'de' : 'Administratoren', 'en' : 'Administrators'  },
        'base_price' : {  'de' : 'Basispreis',  'en' : 'Base price'  },
        'singular_plural_employee' : {  'de' : 'Mitarbeiter',  'en' : 'employee/s'  },
        'singular_plural_admin' : { 'de' : 'Administrator/en', 'en' : 'Administrator/s'  },
        'singular_plural_langs' : { 'de' : 'Sprache/n', 'en' : 'Language/s'  },                    
        'configure_your_product_template' : { 'de' : 'Vorlage', 'en' : 'Template'  },
        'project' : { 'de' : 'Projekt', 'en' : 'project'  },
        'project_links' : { 'de' : 'Projekt Links', 'en' : 'project links'  },
        'configure_your_product_ecosystems' : { 'de' : 'Ökosysteme', 'en' : 'Ecosystems'  },
        'configure_your_product_ecosystems_label_ecosystem' : {  'de' : 'Ökosystem', 'en' : 'Ecosystem'  },
        'define_and_manage_your' : { 'de' : 'Definiere und verwalte dein', 'en' : 'define and manage your' },
        'manage_your_calendar' : { 'de' : 'Verwalte Deinen Kalender', 'en' : 'manage your calendar' },
        'initial_onboarding_price' : { 'de' : 'Einmalige Einrichtungsgebühr', 'en' : 'Initial onboarding price'  },            
        'product_price_per_month' : { 'de' : 'Produktpreis monatlich', 'en' : 'Product price per month'  },            
        'configure_your_product_monthly_costs' : { 'de' : 'Das konfigurierte Paket kostet monatlich', 'en' : 'The configured package costs per month' },
        'long_text_onboarding' : { 'de' : 'Mit der ersten kostenpflichtigen Rechnung, wird eine einmalige Einrichtungsgebühr gestellt.',
            'en' : 'An initial onboarding fee will also be charged with the first chargeable invoice.'  },
        'initial_onboarding_amounts_to' : { 'de' : 'Die einmalige Einrichtungsgebühr beläuft sich auf: ', 'en' : 'The initial onboarding amounts to: ' },
        'configure_your_product_testing' : { 'de' : 'In der Testphase kostet es Dich nichts!', 'en' : 'During the test phase it costs you nothing!'  },
        'add_colleagues_via_email' : { 
            'de' : 'Füge Deine Kollegen mittels der E-Mail-Adressen hinzu, damit diese mit Dir zusammen auf Prosperland arbeiten können.',
            'en' : 'Add your colleagues via their email addresses so they can work with you on Prosperland.'  },
        'upload_csv_with_colleagues_emails' : { 
            'de' : 'Lade eine CSV Datei hoch, welche die E-Mail-Adressen deiner Kollegen enthält. So könnt Ihr zusammen auf Prosperland arbeiten.',
            'en' : 'Upload a CSV file with your colleagues email addresses, so they can work with you on Prosperland.'  },            
        'who_is_working_with_you': { 'de' : 'Wer arbeitet mit Dir an diesem Projekt?', 'en' : 'Who is working with you on this project?'  },
        'upload_csv_file_with_respodent_emails': { 'de' : 'Lade eine CSV-Datei mit Respondent-Emails hoch', 'en' : 'Upload a CSV-file with respodent-emails.'  },
        'orga_manage_user' : { 'de' : 'Hier kannst Du alle Deine Nutzer verwalten und Berechtigungen festlegen.', 
            'en' : 'Here you can manage all your users and set permissions.'  },
        'project_failed' : { 'de' : 'Projekt konnte nicht angelegt werden!', 'en' : 'Project could not be created!'  },
        'new_otp_failed_try_again' : { 
            'de' : 'Es konnte kein One-Time-Password angefordert werden. Eventuell wurdest Du kurzfristig gesperrt, bitte versuche es in ein paar Minuten erneut.',
            'en' : 'Could not request a one-time password. You may have been temporarily banned, please try again in a few minutes.'  },
        'free_account' : {  'de' : 'Kostenlosen Account erstellen', 'en' : 'Create a free account'  },
        'invalid_email_format' : { 'de' : 'Ungültiges Email Format, bitte korrigieren.', 'en' : 'Invalid email format, please correct.'  },
        'invalid_first_lastname' : { 'de' : 'Vorname und Nachname müssen mindestens 2 Zeichen enthalten, bitte korrigieren.', 
            'en' : 'First name and last name must contain at least 2 characters, please correct.'  },
        'must_have_min_2_chrs' : { 'de' : 'muss mindestens 2 Zeichen enthalten.', 'en' : 'must contain at least 2 characters.'  },
        'verification_failed' : { 'de' : 'Überprüfung gescheitert, bitte erneut versuchen.', 'en' : 'Verification failed, please try again.'  },
        'verification_six_digits' : { 'de' : 'Zur Verifizierung Deines Accounts haben wir Dir gerade einen 6-stelligen Code gesendet an', 
            'en' : 'To verify your account, we have sent a 6-digit code to'  },
        'failed_register_user_shop' : { 'de': 'Fehler beim Registrieren des Benutzers für den Shop', 'en': 'Failed to register user for shop' },
        'forgot_password' : { 'de' : 'Passwort vergessen?', 'en' : 'Forgot your password?'  },
        'set_password_for_account' : { 'de' : 'Setze ein Passwort für Dein Benutzerkonto', 'en' : 'Set a password for your user account'  },
        'password_to_weak' : { 'de' : 'Passwort ist zu schwach.', 'en' : 'Password is too weak'  },
        'password_to_weak_explanantion' : { 
            'de' : 'Bitte gib ein Passwort ein, das aus mehreren Sonderzeichen, Zahlen, Klein- und Grossbuchstaben besteht.',
            'en' : 'Please enter a password consisting of several special characters, numbers, lowercase and uppercase characters'  },
        'password_format_wrong' : { 'de' : 'Ungültiges Passwort-Format.', 'en' : 'Invalid password format'  },
        'password_min_seven' : { 
            'de' : 'Passwort sollte mindestens 7 Zeichen lang sein, mindestens eine Zahl, einen Grossbuchstaben und einen Kleinbuchstaben enthalten.',
            'en' : 'Password should be at least 7 characters long and it should contain at least one numeric digit, one uppercase and one lowercase letter.'  },
        'project_placeholder' : { 'de' : 'z.B. Flyererstellung, Relaunch', 'en' : 'e.g. relaunch, flyer production'  },
        'user_not_found' : { 'de' : 'Benutzer nicht gefunden, bitte registriere dich.', 'en' : 'User not found, please register.'  },
        'start_registration' : { 'de' : 'Starte deine Registrierung', 'en' : 'Start your registration'  }
    },
    'test' : {  
        'start' : { 'de' : 'Starte deine Testphase', 'en' : 'Start your test phase'  },
        'end' : { 'de' : 'Keine Verpflichtungen. Der Test endet automatisch.', 'en' : 'No obligations. The test ends automatically.'  }
    }
};

let meLanding = {
    'write_us_and': { 'de': 'Schreib uns, und wir schreiben am selben Tag zurück.',
        'en' : "Leave us a message, and we will get back to you within a day"  },
    'for_your_employees': { 'de' : 'Für deine Belegschaft?', 'en' : 'For your employees?'  },
    'for_your_people': { 'de': 'Für deine Kollegen?', 'en' : 'For your colleagues?'  },
    'for_your_teams' : { 'de': 'Für deine Teams?', 'en' : 'For your teams?'  },
    'wanna_have_too': { 'de': 'Möchtest du auch?', 'en' : 'Would you like as well?'  },
    'distant_teams_long': { 
        'de' : 'Digitalisierung und ‚remote‘ Office gehen Hand in Hand. Die Arbeitswelt wird virtueller, die Kommunikation verändert sich, persönliche Kontakte werden formalisiert, gleichzeitig wird Veränderung zu einem Dauerzustand. Die Teams des Unternehmens entfernen sich nicht nur räumlich voneinander, sondern auch in ihrer Arbeitsweise. Wie lässt sich dennoch verstehen, wie sie zurechtkommen, wo sie Unterstützung brauchen? Hilfe zur Selbsthilfe willkommen wäre? Prosperland bringt sie wieder zusammen, unaufdringlich aber fundiert.',
        'en' : "Digitization and 'remote' office go hand in hand. The world of work is becoming more virtual, communication is changing, personal contacts are being formalized, while at the same time change is becoming a permanent condition. The company's teams are not only moving away from each other geographically, but also in the way they work. How can we still understand how they are coping, where they need support? Is help for self-help welcome? Prosperland brings them back together, unobtrusively but well-founded."  },
    'distant_teams' : { 'de' : 'Auch entfernte Teams zusammenhalten!', 'en' : 'Keep distant teams together too!'  },
    'idea_behind_long': { 
        'de' : 'Das Bild eines Ökosystems, in dem es ‚Lebewesen‘ gut geht, entsteht um einen Kreislauf herum. Wasser und Nahrung durchlaufen einen immerwährenden Zyklus. Das Licht wirkt als Energie. In unserem digitalisierten Arbeitsumfeld gibt es für alles eine Entsprechung. Aus Erfolgserlebnissen, Empathie, Frustrationen, setzt sich die Lebensqualität zusammen, die jeder empfindet. Was trägt die digitalisierte Arbeit dazu positiv oder negativ bei? Prosperland schafft ein Gesamtbild und ein anderes für jeden Einzelnen.',
        'en' : "The image of an ecosystem in which 'living beings' thrive arises around a cycle. Water and food go through a perpetual cycle. The light acts as energy. In our digitized work environment, there is an equivalent for everything. The quality of life that everyone feels is made up of a sense of achievement, empathy and frustration. How does digitized work contribute positively or negatively to this? Prosperland creates an overall picture and a different one for each individual."  },
    'idea_behind': { 'de' : 'Die Idee hinter Prosperland', 'en' : 'The idea behind Prosperland'  },
    'ecosystem' : { 'de': 'Ökosystem', 'en' : 'Ecosystem'  },
    'step_into_prosper': { 'de': 'Hier kommst du rein ins Prosperland', 'en' : 'Here you enter Prosperland'  },
    'your_key': { 'de': 'Dein Schlüssel', 'en' : 'Your key'  },
    'what_you_can_do' : { 'de' : 'Das kannst du tun im Prosperland', 'en' : 'What you can do in Prosperland'  },
    'your_workout' : { 'de' : 'Dein Workout', 'en' : 'Your workout'  },
    'prosper_landscape': { 'de' : 'Das sind die Prosperland-Landschaften', 'en' : 'These are the Prosperland landscapes'  },
    'your_travel_guide': { 'de' : 'Dein  Reiseführer', 'en' : 'Your travel guide'  },
    'welcome_to_prosper' : { 'de' : 'Willkommen im Prosperland!', 'en' : 'Welcome to Prosperland!'  },
    'let_us_show' : { 'de' : 'Schau dich um!', 'en' : 'Have a look around!'  }
};

// Object.entries(myGeneral).forEach(([key, val]) => { myGeneral[key]['en'] = myGeneral[key]['de'] });
// Object.entries(meRegister.general).forEach(([key, val]) => { meRegister.general[key]['en'] = meRegister.general[key]['de'] });
// Object.entries(meRegister.test).forEach(([key, val]) => { meRegister.test[key]['en'] = meRegister.test[key]['de'] });
// Object.entries(meLanding).forEach(([key, val]) => { meLanding[key]['en'] = meLanding[key]['de'] });

let meDefault = {
    'general' : myGeneral,
    'register' : meRegister,
    'landing' : meLanding           
};

export const guiText = writable(meDefault)