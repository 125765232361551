import App from "./App.svelte";

const grab_lang = () => {
  let browser_lang_split = navigator.language.split("-");
  let selected_lang = 'de';
  if ( browser_lang_split.length > 0 && [ 'de', 'en'].includes(browser_lang_split[0]) ) {
    selected_lang = browser_lang_split[0];
  }
  return selected_lang;
}

const app = new App({
  target: document.getElementById("app"),
  props: {
    my_lang : grab_lang()
    // my_lang : "en"
  }
});

export default app;
