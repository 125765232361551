<script>
  export let color = "light";
  export let popup_pie_id;
  export let popup_pie_header;
  export let toggleModalChartPie;
  export let body_text;
  export let popup_pie_label;
</script>

<style>
  #main_popup {
    display: grid;
    grid-template-columns: 100%;
    grid-template-rows: auto auto auto auto;
    position: relative; 
    z-index: 50; 
  }

  #main_popup_header { grid-column: 1; grid-row: 1;}
  #main_popup_body { grid-column: 1; grid-row: 2;}
  #main_popup_note { 
    grid-column: 1; 
    grid-row: 3;
    background-color: #d5d4d5; 
  }
  #main_popup_legend { grid-column: 1; grid-row: 4;}

  .center {
    display: block;
    margin-left: auto;
    margin-right: auto;
    width: 100%;
  }

  .prosper_image_full { object-fit: none; }  

</style>

<div id="prosper_overlay"></div>
      
<div class="relative flex flex-col min-w-0 break-words w-full shadow-lg rounded {color === 'light' ? 'bg-white' : 'bg-red-800 text-white'}">  
  <div class="overflow-x-hidden overflow-y-auto fixed z-50" >
    <div class="border-0 rounded-lg shadow-lg w-full h-full bg-white" >                    
      <div class="mb-0 border-0">

        <div id="main_popup">
          <div id="main_popup_header" class="relative flex">
            
            <div 
              class="lg:w-10/12 w-full prosper_text_l2_strong_weak block font-semibold text-center
                      {color === 'light' ? 'text-blueGray-700' : 'text-white'}">                          
              {#if ["5", "5_4", "5_4_3", "5_4_3_2"].includes(popup_pie_id) }
                {popup_pie_header}
              {/if}
            </div>            

            <div>
              <button 
                class="lg:w-2/12 bg-red-400 text-white float-right active:bg-red-500  
                        font-bold uppercase text-xs px-4 py-2 rounded shadow" 
                type="button" 
                on:click={toggleModalChartPie} > x </button>
            </div>

          </div>

          <div id="main_popup_body"> 
            {#if popup_pie_id === "5"}
              <img class="prosper_image_full" alt="happy" src="../assets/img/fke_torte_happy.png" /> 
            {:else if popup_pie_id === "5_4"}
              <img class="prosper_image_full" alt="satisfied" src="../assets/img/fke_torte_sat.png" />     
            {:else if popup_pie_id === "5_4_3"}
              <img class="prosper_image_full" alt="unsatisfied" src="../assets/img/fke_torte_unsat.png" />     
            {:else if popup_pie_id === "5_4_3_2"}
              <img class="prosper_image_full" alt="unhappy" src="../assets/img/fke_torte_unhappy.png">   
            {/if}
          </div>

          <div id="main_popup_note">
            <div class="text-blueGray-900 text-center text-sm mb-2 px-2"> 
              {#if ["5", "5_4", "5_4_3", "5_4_3_2"].includes(popup_pie_id) }
                {body_text}
              {/if}                
            </div>
          </div>          

          <div id="main_popup_legend"> 
            {#if ["5", "5_4", "5_4_3", "5_4_3_2"].includes(popup_pie_id) }
              <img class="prosper_image_full center" alt="{popup_pie_label}" src="../assets/img/legende_tortendiagramm.png" />    
            {/if}            
          </div>

        </div>

        </div>
      </div>
    </div>
  </div>
