import { writable} from "svelte/store";

let cx = [ 
    { 'pos' : 1, 
      'lbl': 'Dashboard tutorial', 
      'lnk' : 'vzZISzRObs4',
      'exp' : 'https://z327640.invisionservice.com/forum/256-fragen-zum-dashboard/' },
    { 'pos' : 2, 
      'lbl': 'Island layout tutorial',  
      'lnk' : 'vzZISzRObs4', 
      'exp' : 'https://z327640.invisionservice.com/forum/257-fragen-zur-inseldarstellung/' },
    { 'pos' : 3, 
      'lbl': 'Landing page tutorial',  
      'lnk' : 'vzZISzRObs4', 
      'exp' : 'https://z327640.invisionservice.com/forum/258-fragen-zur-landingpage/' },
    { 'pos' : 4, 
      'lbl': 'Configuration page tutorial', 
      'lnk' : 'vzZISzRObs4', 
      'exp' : 'https://z327640.invisionservice.com/forum/259-fragen-zur-konfigurationsseite/' },

    { 'pos' : 5, 
      'lbl': 'Prosperland in general', 
      'lnk' : '', 
      'exp' : 'https://z327640.invisionservice.com/forum/255-fragen-zu-prosperland-allgemein/' },
    { 'pos' : 6, 
      'lbl': '', 
      'lnk' : '', 
      'exp' : '' },
    { 'pos' : 7, 
      'lbl': '', 
      'lnk' : '', 
      'exp' : '' },
    { 'pos' : 8, 
      'lbl': '', 
      'lnk' : '', 
      'exp' : '' }                        


  ];

  let myDefault = { 'cx' : cx }

export const servicesData = writable(myDefault)