import {apiMaster} from "../api/ApiMaster";  

export async function tenantsGet (data) {
    data.authO.loading = true;

    let requestOptions = {
        method: 'GET',
        headers: { 
            'Content-Type': 'application/json', 
            'X-Custom-Header': 'application/json',
            'Authorization' : `Bearer ${data.authO.bearer}`
        }    
    };

    let resp = await apiMaster(
        data,
        `${data.authO.uri_prosper_api}/tenants`, 
        requestOptions
    );

    let tenantsAry = await resp.data;
    
    resp['o'] = {};
    tenantsAry.forEach(e => resp['o'][e.uuid] = e );

    return resp;
}
