<script>
  import { navigate } from "svelte-routing";
  import { onMount } from "svelte";  
  import AdministrationProsperlandLinks from "components/Cards/AdministrationProsperlandLinks.svelte";
  import NoRights from "components/Cards/NoRights.svelte";

  import {guiText} from "../../tstore/guiText"; 

  // import {checkWhoAmI} from "../../lib/checkAuthObject";  
  import {myAuthObject} from "../../tstore/myauth";
  import {checkWhoAmI, checkForRoleAdmin, checkForRoleAudience, checkForRoleProject} from '../../lib/checkAuthObject.js'; 
//   import { select_option } from "svelte/internal";
//   import { logInPost } from "../../api/LogInPost";

  export let my_lang;
  export let color = "light";

  if ( checkWhoAmI($myAuthObject) === false || $myAuthObject.whoami.valid === false ) {
    navigate('/auth/login', { replace: true }); 
  }

  const roleAdminStatus = checkForRoleAdmin($myAuthObject);
  const roleAudienceStatus = checkForRoleAudience($myAuthObject);
  const roleProjectStatus = checkForRoleProject($myAuthObject);

  onMount(async () => { 
    
    $myAuthObject.shop_status.status 
    if ( $myAuthObject.shop_status.hasOwnProperty('status') === false ) { 
      navigate('/auth/login', { replace: true });
    }

    if ( roleAdminStatus === false && roleAudienceStatus === false && roleProjectStatus === false ) { navigate('/auth/login', { replace: true });  };
  });

</script>



    {#if $myAuthObject['shop_status']['status'] === 'open' }
      {#if roleAdminStatus === true || roleAudienceStatus === true || roleProjectStatus === true } 

        <AdministrationProsperlandLinks my_lang="{my_lang}"/>

      {:else}
        <div class="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded {color === 'light' ? 'bg-white' : 'bg-red-800 text-white'}">                
          <NoRights my_lang="{my_lang}" 
          my_msg_head="{$guiText.general.no_rights[my_lang]}!" 
          my_msg_body="{$guiText.general.no_rights_to_log_in[my_lang]}."
          my_msg_link="" 
          my_msg_link_txt="" />          
        </div>
      {/if}        
    {/if}






    {#if $myAuthObject['shop_status']['status'] === 'closed' }
    <div class="flex flex-wrap mt-4">
      <div class="w-full mb-12 px-4">

      <div class="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded {color === 'light' ? 'bg-white' : 'bg-red-800 text-white'}">
        {#if roleAdminStatus === true }
          <NoRights my_lang="{my_lang}" 
          my_msg_head="{$guiText.general.closed_project[my_lang]}" 
          my_msg_body="{$guiText.general.project_was_closed_contact_team[my_lang]}"
          my_msg_link="" 
          my_msg_link_txt="" />        
        {:else}
          <NoRights my_lang="{my_lang}" 
          my_msg_head="{$guiText.general.closed_project[my_lang]}" 
          my_msg_body="{$guiText.general.project_is_actually_closed[my_lang]}"
          my_msg_link="" 
          my_msg_link_txt="" />                  
        {/if}
      </div>
    </div>
  </div>      
    {/if}

    {#if $myAuthObject['shop_status']['status'] === 'init'}
    <div class="flex flex-wrap mt-4">
      <div class="w-full mb-12 px-4">    

      <!--           my_msg_body="{$guiText.general.configure_your_bank_data_in_stripe[my_lang]}"  -->

      <div class="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded {color === 'light' ? 'bg-white' : 'bg-red-800 text-white'}">              
        {#if roleAdminStatus === true && $myAuthObject.shop_status.respondent === $myAuthObject.whoami.email}
          <NoRights my_lang="{my_lang}" 
          my_msg_head="{$guiText.general.configure_bank_data[my_lang]}" 
          my_msg_body="{$guiText.general.configure_your_account[my_lang]}"          
          my_msg_link="{$myAuthObject.uri_ext_account}?prefilled_email={$myAuthObject.whoami.email}"
          my_msg_link_txt="{$guiText.general.here[my_lang]}"
          />
        {:else} 
          <NoRights my_lang="{my_lang}" 
          my_msg_head="{$guiText.general.welcome[my_lang]}" 
          my_msg_body="{$guiText.general.project_will_start_soon[my_lang]}"
          my_msg_link="" 
          my_msg_link_txt="" />
        {/if}              
      </div>
    </div>
  </div>
    {/if}

    {#if $myAuthObject['shop_status']['status'] === false || $myAuthObject['shop_status']['status'] === 'unknown'}
    <div class="flex flex-wrap mt-4">
      <div class="w-full mb-12 px-4">    
      <div class="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded {color === 'light' ? 'bg-white' : 'bg-red-800 text-white'}">                  
        <NoRights my_lang="{my_lang}" 
        my_msg_head="{$guiText.general.unknown_project[my_lang]}" 
        my_msg_body="{$guiText.general.contact_your_manager[my_lang]}"
        my_msg_link="" 
        my_msg_link_txt="" />
      </div>
    </div>
  </div>      
    {/if}



