<script>
  import DashPCampagne from "components/Dashboards/DashPCampagne.svelte";
  import DashPFlashFeedback from "components/Dashboards/DashPFlashFeedback.svelte";

  export let popup_pie_id;
  export let header_text;
  export let toggleModalChartPie;
  export let linkRoot;
  export let linkIterim;
  export let linkMobileCheckUp;
</script>

<style>
  #main_popup {
    display: grid;
    grid-template-columns: 100%;
    grid-template-rows: auto auto auto auto;
    position: relative; 
    z-index: 50; 
  }

  #main_popup_header { grid-column: 1; grid-row: 1;}
  #main_popup_body { grid-column: 1; grid-row: 2;}
  #main_popup_note { 
    grid-column: 1; 
    grid-row: 3;
    background-color: #ffffff; 
  }
  #main_popup_legend { grid-column: 1; grid-row: 4;}

</style>

<div id="prosper_overlay"></div>     
      <div class="relative flex flex-col min-w-0 break-words w-full shadow-lg rounded">  
        <div class="overflow-x-hidden overflow-y-auto fixed z-50" >
          <div class="border-0 rounded-lg shadow-lg w-full h-full bg-white" >                    
            <div class="mb-0 border-0">

        <div id="main_popup">

          <div id="main_popup_header" class="flex">
            <div class="lg:w-10/12 prosper_text_l2_strong_weak block font-semibold text-center">
            {#if popup_pie_id === "momentum"}
                {header_text}                           
            {/if}
            </div>
            
            <div>
              <button 
                class="lg:w-2/12 bg-red-400 text-white float-right active:bg-red-500 font-bold uppercase 
                        text-xs px-4 py-2 rounded shadow" 
                type="button" 
                on:click={toggleModalChartPie} > x </button>
            </div>

          </div>

          <div id="main_popup_body"> 

            {#if popup_pie_id === "campagne"}
              <DashPCampagne myTUrl={`${linkRoot}/calendar?${linkIterim}`} />
            {:else if popup_pie_id === "flash_feedback"}
              <DashPFlashFeedback myTUrl={`${linkMobileCheckUp}`} />
            {/if}

          </div>

          <div id="main_popup_note">
            <div class="text-blueGray-900 text-center text-sm mb-2 px-2"> 
            </div>
          </div>          

          <div id="main_popup_legend">  </div>

        </div>

        </div>
      </div>
    </div>
  </div>