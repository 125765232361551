<script>
  import { navigate } from "svelte-routing";
  import {selectTextOnFocus, blurOnEscape} from '../../lib/inputDirectives.js';  
  import {myAuthObject} from "../../tstore/myauth";
  import {guiText} from "../../tstore/guiText";
  import LightGreyButon from "../../components/Buttons/LightGreyButon.svelte";  
  import {registrationPost} from "../../api/RegistrationPost";  

  export let my_lang;

  const emailRegex = RegExp(
    /^[a-zA-Z0-9][a-zA-Z0-9\.!#$%&'’*+\-/=?^_`{|}~]*?[a-zA-Z0-9._\-]?@[a-zA-Z0-9][a-zA-Z0-9\._\-]*?[a-zA-Z0-9]?\.[a-zA-Z]{2,63}$/
  );

  let isFirstnameOk = false;
  let isLastnameOk = false;
  let isMailOk = false;
  let isAgbOk = false; 
  
  let inputErrorMessage = '';

  $myAuthObject.loading = false;
  $myAuthObject.inputReady = false; 

  const abgPreCheck = () => {
    if ( isFirstnameOk === true && isMailOk === true && isLastnameOk === true && isAgbOk === false) {
      inputErrorMessage = $guiText.register.general.accept_agb[my_lang];
      // document.activeElement.blur();
      // document.getElementById('customCheckAgb').focus();
    }
  }

  const checkInputFirstname = () => { 
    inputErrorMessage = '';
    $myAuthObject.inputReady = false; 
    isFirstnameOk = true;
    
    if ( $myAuthObject.firstname.length === 0 ) { 
      isFirstnameOk = false; 
    }     
    else if ( $myAuthObject.firstname.length < 2 ) { 
      inputErrorMessage = `${$guiText.general.firstname[my_lang]} ${$guiText.register.general.must_have_min_2_chrs[my_lang]}`;
      isFirstnameOk = false;      
    }    
    else if ( isMailOk === true && isLastnameOk === true && isAgbOk === true) {
      $myAuthObject.inputReady = true; 
    }

    abgPreCheck();

  }

  const checkInputLastname = () => { 
    inputErrorMessage = '';
    $myAuthObject.inputReady = false; 
    isLastnameOk = true;
    
    if ( $myAuthObject.lastname.length === 0 ) { 
      isLastnameOk = false; 
    }     
    else if ( $myAuthObject.lastname.length < 2 ) { 
      inputErrorMessage = `${$guiText.general.lastname[my_lang]} ${$guiText.register.general.must_have_min_2_chrs[my_lang]}`;
      isLastnameOk = false;      
    } 
    else if ( isMailOk === true && isFirstnameOk === true  && isAgbOk === true) {
      $myAuthObject.inputReady = true; 
    }

    abgPreCheck();
  }  
  
  const checkInputEmail = () => {
    inputErrorMessage = '';
    $myAuthObject.inputReady = false;    
    isMailOk = true;

    if ( $myAuthObject.email.length === 0 ) {
      isMailOk = false;
    }
    else if ( $myAuthObject.email.length < 7 || emailRegex.test($myAuthObject.email) === false ) {
      inputErrorMessage = $guiText.register.general.enter_valid_email_format[my_lang];
      isMailOk = false;
    } 
    else if (isFirstnameOk === true && isLastnameOk === true && isAgbOk === true){
      $myAuthObject.inputReady = true; 
    }    
    
    abgPreCheck();
  }

  const checkInputAgb = () => {
    inputErrorMessage = '';
    $myAuthObject.inputReady = false; 
    isAgbOk = true;

    if ( $myAuthObject.agb === false ) {
      inputErrorMessage = $guiText.register.general.accept_agb[my_lang];
      isAgbOk = false;
    } 
    else if ( isMailOk === true && isFirstnameOk === true && isLastnameOk === true ){
      $myAuthObject.inputReady = true; 
    }    
  }

  const checkButtonClick = () => {
    if ( $myAuthObject.inputReady === true ) {    
      $myAuthObject.userUuid = '';
      $myAuthObject.bearer = '';
      apiRunner(); 
    }
  }

  const apiRunner = async () => {
    let rawresp = await registrationPost({'authO': $myAuthObject, 'guiTxt': $guiText });
    let resp = await rawresp.data;

    if ( rawresp.status === true && resp.length > 30 ) {
      $myAuthObject.userUuid = resp;
      navigate('/auth/otp', { replace: true });
    } 
    else if ( resp.status === 400 && resp.detail === "User is already registered.") {
      inputErrorMessage = resp.detail;     
      $myAuthObject.loading = false;
      $myAuthObject.inputReady = false;        
      navigate('/auth/login', { replace: true });    
    }
    else { 
      inputErrorMessage = rawresp.message; 
      $myAuthObject.loading = false;
      $myAuthObject.inputReady = false;      
    }
	}

  </script>
  
  <div class="container mx-auto px-4 h-full">
    <div class="flex content-center items-center justify-center h-full">
      <div class="w-full lg:w-6/12 px-4">
        <div class="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg bg-blueGray-200 border-0" >
          <div class="flex-auto px-4 lg:px-10 py-10 pt-0">

            <form>

              <div class="relative py-5"> 
                <h3 class="text-2xl mb-2 font-semibold leading-none">
                  {$guiText.register.general.start_registration[my_lang]} 
                </h3>
              </div>

              <div class="relative w-full mb-3">
                <input
                  id="grid-firstname" 
                  type="text"                  
                  class="border-0 px-3 placeholder-blueGray-300 text-blueGray-900 bg-white rounded text-sm 
                    shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                  placeholder="{$guiText.general.firstname[my_lang]}" 
                  bind:value={$myAuthObject.firstname}
                  on:input={checkInputFirstname}
                  use:selectTextOnFocus 
                  use:blurOnEscape 
                />
              </div>
  
              <div class="relative w-full mb-3">
                <input
                  id="grid-lastname"
                  type="text"                  
                  class="border-0 px-3 placeholder-blueGray-300 text-blueGray-900 bg-white rounded text-sm 
                    shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                  placeholder="{$guiText.general.lastname[my_lang]}"
                  bind:value={$myAuthObject.lastname}
                  on:input={checkInputLastname}
                  use:selectTextOnFocus    
                  use:blurOnEscape                           
                />
              </div>

              <div class="relative w-full mb-3">
                <input
                  id="grid-email"
                  type="email"
                  class="border-0 px-3 placeholder-blueGray-300 text-blueGray-900 bg-white rounded text-sm 
                    shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                  placeholder="{$guiText.general.email[my_lang]}"
                  bind:value={$myAuthObject.email}
                  on:input={checkInputEmail}
                  use:selectTextOnFocus    
                  use:blurOnEscape                           
                />
              </div>
  
              <div>
                <label class="inline-flex items-center cursor-pointer">
                  <input
                    id="customCheckAgb"
                    type="checkbox"
                    bind:checked={$myAuthObject.agb}
                    on:change={checkInputAgb}
                    class="form-checkbox border-1 rounded text-blueGray-400 ml-1 w-5 h-5
                      ease-linear transition-all duration-150"
                    /> 
                  <span class="ml-2 text-xs text-blueGray-400">
                    {$guiText.register.general.create_account_accept[my_lang]} 
                    <a href="{$myAuthObject.uri_mn_datenschutzerklaerung}" 
                      target="_blank"
                      class="text-red-500">
                      {$guiText.general.datenschutzerklaerung[my_lang]} 
                    </a> 
                    und die 
                    <a href="{$myAuthObject.uri_mn_agb}"
                      target="_blank" 
                      class="text-red-500">
                      {$guiText.general.agb[my_lang]}  
                    </a>
                  </span>
                </label>
              </div>
               
              <!-- 
              <div>                
                <label class="inline-flex  py-3 items-center cursor-pointer">
                  <input
                    id="customCheckNewsletter"
                    bind:checked={$myAuthObject.newsletter}
                    type="checkbox"
                    class="form-checkbox border-1 rounded text-blueGray-400 ml-1 w-5 h-5 ease-linear transition-all duration-150"
                    /> 
                  <span class="ml-2 text-xs text-blueGray-400">
                    {$guiText.general.weekly_newsletter[my_lang]}  
                  </span>
                </label>

              </div>
              -->
  
              <div class="text-center mt-6">
 
                {#if $myAuthObject.inputReady === true}
                  {#if $myAuthObject.loading === true}

                    <LightGreyButon bg_color = "bg-blueGray-200">
                      <div slot="button_text">... {$guiText.general.sending[my_lang]}</div>
                    </LightGreyButon>

                  {:else}

                    <button
                      class="bg-blueGray-800 text-white active:bg-blueGray-600 text-sm font-bold px-6 py-3 
                          rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 w-full ease-linear 
                          transition-all duration-150"
                      type="button" 
                      on:click={checkButtonClick}                       
                    > 
                      {$guiText.register.general.create_account[my_lang]}
                    </button>
                  {/if}

                {:else}

                  <LightGreyButon bg_color = "bg-blueGray-200">
                    <div slot="button_text">{$guiText.register.general.create_account[my_lang]}</div>
                  </LightGreyButon>

                {/if}

              </div>
              
              {#if inputErrorMessage.length > 0 }
                <div>
                  <span class="block ml-2 text-xs text-red-400">
                    {inputErrorMessage}
                  </span>                  
                </div>
              {/if}

            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
  
