import {apiMaster} from "../api/ApiMaster";  

export async function usersGet (data) {
    data.authO.loading = true;

    let requestOptions = {
        method: 'GET',
        headers: { 
            'Content-Type': 'application/json', 
            'X-Custom-Header': 'application/json',
            'Authorization' : `Bearer ${data.authO.bearer}`
        }    
    };

    let resp = await apiMaster(
        data,
        `${data.authO.uri_prosper_api}/users`, 
        requestOptions
    );

    let rawd = await resp.data;
    
    rawd.sort((a, b) => {
        if( a.lastName !== undefined && b.lastName !== undefined ) {
            let na = a.lastName.toUpperCase();
            let nb = b.lastName.toUpperCase();
            if (na < nb) { return -1; }
            if (na > nb) { return 1; }
            return 0;
        } else {
            if (a.email < b.email) { return -1; }
            if (a.email > b.email) { return 1; }
            return 0;            
        }


      });

    return resp;
}
