<script>
  import { navigate, link } from "svelte-routing";
  import { onMount } from "svelte";    
  import {guiText} from "../../tstore/guiText";
  import {myAuthObject} from "../../tstore/myauth";
  import {checkForRoleAdmin, checkForRoleAudience, checkForRoleProject} from '../../lib/checkAuthObject.js'; 
  import {tenantsGet} from "../../api/TenantsGet";
  import {surveysGet} from "../../api/SurveysGet";  

  export let my_lang;
  export let color = "light";

  let defTxtCol = 'bg-red-800 text-white';
  if (color === 'light'){ defTxtCol= 'bg-white'; }

  // ml-auto - items-stretch 
  const flex_starter = "relative flex content-center items-center justify-center w-full heightdefs ";
  const flex_ender = "mr-auto text-center break-words shadow-lg rounded " + defTxtCol;
  let defClassItem = flex_starter + "lg:w-12/12 mb-2 " + flex_ender;
  let lastClassItem = flex_starter + "lg:w-12/12 mb-6 " + flex_ender;  
  let headerClassItem = flex_starter + "lg:w-full mb-2 " + flex_ender;  

  let defButton = "text-emerald-500 background-transparent font-bold capitalize px-4 py-4 ";
  defButton += "hover:text-emerald-600 text-xs outline-none focus:outline-none ";
  defButton += "mr-1 mb-1 mt-1 ease-linear transition-all duration-150 ";

  let tenantsLabel = '';
  let surveysLabel = ''; 
  let tenantsAry = []; 
  let surveys_by_tenant = {};
  let txt_project_headline = '';

  const roleAdminStatus = checkForRoleAdmin($myAuthObject);
  const roleAudienceStatus = checkForRoleAudience($myAuthObject);
  const roleProjectStatus = checkForRoleProject($myAuthObject);

  // url aufbau für zim-gui
  // /userUUUid/botId/(prosperland|configuration)...

  const gotoProsperConfiguration = () => { 
    let myUrl = `${$myAuthObject.uri_prosper_mn}/${$myAuthObject.whoami.uuid}/${$myAuthObject.projectId}/configuration?lang=${my_lang}`;
    window.open(myUrl, '_blank').focus(); 
  };

  const gotoProsperLandingPage = () => { 
    // let myUrl = `${$myAuthObject.uri_prosper_mn}/${$myAuthObject.whoami.uuid}/landingpage?lang=${my_lang}`;
    // let myUrl = `${$myAuthObject.uri_prosper_mn}/${$myAuthObject.whoami.uuid}/${$myAuthObject.projectId}/prosperland?lang=${my_lang}`;
    let myUrl = `${$myAuthObject.uri_prosper_mn}/${$myAuthObject.whoami.uuid}/${$myAuthObject.projectId}/prosperland?lang=${my_lang}&status=true`;    
    window.open(myUrl, '_blank').focus();     
  };

  const gotoProsperCalendar = () => { 
    let myUrl = `${$myAuthObject.uri_prosper_mn}/${$myAuthObject.whoami.uuid}/${$myAuthObject.projectId}/calendar?lang=${my_lang}&status=true`;    
    window.open(myUrl, '_blank').focus(); 
  };

  onMount(async () => { 
    if ( roleAdminStatus === false && roleAudienceStatus === false && roleProjectStatus === false ) {
      navigate('/auth/login', { replace: true }); 
    };

    let tenantsRawResp = await tenantsGet({'authO': $myAuthObject, 'guiTxt': $guiText });
    tenantsAry = await tenantsRawResp.data; 
    // tenantsO = await tenantsRawResp.o;

    let surveysRawResp = await surveysGet({'authO': $myAuthObject, 'guiTxt': $guiText });
    // surveysAry = await surveysRawResp.data; 

    surveys_by_tenant = await surveysRawResp.by_tenant;
    // surveys_by_tenant_survey = await surveysRawResp.by_tenant_survey;
    // surveys_by_tenant_bot = await surveysRawResp.by_tenant_bot;

    tenantsLabel = tenantsAry[0].label;
    surveysLabel = surveys_by_tenant[tenantsAry[0].uuid][0]['label'];


    if ( $myAuthObject.projectId === '55109280-13fc-11ee-9534-376b5dabbac4' ) {
      tenantsLabel = 'Grabow';
      surveysLabel = 'Grabow';
    }


    txt_project_headline = `${$guiText.register.general.project[my_lang]} : ${surveysLabel}`;
  });

</script>

<style>
  main {
    display: grid;
    grid-template-columns: 25% 25% 25% 25%;
    grid-template-rows: 5vw 5vw;
    grid-gap: 0.5em;
  }

  .prosper_image { 
    object-fit: cover;
    height: 15vw;
  }

  .prosper_image_full { 
    object-fit: none;
  }  

  .prosper_image_fill { 
    object-fit: fill;
  }  

  #prosper_header { grid-column: 1 / span 4; grid-row: 1; }
  #prosper_config { grid-column: 1 / span 4; grid-row: 2; }  

  @media (max-width: 640px) {
    main {
      display: grid;
      grid-template-columns: 100%;
      grid-template-rows: 10vw 10vw 10vw;
      grid-gap: 1em;
    }

    #prosper_header { grid-column: 1; grid-row: 1; }
    #prosper_config { grid-column: 1; grid-row: 2; }  
  }

</style>

{#if $myAuthObject.whoami.valid === true }

<main>
  <div id="prosper_header">
    <div class="heightdefs">
      <div class="{headerClassItem}">    
        <h3 class="font-semibold text-center text-lg {color === 'light' ? 'text-blueGray-700' : 'text-white'}" >
          {$guiText.general.administration[my_lang]}
        </h3> 
      </div>
    </div>
  </div>

  <div id="prosper_config">
    <div class="heightdefs">
      <div class="{headerClassItem}">    
        {#if roleAdminStatus === true || roleProjectStatus === true}       
        <button class="{defButton}" type="button" on:click={gotoProsperConfiguration}> 
            {$guiText.general.configuration[my_lang]}
        </button>          
        {/if}
      </div>
    </div>
  </div>

</main>

{/if}




