import { writable} from "svelte/store";

let cx = [ 
    { 'pos' : 1, 
      'lbl': { 'en' : 'General fit', 'de' : 'General fit' }, 
      'lnk' : 'momentum/1784217/FcG8ZJ', 
      'exp' : { 'en' : "Perception of the supplier's overall performance", 'de' : "Perception of the supplier's overall performance" } },
    { 'pos' : 2, 
      'lbl': { 'en' : 'Presales & Sales', 'de' : 'Presales & Sales'}, 
      'lnk' : 'momentum/1784230/lvHAGg', 
      'exp' : { 'en' : 'Including marketing, (pre)sales, offering, and negotiations', 'de' : 'Including marketing, (pre)sales, offering, and negotiations' } },
    { 'pos' : 3, 
      'lbl': { 'en' : 'Network Implementation', 'de' : 'Network Implementation' },  
      'lnk' : 'momentum/1784369/gIl7n7', 
      'exp' : { 'en' : 'Incorporating project management, site works, installation, and commissioning', 'de' : 'Incorporating project management, site works, installation, and commissioning' } },
    { 'pos' : 4, 
      'lbl': { 'en' : 'HW Delivery and Logistics', 'de' : 'HW Delivery and Logistics' }, 
      'lnk' : 'momentum/1784387/3cwCXU', 
      'exp' : { 'en' : 'Including hardware delivery content and process', 'de' : 'Including hardware delivery content and process' } },
    { 'pos' : 5, 
      'lbl': { 'en' : 'Systems Integration', 'de' : 'Systems Integration' }, 
      'lnk' : 'momentum/1784394/34S2Wo', 
      'exp' : { 'en' : 'Incorporating multivendor integration and testing services', 'de' : 'Incorporating multivendor integration and testing services' } },
    { 'pos' : 6, 
      'lbl': { 'en' : 'Managed Services', 'de' : 'Managed Services' }, 
      'lnk' : 'momentum/1784401/9ylV7T', 
      'exp' : { 'en' : 'Involving network and service operations, and XaaS Services', 'de' : 'Involving network and service operations, and XaaS Services' } },
    { 'pos' : 7, 
      'lbl': { 'en' : 'Network Planning, Design & Optimization', 'de' : 'Network Planning, Design & Optimization' }, 
      'lnk' : 'momentum/1784418/IXFp9M', 
      'exp' : { 'en' : "Services to configure and enhance the network according to the customer's needs", 'de' : "Services to configure and enhance the network according to the customer's needs" } },
    { 'pos' : 8, 
      'lbl': { 'en' : 'Support and Maintenance', 'de' : 'Support and Maintenance'}, 
      'lnk' : 'momentum/1784425/rIV2z7', 
      'exp' : { 'en' : 'Field & remote support, SW and HW support, SW upgrades, predictive services', 'de' : 'Field & remote support, SW and HW support, SW upgrades, predictive services' } },
    { 'pos' : 9, 
      'lbl': { 'en' : 'Training services', 'de' : 'Training services' }, 
      'lnk' : 'momentum/1784429/2PJKyr', 
      'exp' : { 'en' : 'Involving onsite and online training, learning apps', 'de' : 'Involving onsite and online training, learning apps' } }, 
    { 'pos' : 10, 
      'lbl': { 'en' : 'Business Relationship', 'de' : 'Business Relationship' }, 
      'lnk' : 'momentum/1784440/5FIHI2', 
      'exp' : { 'en' : 'Incorporating communication, customer orientation, competence, and empowerment', 'de' : 'Incorporating communication, customer orientation, competence, and empowerment' } },
    { 'pos' : 11, 
      'lbl': { 'en' : 'Technology and Solutions', 'de' : 'Technology and Solutions' }, 
      'lnk' : 'momentum/1784443/27f6ph', 
      'exp' : { 'en' : 'Regarding product strength, time to market, roadmaps, and cost', 'de' : 'Regarding product strength, time to market, roadmaps, and cost' } },
    { 'pos' : 12, 
      'lbl': { 'en' : 'Complete survey', 'de' : 'Complete survey' }, 
      'lnk' : 'survey/1784099/vhy1Qm', 
      'exp' : { 'en' : 'Including all phases of the customer-supplier interaction', 'de' : 'Including all phases of the customer-supplier interaction' } }
  ];

  let myDefault = { 'cx' : cx }

export const momentumCxData = writable(myDefault)