export async function apiMaster (data, req_uri, req_options) {
    let lng = data.authO.defLang;

    let resp = await fetch(req_uri, req_options )
    .then(response => { 
        if (response.ok && response.status === 200) {
            return {
                'message' : '',
                'status' : true,
                'data' : response.json() 
            }
        }                
        else if ( [ 400, 401, 402, 403 ].includes(response.status) ) {
            return {
                message : `${data.guiTxt.general.error_occured[lng]} ${data.guiTxt.general.contact_your_manager[lng]}`, 
                status: false, 
                data: response.json()
            };                
      } else {
            return {
                message : `${data.guiTxt.general.error_unknown_occured[lng]} ${data.guiTxt.general.contact_your_manager[lng]}`,
                status: false, 
                data: response.json()
            };                
      }
      })
    .catch(error => {         
        return {
            message : `${data.guiTxt.general.error_unknown_occured[lng]} ${data.guiTxt.general.contact_your_manager[lng]}`,
            status: false,
            data: false
        }
    });

    return resp;

}