import {apiMaster} from "./ApiMaster";  

export async function calendarEventPost (data, defined_event) {
    data.authO.loading = true;

    /**
     * event-end (picker_end2) darf auch null sein, hängt vom event_type ab.
     *  event_type === 'now' darf null sein 
     *  event_type === 'publication' darf null sein
     * in solch einem fall event-end (picker_end2) auf 01.01.2100 setzen   
     */
    if ( defined_event.l2themeValue === "now" || defined_event.l2themeValue === "publication" ) {
        if ( defined_event.picker_end.length === 0 ) { 
            defined_event.picker_end = new Date(2100, 1, 1);
            defined_event.picker_end2 = new Date(2100, 1, 1, 0, 0, 0);
        }
    }

    /**
     * hier fehlt Filter als attribut!
     * das muss mit thomas getestet werden!
     *  
     * 'start_email' : defined_event.start_email, 
     * 'end_email' : defined_event.end_email, 
     * 'filter' : defined_event.filter     
     */

    let myBody = { 
        'id' : 0,
        'surveyId' : defined_event.surveyId,
        'localeCode' : defined_event.language,
        'title': defined_event.calendar_title, 
        'description': defined_event.calendar_description,    
        'allDay' : true,  
        'start' : `${defined_event.picker_start2}:00.000Z`,
        'end' : `${defined_event.picker_end2}:00.000Z`,
        'color' : '#F75201',
        'l2' : defined_event.l2themeValue,
        'function': defined_event.event_type          
    };

    let requestOptions = {
        method: 'POST',
        headers: { 
            'Content-Type': 'application/json', 
            'X-Custom-Header': 'application/json' 
        },
        body: JSON.stringify(myBody)
      };

    let resp = await apiMaster(
        data,
        `${data.authO.uri_prosper_mn}/${data.authO.userUuid}/${data.authO.projectId}/app/setevent`, 
        requestOptions
    );

    return resp;

}