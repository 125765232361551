<script>
    import { navigate } from "svelte-routing";
    import {myAuthObject} from "../../tstore/myauth";  
    import {guiText} from "../../tstore/guiText";
    import {selectTextOnFocus, blurOnEscape} from '../../lib/inputDirectives.js';    
    import LightGreyButon from "../../components/Buttons/LightGreyButon.svelte";
    import {participantCreatePost} from "../../api/ParticipantCreatePost";  

    export let my_lang;

    let inputErrorMessage = '';    
    let myUsers = '';
    
    $myAuthObject.loading = false;

    const checkButtonClick = () => {
      $myAuthObject.loading = true;
      if ( $myAuthObject.inputReady === true ) {
        apiRunner();
      }
    }

    const jumpToIsland = () => {
      // let islandUrl = `${$myAuthObject.uri_prosper_mn}/${$myAuthObject.userUuid}/configuration?lang=${my_lang}`;
      // window.open(islandUrl, '_blank').focus();   
      navigate('/admin/dashboard', { replace: true });  
    }

    const apiRunner = async () => {
      let rawresp = await participantCreatePost({'authO': $myAuthObject, 'guiTxt': $guiText }, myUsers);
      let resp = await rawresp.data;

      if ( rawresp.status === true) {
        jumpToIsland(); 
      }
      else {
        inputErrorMessage = rawresp.message; 
        $myAuthObject.loading = false;
        myUsers = '';
      }    
    }

    </script>

    <div class="container mx-auto px-4 h-full">
      <div class="flex content-center items-center justify-center h-full">
        <div class="w-full lg:w-6/12 px-4">
          <div class="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg bg-blueGray-200 border-0" >
            <div class="flex-auto px-4 lg:px-10 py-10 pt-0">

              <form>
                <div class="relative w-full py-5 mb-3">
                  <h3 class="text-2xl mb-2 font-semibold leading-none">
                    {$guiText.register.general.who_is_working_with_you[my_lang]}
                  </h3>
                
                  <label
                    class="block text-blueGray-400 text-xs mb-2"
                    for="grid-user"
                  >
                    {$guiText.register.general.add_colleagues_via_email[my_lang]}
                  </label>

                  <textarea
                    id="grid-users"
                    rows="4"
                    type="text"
                    class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-900 bg-white 
                      rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                    placeholder="email_1@company.com, email_2@company.com"
                    bind:value={myUsers}
                    use:selectTextOnFocus 
                    use:blurOnEscape                    
                  />                  

                </div>
    
                <div class="text-center mt-6">

                  {#if myUsers.length > 5 }
                    {#if $myAuthObject.loading === true}    

                      <LightGreyButon bg_color = "bg-blueGray-200">
                        <div slot="button_text">... {$guiText.general.checking[my_lang]}</div>
                      </LightGreyButon>

                      {:else}                  
                      <button
                        class="bg-blueGray-800 text-white active:bg-blueGray-600 text-sm font-bold px-6 py-3 
                            rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 w-full ease-linear 
                            transition-all duration-150"
                        type="button"
                        on:click={checkButtonClick}                       
                      > 
                        {$guiText.general.invite_persons[my_lang]}
                      </button>
                    {/if}

                  {:else}

                    <LightGreyButon bg_color = "bg-blueGray-200">
                      <div slot="button_text">{$guiText.general.invite_persons[my_lang]}</div>
                    </LightGreyButon>

                  {/if}

                </div>

                <label
                  class="block text-blueGray-400 text-xs mb-2 text-center"
                  for="grid-user"
                >
                  {$guiText.general.or[my_lang]} 
                </label>

                <label
                  class="block text-blueGray-400 text-xs mb-2 text-center"
                  for="grid-user"
                >

                  <button
                    class="bg-blueGray-200 text-blueGray-400 active:bg-blueGray-200 text-sm px-6 py-3 
                        outline-none focus:outline-none mr-1 mb-1 w-full ease-linear transition-all duration-150"
                    type="button"
                    on:click={jumpToIsland}                      
                  > 
                  {$guiText.general.skip_this_step[my_lang]}
                  </button>

                </label>

                {#if inputErrorMessage.length > 0 }
                  <div>
                    <span class="block ml-2 text-xs text-red-400">
                      {inputErrorMessage}
                    </span>                  
                  </div>
                {/if}

              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
    