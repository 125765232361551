<script>
  export let bText;   
  export let bLink;
  export let showModal;
  // export let gotoProsperMomentum;
  export let toggleModalWindow;
  export let background_color = "A6CD8A";

  let color = "ffffff";

  const gotoProsperMomentum = () => { toggleModalWindow(bLink); }

</script>

<!-- bg-emerald-500 text-white active:bg-emerald-400  -->

<button
  class=" font-bold uppercase text-xs rounded shadow hover:shadow-md outline-none focus:outline-none 
          ease-linear transition-all duration-150 relative px-4 py-1 mr-1"
  type="button"
  style="color: #{color}; background-color: #{background_color};"
  on:click={gotoProsperMomentum}
> 
  {bText} 
</button>


