<script>
  // core components
  import { navigate } from "svelte-routing";
  import { onMount } from "svelte";  

  import {selectTextOnFocus, blurOnEscape} from '../../lib/inputDirectives.js';  

  import {userPut} from "../../api/UserPut.js";  
  import {whoAmIGet} from "../../api/WhoAmIGet.js";  

  import {checkWhoAmI} from "../../lib/checkAuthObject.js";  
  import {guiText} from "../../tstore/guiText.js"; 
  import {myAuthObject} from "../../tstore/myauth.js";

  import DashHeader from "components/Dashboards/DashHeader.svelte";

  if ( checkWhoAmI($myAuthObject) === false || $myAuthObject.whoami.valid === false ) {
    navigate('/auth/login', { replace: true }); 
  }

  export let my_lang;
  export let color = "light";

  let isFirstnameOk = false;
  let isLastnameOk = false;
  let inputErrorMessage = '';

  $myAuthObject.loading = false;
  $myAuthObject.inputReady = false; 

  $: $myAuthObject.firstname && checkInputFirstname();
  $: $myAuthObject.lastname && checkInputLastname();  

  let editProfile = false;
  function toggleEditProfile(e=true){ 
    editProfile = !editProfile; 
    $myAuthObject.loading = false;
  };

  const userPutRun = async () => {
    let edit_usr = {
      'firstname' : $myAuthObject.firstname ? $myAuthObject.firstname : '',
      'lastname' : $myAuthObject.lastname ? $myAuthObject.lastname : '',
      'uuid' : $myAuthObject.userUuid ? $myAuthObject.userUuid : ''
    }

    let rawresp = await userPut({'authO': $myAuthObject, 'guiTxt': $guiText }, edit_usr);
    let resp = await rawresp.data;
    
    if ( rawresp.status === true ) {
      return true;
    }
    else {
        $myAuthObject.loading = false;
        inputErrorMessage = rawresp.message; 
        return false;
      }
  } 

  const saveProfile = () => { 
    if ( $myAuthObject.inputReady === true ) {    
      apiRunner(); 
    } 
    toggleEditProfile();
  };

  const authObjectUpdate = (resp) => {
    $myAuthObject.firstname = resp.firstName;
    $myAuthObject.lastname = resp.lastName;
    $myAuthObject.email = resp.email;
    $myAuthObject.userUuid = resp.uuid;
  };

  const whoAmIGetStatus = async () => {
    $myAuthObject.loading = true;
    let rawresp = await whoAmIGet({'authO': $myAuthObject, 'guiTxt': $guiText });
    let resp = await rawresp.data;

    if ( rawresp.status === true && resp.uuid.length > 30 && resp.email === $myAuthObject.email && resp.email.length >= 7 ) { 
      authObjectUpdate(resp);

      if ( resp.tenantRoles.length > 0 ) {
        if ( resp.tenantRoles[0]['tenantUuid'] ) {
          $myAuthObject.tenantUuid = resp.tenantRoles[0]['tenantUuid'];
        } 
        if ( resp.tenantRoles[0]['roles'] ) {
          $myAuthObject.tenantRole = resp.tenantRoles[0]['roles'];
        }    
      }

      $myAuthObject.project = '';
      $myAuthObject.whoami = resp;
      return true;
    } 
    else {
      logInFailedMessage = $guiText.general.login_failed[my_lang];
      return false;
    }
  }

  const apiRunner = async () => {
    let userPost = await userPutRun();
    if ( userPost === true ) {
      await whoAmIGetStatus();
    }
	};

  const checkInputTot = () => { 
    $myAuthObject.loading === false;
    if ( isLastnameOk === true && isFirstnameOk === true) {
      $myAuthObject.inputReady = true; 
    } else {
      $myAuthObject.inputReady = false; 
    }
  
  };

  const checkInputLastname = () => { 
    inputErrorMessage = ''; 

    if ( $myAuthObject.lastname.length === 0 ) { 
      isLastnameOk = false; 
      $myAuthObject.inputReady = false;
    }     
    else if ( $myAuthObject.lastname.length < 2 ) { 
      inputErrorMessage = `${$guiText.general.lastname[my_lang]} ${$guiText.register.general.must_have_min_2_chrs[my_lang]}`;
      isLastnameOk = false;      
      $myAuthObject.inputReady = false;
    } else {
      isLastnameOk = true;
      checkInputTot();
    }

  };  

  const checkInputFirstname = () => { 
    inputErrorMessage = '';

    if ( $myAuthObject.firstname.length === 0 ) { 
      isFirstnameOk = false; 
      $myAuthObject.inputReady = false;
    }     
    else if ( $myAuthObject.firstname.length < 2 ) { 
      inputErrorMessage = `${$guiText.general.firstname[my_lang]} ${$guiText.register.general.must_have_min_2_chrs[my_lang]}`;
      isFirstnameOk = false;      
      $myAuthObject.inputReady = false;
    } else {
      isFirstnameOk = true;      
      checkInputTot();
    }
  }

  onMount(async () => { 
    $myAuthObject.lastname = $myAuthObject.whoami.lastName;
    $myAuthObject.firstname = $myAuthObject.whoami.firstName;
    $myAuthObject.email = $myAuthObject.whoami.email;

  });

</script>

<main>

<div id="prosper_momentum_header">
  <DashHeader 
    color="ffffff" 
    background_color="076623" 
    headline={$guiText.general.your_profile[my_lang]} show_logo="show" />
</div>

<div id="prosper_momentum_subheader">
  <DashHeader 
    color="ffffff" 
    background_color="A6CD8A" 
    headline=" " />
</div>

<div > <!-- div>class="container mx-auto px-4 h-full "  -->
  <div class="relative flex flex-col min-w-0 break-words w-full shadow-lg 
            rounded {color === 'light' ? 'bg-white' : 'bg-red-800 text-white'}">


    <div class="rounded-t mb-0 px-4 z-30 border-0"> <!-- class="rounded-t mb-0 px-4 border-0" -->
      <div class="flex flex-wrap items-center">
        <div class="relative w-full px-4 max-w-full flex-grow flex-1">
          <div class="text-center flex justify-between">

            <h3 class="font-semibold lg:w-8/12 text-lg {color === 'light' ? 'text-blueGray-700' : 'text-white'}" >
              &nbsp; 
            </h3>

            {#if editProfile === true }

              {#if $myAuthObject.inputReady === true}
                {#if $myAuthObject.loading === false}
                  <button
                    class="bg-red-500 text-white active:bg-emerald-400 font-bold uppercase 
                          text-xs px-4 py-1 rounded shadow hover:shadow-md outline-none 
                          focus:outline-none mr-1 ease-linear transition-all duration-150"
                    type="button"
                    on:click={saveProfile}
                  > {$guiText.general.save[my_lang]} </button>

                {:else}

                  <button
                    class="bg-red-200 text-white active:bg-emerald-400 font-bold uppercase text-xs px-4 rounded shadow hover:shadow-md outline-none 
                      focus:outline-none mr-1 ease-linear transition-all duration-150"
                    type="button"
                  > ... {$guiText.general.sending[my_lang]} </button>
                  
                {/if}
              {:else}

                <button
                  class="bg-red-200 text-white active:bg-emerald-400 font-bold uppercase text-xs px-4 rounded shadow hover:shadow-md outline-none 
                    focus:outline-none mr-1 ease-linear transition-all duration-150"
                  type="button"
                > {$guiText.general.edit[my_lang]} </button>
              {/if}

            {:else}

              <button
                class="bg-emerald-500 text-white active:bg-emerald-400 font-bold uppercase text-xs px-4 rounded shadow hover:shadow-md outline-none 
                  focus:outline-none mr-1 ease-linear transition-all duration-150"
                type="button"
                on:click={toggleEditProfile}
              > {$guiText.general.edit[my_lang]} </button>
            {/if}

          </div>
        </div>
      </div>
    </div>

    <div class="block w-full overflow-x-auto">

      <form>
        <table class="table-fixed items-center w-full bg-transparent border-collapse">
          <thead>
            <tr>
              {#each [' ', ' '] as headItm }
              <th class="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left {color === 'light' ? 'bg-blueGray-50 text-blueGray-500 border-blueGray-100' : 'bg-red-700 text-red-200 border-red-600'}" >
                {headItm}
              </th>
            {/each}
            </tr>
          </thead>

          <tbody>

            <tr>
              <th class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 text-left flex items-center" >
                <label
                  class="block text-blueGray-600 text-xs font-bold mb-2"
                  for="grid-first-name"
                > {$guiText.general.firstname[my_lang]} </label>          
              </th>
              <td class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4" >
                {#if editProfile === true }
                  <input
                    id="grid-first-name"
                    type="text"
                    class="border-0 px-3 placeholder-blueGray-300 text-blueGray-900 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                    bind:value={$myAuthObject.firstname}
                    on:input={checkInputFirstname}
                    use:selectTextOnFocus 
                    use:blurOnEscape 
                  />            
                {:else}
                  <div> {$myAuthObject.whoami.firstName} </div>
                {/if}

              </td>          
            </tr>

            <tr>
              <th class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 text-left flex items-center" >
                <label
                  class="block text-blueGray-600 text-xs font-bold mb-2"
                  for="grid-last-name"
                > {$guiText.general.lastname[my_lang]} </label>         
              </th>
              <td class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4" >
                {#if editProfile === true }            
                  <input
                    id="grid-last-name"
                    type="text"
                    class="border-0 px-3 placeholder-blueGray-300 text-blueGray-900 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                    bind:value={$myAuthObject.lastname}
                    on:input={checkInputLastname}
                    use:selectTextOnFocus    
                    use:blurOnEscape                           
                  />          
                  {:else}
                    <div> {$myAuthObject.whoami.lastName} </div>
                {/if}              
              </td>          
            </tr>

            <tr>
              <th class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 text-left flex items-center" >
                <label
                  class="block text-blueGray-600 text-xs font-bold mb-2"
                  for="grid-email"
                > {$guiText.general.email[my_lang]} </label>        
              </th>
              <td class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4" >
                <div> {$myAuthObject.whoami.email} </div>
              </td>          
            </tr>

          </tbody>
        </table>

        {#if inputErrorMessage.length > 0 }
          <div>
            <span class="block ml-2 text-xs text-red-400">
              {inputErrorMessage}
            </span>                  
          </div>
        {/if}

      </form>

    </div>

  </div>
</div>

</main>