<script>
  import { navigate } from "svelte-routing";
  import {myAuthObject} from "../../tstore/myauth";  
  import {selectTextOnFocus, blurOnEscape} from '../../lib/inputDirectives.js';      
  import {guiText} from "../../tstore/guiText";
  import LightGreyButon from "../../components/Buttons/LightGreyButon.svelte";  
  import {tokenPost} from "../../api/TokenPost";  
  import {whoAmIGet} from "../../api/WhoAmIGet";
  import {passwordPut} from "../../api/PasswordPut";    
  import {userPut} from "../../api/UserPut";    

  export let passtoken;
  export let my_lang;

  let myPassword = '';
  let inputErrorMessage = ''; 
  let isFirstnameOk = false;
  let isLastnameOk = false;

  $myAuthObject.loading = false;
  $myAuthObject.inputReady = false;

  $: myPassword && checkInputPassword();

  const checkButtonClick = () => {
    if ( $myAuthObject.inputReady === true ) {
      $myAuthObject.password = true;
      apiRunner(); 
    }
  }

  const apiRunner = async () => {  
    let rawresp = await tokenPost({'authO': $myAuthObject, 'guiTxt': $guiText }, passtoken);
    let resp = await rawresp.data;

    $myAuthObject.bearer = '';

    if ( rawresp.status === true && resp.type === 'Bearer ' && resp.token.length > 30 ) {
        $myAuthObject.bearer = resp.token;
    }
    else {
      inputErrorMessage = rawresp.message; 
      $myAuthObject.inputReady = false;
      $myAuthObject.loading = false;
    }

    let whoami = await whoAmIGetStatus();
    if ( whoami === true) {
      let passPost = await passwordPutRun();
      if ( passPost === true) {
        let userPost = await userPutRun();
        if ( userPost === true ) {
          let whoami2 = await whoAmIGetStatus();
            if ( whoami2 === true) {          
              // let islandUrl = `${$myAuthObject.uri_prosper_mn}/${$myAuthObject.userUuid}/landingpage?lang=${my_lang}`;
              // window.open(islandUrl, '_blank').focus();
              navigate('/admin/dashboard', { replace: true });            
          }
        }
      }
    }

  }

  const passwordPutRun = async () => {
    let rawresp = await passwordPut({'authO': $myAuthObject, 'guiTxt': $guiText }, myPassword);
    let resp = await rawresp.data;  

    if ( rawresp.status === true ) {
      $myAuthObject.passwordSet = true;
      $myAuthObject.userUuid = resp.uuid;
    }
    else {
      inputErrorMessage = rawresp.message; 
    }
    return rawresp.status
  }

  const whoAmIGetStatus = async () => {    
    // das muss eine option werden, oder??
    $myAuthObject.agb = true;
    $myAuthObject.loading = true;

    let rawresp = await whoAmIGet({'authO': $myAuthObject, 'guiTxt': $guiText });
    let resp = await rawresp.data;

    if ( rawresp.status === true && resp.uuid.length > 30 && resp.email.length >= 7 ) { 
      // $myAuthObject.firstname = resp.firstName;
      // $myAuthObject.lastname = resp.lastName;
      $myAuthObject.email = resp.email;      
      $myAuthObject.userUuid = resp.uuid;

      if ( resp.tenantRoles.length > 0 ) {
        if ( resp.tenantRoles[0]['tenantUuid'] ) {
          $myAuthObject.tenantUuid = resp.tenantRoles[0]['tenantUuid'];
        } 
        if ( resp.tenantRoles[0]['roles'] ) {
          $myAuthObject.tenantRole = resp.tenantRoles[0]['roles'];
        }    
      }

      $myAuthObject.project = '';
      return true;
    } 
    else {
      $myAuthObject.loading = false;
      inputErrorMessage = rawresp.message; 
      return false;
    }
  }

  const userPutRun = async () => {
    let edit_usr = {
      'firstname' : $myAuthObject.firstname ? $myAuthObject.firstname : '',
      'lastname' : $myAuthObject.lastname ? $myAuthObject.lastname : '',
      'uuid' : $myAuthObject.userUuid ? $myAuthObject.userUuid : ''
    }

    let rawresp = await userPut({'authO': $myAuthObject, 'guiTxt': $guiText }, edit_usr);
    let resp = await rawresp.data;
    
    if ( rawresp.status === true ) {
      return true;
    }
    else {
        $myAuthObject.loading = false;
        inputErrorMessage = rawresp.message; 
        return false;
      }
  }  

  const checkInputPassword = () => {
    inputErrorMessage = "";
    $myAuthObject.inputReady = false;
    $myAuthObject.password = true; 

    if ( myPassword.length === 0 ) { 
      $myAuthObject.password = false; 
    }
    else if ( myPassword.length < 7 ) { 
      inputErrorMessage = $guiText.register.general.password_min_seven[my_lang];
      $myAuthObject.password = false; 
    }
    else if ( isLastnameOk === true && isFirstnameOk === true) {
      $myAuthObject.inputReady = true; 
    }    
    
  }

  const checkInputFirstname = () => { 
    inputErrorMessage = '';
    $myAuthObject.inputReady = false; 
    isFirstnameOk = true;
    
    if ( $myAuthObject.firstname.length === 0 ) { 
      isFirstnameOk = false; 
    }     
    else if ( $myAuthObject.firstname.length < 2 ) { 
      inputErrorMessage = `${$guiText.general.firstname[my_lang]} ${$guiText.register.general.must_have_min_2_chrs[my_lang]}`;
      isFirstnameOk = false;      
    }    
    else if ( isLastnameOk === true && $myAuthObject.password === true) {
      $myAuthObject.inputReady = true; 
    }
  }

  const checkInputLastname = () => { 
    inputErrorMessage = '';
    $myAuthObject.inputReady = false; 
    isLastnameOk = true;
    
    if ( $myAuthObject.lastname.length === 0 ) { 
      isLastnameOk = false; 
    }     
    else if ( $myAuthObject.lastname.length < 2 ) { 
      inputErrorMessage = `${$guiText.general.lastname[my_lang]} ${$guiText.register.general.must_have_min_2_chrs[my_lang]}`;
      isLastnameOk = false;      
    } 
    else if ( isFirstnameOk === true  && $myAuthObject.password === true) {
      $myAuthObject.inputReady = true; 
    }

  } 

</script>

  <div class="container mx-auto px-4 h-full">
    <div class="flex content-center items-center justify-center h-full">
      <div class="w-full lg:w-6/12 px-4">
        <div class="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg bg-blueGray-200 border-0" >
          <div class="flex-auto px-4 lg:px-10 py-10 pt-0">
            <form>

              <div class="relative w-full mb-3 py-5">

                <h3 class="text-2xl mb-2 font-semibold leading-none">
                  {$guiText.register.general.set_password_for_account[my_lang]}
                </h3>
              
                <label
                  class="block text-blueGray-400 text-xs mb-2"
                  for="grid-password"
                >                  
                {$guiText.register.general.choose_secure_password[my_lang]}
                </label>
              </div>
             
              <div class="relative w-full mb-3">
                <input
                  id="grid-firstname"                   
                  type="text"                  
                  class="border-0 px-3 placeholder-blueGray-300 text-blueGray-900 bg-white rounded text-sm 
                    shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                  placeholder="{$guiText.general.firstname[my_lang]}" 
                  bind:value={$myAuthObject.firstname}
                  on:input={checkInputFirstname}
                  use:selectTextOnFocus 
                  use:blurOnEscape  
                />
              </div>             

              <div class="relative w-full mb-3">
                <input
                  id="grid-lastname"                  
                  type="text"                  
                  class="border-0 px-3 placeholder-blueGray-300 text-blueGray-900 bg-white rounded text-sm 
                    shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                  placeholder="{$guiText.general.lastname[my_lang]}"
                  bind:value={$myAuthObject.lastname}
                  on:input={checkInputLastname}
                  use:selectTextOnFocus 
                  use:blurOnEscape  
                />
              </div> 

              <div class="relative w-full mb-3">              
                <input
                  id="grid-password"
                  type="password"
                  class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-900 bg-white rounded text-sm 
                    shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                  placeholder="{$guiText.general.password[my_lang]}"
                  bind:value={myPassword}
                  on:input={checkInputPassword}
                  use:selectTextOnFocus 
                  use:blurOnEscape                    
                />
              </div>

              <div class="text-center mt-6">

                {#if $myAuthObject.inputReady === true}
                  {#if $myAuthObject.loading === true}    

                    <LightGreyButon bg_color = "bg-blueGray-200">
                      <div slot="button_text">... {$guiText.general.checking[my_lang]}</div>
                    </LightGreyButon>

                  {:else}
                    <button
                      class="bg-blueGray-800 text-white active:bg-blueGray-600 text-sm font-bold px-6 py-3 
                          rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 w-full ease-linear 
                          transition-all duration-150"
                      type="button"
                      on:click={checkButtonClick}                       
                    > 
                      {$guiText.general.letsgo[my_lang]}
                    </button>
                  {/if}

                {:else}

                  <LightGreyButon bg_color = "bg-blueGray-200">
                    <div slot="button_text">{$guiText.general.login[my_lang]}</div>
                  </LightGreyButon>

                {/if}

              </div>

              {#if inputErrorMessage.length > 0 }
                <div>
                  <span class="block ml-2 text-xs text-red-400">
                    {inputErrorMessage}
                  </span>                  
                </div>
              {/if}

            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
  
