<script>
    import Select from 'svelte-select';
    import { navigate } from "svelte-routing";    
    import { onMount } from "svelte";    

    import {myAuthObject} from "../../tstore/myauth";
    import {shopProductsGet} from "../../api/ShopProductsGet";  
    import {shopProductsPrice} from "../../api/ShopProductsPrice";  

    import {guiText} from "../../tstore/guiText"; 
    import LightGreyButon from "../../components/Buttons/LightGreyButon.svelte";

    export let my_lang;
    // export let color = "light";

    let inputErrorMessage = '';
    let myPrice = {'price': 0, 'price_admins': 0, 'price_base': 0, 'price_languages': 0, 'price_onboarding': 0, 'price_product': 0 };
    let myProductSelection = { 'selected' : {"employees" : 50, "admins" : 1, "languages" : 1, "onboarding" : 1 }};

    $: myProductSelection && setMyPrice();

    const setMyPrice = ( new_price = 0) => { 
      myPrice = new_price;
      if ( myPrice.price > 0 ) {
        $myAuthObject.projectState = true;
        $myAuthObject.inputReady = true;
        $myAuthObject.loading = false;
      } 
    }

    let myProductO = {};
    let to_buy = {
      'versions' : '', 'version_default' : { 'label' : '' }, 'version_select' : {},
      'employees' : '', 'employees_default' : { 'label' : '' }, 'employees_select' : {},
      'selected' : {"employees" : 50, "admins" : 2, "languages" : 2, "onboarding" : 1 }
    };
   
    onMount( async () => { 
      let myProductRaw = await shopProductsGet({'authO': $myAuthObject, 'guiTxt': $guiText });
      myProductO = await myProductRaw.data;

      to_buy.versions = myProductO.versions;
      to_buy.version_default = myProductO.versions[0];

      to_buy.employees = myProductO.employees;
      to_buy.employees_default = myProductO.employees[0];      

      myProductSelection['versions'] = myProductO.versions[0];      
      myProductSelection['onboarding'] = myProductO.onboarding[0];
      myProductSelection['employees'] = myProductO.employees[0];


      ['admins', 'languages'].forEach( e => {
        myProductSelection['selected'][e] = myProductO['selected'][e];
      });

      myProductSelection['selected']['employees'] = to_buy.employees_default['value'];      

      let new_price = await shopProductsPrice(
        {'authO': $myAuthObject, 'selected_product' : myProductSelection } );
      let new_price_data = await new_price.data;
      // myProductSelection['__calculated_price'] = new_price_data; 

      setMyPrice( new_price_data );
    });

    const handleCalcNewPrice = async (e) => {
      if ( myProductSelection.selected ) {

        if ( myProductSelection.selected.employees ) {
          if ( myProductSelection.selected.employees < 50 ) {
            myProductSelection.selected.employees = 50;
          } else if ( myProductSelection.selected.employees > 2000 ) {
            myProductSelection.selected.employees = 2000;
          } 
        }

        if ( Number.isInteger( myProductSelection.selected.admins ) === false ) {
          myProductSelection.selected.admins = 1; }

        if ( Number.isInteger( myProductSelection.selected.languages ) === false ) {
          myProductSelection.selected.languages = 1; }        
      
      }

      if ( myProductSelection ) {
        let new_price = await shopProductsPrice(
          {'authO': $myAuthObject, 'selected_product' : myProductSelection } );
        let new_price_data = await new_price.data;
        setMyPrice( new_price_data );
        $myAuthObject.configured_product = new_price_data;

      }


    };

    // const handleShopCleaner = (e, attrib) => {
    //   myProductSelection.versions = myProductO.versions[0];
    //   myProductSelection.onboarding = myProductO.onboarding[0];
    //   ['admins', 'employees', 'languages'].forEach( e => {
    //     myProductSelection.selected[e] = myProductO['selected'][e];
    //   });

    //   handleCalcNewPrice();
    // };
    
    const handleShopSelect = (e, attrib) => {
      if ( attrib === 'Versions') {
        myProductSelection.versions = e.detail;
        let onBoardSel = myProductO.onboarding.find(e2 => e2.value === myProductSelection.versions.value );
        myProductSelection['selected']['onboarding'] = myProductSelection.versions.value
        myProductSelection['onboarding'] = onBoardSel;        
      } 
      else if ( attrib === 'Employees' ) {
        myProductSelection.employees = e.detail;
        let onBoardSel = myProductO.employees.find(e2 => e2.value === myProductSelection.employees.value );
        myProductSelection['selected']['employees'] = myProductSelection.employees.value
        myProductSelection['employees'] = onBoardSel;        
      }

      handleCalcNewPrice();
    };

    $myAuthObject.loading = false;

    const checkButtonClick = () => {
      $myAuthObject.loading = true;
      if ( $myAuthObject.inputReady === true ) { apiRunner(); }
    }

    const apiRunner = async () => { navigate('/auth/template', { replace: true }); }

    </script>

    <div class="container mx-auto px-4 h-full">
      <div class="flex content-center items-center justify-center h-full">
        <div class="w-full lg:w-6/12 px-4">
          <div class="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg bg-blueGray-200 border-0" >
            <div class="flex-auto px-4 lg:px-10 py-10 pt-0">
              <form>

                <div class="relative w-full mb-3 py-5">

                  <h3 class="text-2xl mb-2 font-semibold leading-none">
                    {$guiText.register.general.configure_your_product[my_lang]}
                  </h3>

                  <label
                    class="block text-blueGray-400 text-sm mb-2"
                    for="grid-project"
                  >
                    {$guiText.register.general.configure_your_product_defaults[my_lang]} 
                  </label>

                  <table class="table-fixed items-center w-full bg-transparent border-collapse">
                    <tbody>

                      <tr>
                        <th class="border-t-0 px-1 align-middle border-l-0 border-r-0 text-xs p-1 text-left flex items-center" >
                          <label class="block text-blueGray-400 text-sm mb-2 pt-2"
                            for="grid-project" >
                            {$guiText.register.general.configure_your_product_label_x[my_lang]}
                          </label>
                        </th>
                        <td class="border-t-0 px-1 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-1" >

                          <Select 
                            items={to_buy.versions}
                            placeholder={to_buy.version_default.label}
                            on:select={(e) => handleShopSelect(e, 'Versions')}
                            bind:value={myProductSelection.versions}
                            on:click={(e) => handleCalcNewPrice(e)}
                          ></Select>

                        </td>
                      </tr>

                      <tr>
                        <th class="border-t-0 px-1 align-middle border-l-0 border-r-0 text-xs p-1 text-left flex items-center" >
                          <label class="block text-blueGray-400 text-sm mb-2 pt-2"
                            for="grid-project" >
                            {$guiText.register.general.configure_your_product_employee[my_lang]}
                          </label>
                        </th>
                        <td class="border-t-0 px-1 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-1" >

                          <Select 
                            items={to_buy.employees}
                            placeholder={to_buy.employees_default.label}
                            on:select={(e) => handleShopSelect(e, 'Employees')}
                            bind:value={myProductSelection.employees}
                            on:click={(e) => handleCalcNewPrice(e)}
                          ></Select>

                        </td>
                      </tr>

                      <!-- <tr>
                        <th class="border-t-0 px-1 align-middle border-l-0 border-r-0 text-xs p-1 text-left flex items-center" >
                          <label class="block text-blueGray-400 text-sm mb-2 pt-2"
                            for="grid-project" >
                            {$guiText.register.general.configure_your_product_employee[my_lang]}
                          </label>
                        </th>
                        <td class="border-t-0 px-1 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-1" >

                          <input type="number" maxlength=4 min=50 max=2000
                            class="border-0 px-1 py-3 placeholder-blueGray-800 text-blueGray-900 bg-white rounded text-sm shadow 
                              focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                            placeholder={to_buy.selected.employees}
                            bind:value={myProductSelection.selected.employees}                               
                            on:input={(e) => handleCalcNewPrice(e)}
                          />

                        </td>
                      </tr> -->

                      <tr>
                        <th class="border-t-0 px-1 align-middle border-l-0 border-r-0 text-xs p-1 text-left flex items-center" >                        
                          <label class="block text-blueGray-400 text-sm mb-2 pt-2"
                            for="grid-project" >
                            {$guiText.register.general.configure_your_product_languages[my_lang]}
                          </label>
                        </th>
                        <td class="border-t-0 px-1 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-1" >

                          <input type="number" maxlength=3 min=1 max=123
                            class="border-0 px-1 py-3 placeholder-blueGray-800 text-blueGray-900 bg-white rounded text-sm shadow 
                              focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                            placeholder={to_buy.selected.languages}
                            bind:value={myProductSelection.selected.languages}  
                            on:input={(e) => handleCalcNewPrice(e)}
                          />

                        </td>                        
                      </tr>                      

                      <tr>
                        <th class="border-t-0 px-1 align-middle border-l-0 border-r-0 text-xs p-1 text-left flex items-center" >                        
                          <label class="block text-blueGray-400 text-sm mb-2 pt-2"
                            for="grid-project" >
                            {$guiText.register.general.configure_your_product_admins[my_lang]}
                          </label>
                        </th>
                        <td class="border-t-0 px-1 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-1" >

                          <input type="number" maxlength=2 min=1 max=50
                            class="border-0 px-1 py-3 placeholder-blueGray-800 text-blueGray-900 bg-white rounded text-sm shadow 
                              focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                            placeholder={to_buy.selected.admins}
                            bind:value={myProductSelection.selected.admins}                              
                            on:input={(e) => handleCalcNewPrice(e)}
                          />

                        </td>                        
                      </tr>                      

                    </tbody>
                  </table>
                
                </div>
    
                <div class="text-center mt-6">

                  {#if $myAuthObject.projectState === true}
                    {#if $myAuthObject.loading === true}

                      <LightGreyButon bg_color = "bg-blueGray-200">
                        <div slot="button_text">... {$guiText.general.sending[my_lang]}</div>
                      </LightGreyButon>

                    {:else}

                      <button
                        class="bg-blueGray-800 text-white active:bg-blueGray-600 text-sm font-bold px-6 py-3 
                            rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 w-full ease-linear 
                            transition-all duration-150"
                        type="button"
                        on:click={checkButtonClick}                                             
                      > 
                        {$guiText.general.continue[my_lang]}
                      </button>
                    {/if}

                  {:else}

                    <LightGreyButon bg_color = "bg-blueGray-200">
                      <div slot="button_text">{$guiText.general.continue[my_lang]}</div>
                    </LightGreyButon>

                  {/if}

                </div>


                <label
                  class="block text-blueGray-400 text-sm mb-2"
                  for="grid-project"
                >
                  <span class="block ml-2 text-xs text-red-400">

                    <table>
                      <tr> <td> &nbsp; </td> </tr>                      
                      <tr colpan=2><th> Price Setup:</th></tr>
                      <tr> 
                        <td>
                          {$guiText.register.general.base_price[my_lang]} 
                          {myProductSelection.selected.employees} 
                          {$guiText.register.general.singular_plural_employee[my_lang]}:</td> 
                        <td class="text-right">
                          {new Intl.NumberFormat('de-DE', { style: 'currency', currency: 'EUR' }).format(myPrice.price_base)}
                        </td>
                      </tr> 
                      <tr colpan=2> <td><b>Add Ons:</b></td> </tr> 
                      <tr> 
                        <td>{myProductSelection.selected.admins} 
                          {$guiText.register.general.singular_plural_admin[my_lang]}:</td> 
                        <td class="text-right">
                          {new Intl.NumberFormat('de-DE', { style: 'currency', currency: 'EUR' }).format(myPrice.price_admins)}
                        </td>
                      </tr> 
                      <tr> 
                        <td>{myProductSelection.selected.languages} 
                          {$guiText.register.general.singular_plural_langs[my_lang]}:</td> 
                        <td class="text-right">
                          {new Intl.NumberFormat('de-DE', { style: 'currency', currency: 'EUR' }).format(myPrice.price_languages) }
                        </td>
                      </tr>                       
                      <tr> <td> &nbsp; </td><td class="text-right">------</td> </tr>
                      <tr> 
                        <td><b>{$guiText.register.general.product_price_per_month[my_lang]}:</b></td> 
                        <td class="text-right">
                          {new Intl.NumberFormat('de-DE', { style: 'currency', currency: 'EUR' }).format(myPrice.price_product)}
                        </td>
                                                
                      </tr> 
                      <tr colpan=2> <td> &nbsp; </td> </tr>
                      
                      <!-- 
                      <tr> 
                        <td>{$guiText.register.general.initial_onboarding_price[my_lang]}:</td> 
                        <td>
                          {new Intl.NumberFormat('de-DE', { style: 'currency', currency: 'EUR' }).format(myPrice.price_onboarding)}
                        </td>
                      </tr>
                      <tr> <td> &nbsp; </td> </tr>                      
                    -->

                      <tr colpan=2> <td>
                        {$guiText.register.general.configure_your_product_monthly_costs[my_lang]} 
                        <b>{new Intl.NumberFormat('de-DE', { style: 'currency', currency: 'EUR' }).format(myPrice.price_product)}</b>
                      </td></tr>
                      <tr colpan=2> <td>
                        {$guiText.register.general.long_text_onboarding[my_lang]} 
                        {$guiText.register.general.initial_onboarding_amounts_to[my_lang]} 
                        <b>{new Intl.NumberFormat('de-DE', { style: 'currency', currency: 'EUR' }).format(myPrice.price_onboarding)}</b>
                      </td></tr>

                    </table>

                    <br/>
                    
                  </span>

                </label>


                {#if inputErrorMessage.length > 0 }
                  <div>
                    <span class="block ml-2 text-xs text-red-400">
                      {inputErrorMessage}
                    </span>                  
                  </div>
                {/if}

              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
    