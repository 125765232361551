<style>


#prosper_header_stats {
  padding-bottom: 8rem;
}


@media (max-width: 640px) {
  #prosper_header_stats {
    padding-bottom: 4rem;
  }
}




</style>

<script>
  
  // core components
  // import CardStats from "components/Cards/CardStats.svelte";
  
  // export let my_lang;

  // let registerBg2 = "/assets/img/landingpage_bg_en.jpg";
  // if (my_lang == "de") {
  //   registerBg2 = "/assets/img/register_bg_2.jpg";    
  // }
</script>

<!-- Header relative pb-32 -->
<div id="prosper_header_stats" class="md:pt-22 pt-12">
  <div class="mx-auto w-full">

    <!-- div>
      <div class="flex flex-wrap">
        <div class="w-full lg:w-6/12 xl:w-3/12 px-4">
          <CardStats
            statSubtitle="TRAFFIC"
            statTitle="350,897"
            statArrow="up"
            statPercent="3.48"
            statPercentColor="text-emerald-500"
            statDescripiron="Since last month"
            statIconName="far fa-chart-bar"
            statIconColor="bg-red-500"
          />
        </div>
        <div class="w-full lg:w-6/12 xl:w-3/12 px-4">
          <CardStats
            statSubtitle="NEW USERS"
            statTitle="2,356"
            statArrow="down"
            statPercent="3.48"
            statPercentColor="text-red-500"
            statDescripiron="Since last week"
            statIconName="fas fa-chart-pie"
            statIconColor="bg-orange-500"
          />
        </div>
        <div class="w-full lg:w-6/12 xl:w-3/12 px-4">
          <CardStats
            statSubtitle="SALES"
            statTitle="924"
            statArrow="down"
            statPercent="1.10"
            statPercentColor="text-orange-500"
            statDescripiron="Since yesterday"
            statIconName="fas fa-users"
            statIconColor="bg-pink-500"
          />
        </div>
        <div class="w-full lg:w-6/12 xl:w-3/12 px-4">
          <CardStats
            statSubtitle="PERFORMANCE"
            statTitle="49,65%"
            statArrow="up"
            statPercent="12"
            statPercentColor="text-emerald-500"
            statDescripiron="Since last month"
            statIconName="fas fa-percent"
            statIconColor="bg-emerald-500"
          />
        </div>
      </div>
    </div -->
  </div>
</div>
