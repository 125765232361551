<script>
    import Select from 'svelte-select';
    import { navigate } from "svelte-routing";    
    import { onMount } from "svelte";    

    import {myAuthObject} from "../../tstore/myauth";
    import {templatesGet} from "../../api/TemplatesGet";  

    import {guiText} from "../../tstore/guiText"; 
    import LightGreyButon from "../../components/Buttons/LightGreyButon.svelte";
    import {projectPost} from "../../api/ProjectPost";  
    // import {shopSubscription} from "../../api/ShopSubscription";

    export let my_lang;
    // export let color = "light";

    let inputErrorMessage = '';
    let templatesO = {};
    let templatesR = [];
    let to_buy = { 'templates' : [], 'template_default' : { 'label' : '' }, 'template_select' : {} };
   
    onMount( async () => { 
      $myAuthObject.projectState = false;
      $myAuthObject.loading = true;

      let myTemplatesRaw = await templatesGet({'authO': $myAuthObject, 'guiTxt': $guiText });
      templatesO = await myTemplatesRaw.for_select;

      /**
       * ermitteln ob die insel-auswahl mit dem insel-angebot der templates
       * übereinstimmt ! nur passende templates anzeigen !
      */

      for ( let i in templatesO ) {
        for ( let i2 in templatesO[i]['text'] ){
          if ( templatesO[i]['text'][i2]['localeCode'] ===  my_lang) {
            if ( templatesO[i]['text'][i2]['description'] ) {
              templatesO[i]['label'] = templatesO[i]['text'][i2]['description'];
            } else {
              templatesO[i]['label'] = `${templatesO[i]["description"]} island / bot: ${templatesO[i]["value"]}`;
            }
          }
        }
      }  

      try {
        templatesO.forEach(e => {
          if ( e['rx_count_of_islands'] === $myAuthObject['configured_product']['data']['versions']['value'] ) {
            templatesR.push(e);
          } } );   
      } catch (error) {
        templatesR = templatesO;
      }

      to_buy['templates'] = templatesR;
      to_buy['template_select'] = templatesR[0];
      to_buy['template_default'] = templatesR[0];

      $myAuthObject['selected_template'] = templatesR[0];
    });

    const handleTemplateSelect = async (e) => { 
      if ( e.detail.value === 'error' ) {
        $myAuthObject.projectState = false;
        $myAuthObject.inputReady = false;        
      } else {
        $myAuthObject.projectState = true;
        $myAuthObject.inputReady = true;
        $myAuthObject.loading = false; 
        $myAuthObject['selected_template'] = e.detail;
      }
    };

    $myAuthObject.loading = false;

    const checkButtonClick = () => {
      $myAuthObject.loading = true;
      if ( $myAuthObject.inputReady === true ) { apiRunner(); }
    }

    const apiRunner = async () => {
      $myAuthObject.loading = false;

      let rawresp = await projectPost({'authO': $myAuthObject, 'guiTxt': $guiText });
      let resp = await rawresp.data;

      if ( rawresp.status === true) {  
        $myAuthObject.projectId = resp.botId;

        navigate('/auth/user', { replace: true });

        /* 
        let rawInvoice = await shopSubscription(
          {'authO': $myAuthObject, 'guiTxt': $guiText });
        let respInvoice = await rawInvoice.data;        

        if ( rawInvoice.status === true) {  
          navigate('/auth/user', { replace: true });
        }
        else { 
          inputErrorMessage = rawresp.message; 
          $myAuthObject.projectState = false;
          $myAuthObject.inputReady = false;  
          $myAuthObject.loading = false;
        }
        */

      }      
      else { 
        inputErrorMessage = rawresp.message; 
        $myAuthObject.projectState = false;
        $myAuthObject.inputReady = false;  
        $myAuthObject.loading = false;
      } 




    }

    </script>

    <div class="container mx-auto px-4 h-full">
      <div class="flex content-center items-center justify-center h-full">
        <div class="w-full lg:w-6/12 px-4">
          <div class="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg bg-blueGray-200 border-0" >
            <div class="flex-auto px-4 lg:px-10 py-10 pt-0">
              <form>

                <div class="relative w-full mb-3 py-5">

                  <h3 class="text-2xl mb-2 font-semibold leading-none">
                    {$guiText.register.general.select_your_template[my_lang]}
                  </h3>

                  <label
                    class="block text-blueGray-400 text-sm mb-2"
                    for="grid-project"
                  >
                    {$guiText.register.general.select_fitting_template[my_lang]} 
                  </label>

                  <div class="border-t-0 px-1 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-1">
                    <Select 
                      items={to_buy.templates}
                      placeholder={to_buy.template_default.label}
                      on:select={(e) => handleTemplateSelect(e)}
                    ></Select>
                  </div>
                
                </div>
    
                <div class="text-center mt-6">

                  {#if $myAuthObject.projectState === true}
                    {#if $myAuthObject.loading === true}

                      <LightGreyButon bg_color = "bg-blueGray-200">
                        <div slot="button_text">... {$guiText.general.project_is_created[my_lang]}</div>
                      </LightGreyButon>

                    {:else}

                      <button
                        class="bg-blueGray-800 text-white active:bg-blueGray-600 text-sm font-bold px-6 py-3 
                            rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 w-full ease-linear 
                            transition-all duration-150"
                        type="button"
                        on:click={checkButtonClick}                                             
                      > 
                        {$guiText.general.continue[my_lang]}
                      </button>
                    {/if}

                  {:else}

                    <LightGreyButon bg_color = "bg-blueGray-200">
                      <div slot="button_text">{$guiText.general.continue[my_lang]}</div>
                    </LightGreyButon>

                  {/if}

                </div>

                {#if inputErrorMessage.length > 0 }
                  <div>
                    <span class="block ml-2 text-xs text-red-400">
                      {inputErrorMessage}
                    </span>                  
                  </div>
                {/if}

              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
    