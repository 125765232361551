<script>
  // library for creating dropdown menu appear on click
  // import { link } from "svelte-routing";  
  import { createEventDispatcher } from 'svelte';
  import { createPopper } from "@popperjs/core";

  import {guiText} from "../../tstore/guiText"; 
  // import {myAuthObject} from "../../tstore/myauth";

  export let recipient;
  export let my_lang;

  const delete_recipient = createEventDispatcher();
  // const landingpage_recipient = createEventDispatcher();
  const edit_recipient = createEventDispatcher();
  // const lock_recipient = createEventDispatcher();

  let dropdownPopoverShow = false;
  let btnDropdownRef;
  let popoverDropdownRef;

  const deleteRecipient = async (uuid) => {
    delete_recipient('delete_recipient', { 'uuid' : uuid });
    dropdownPopoverShow = false;
  };

  // const landingpageRecipient = (uuid) => {
  //   landingpage_recipient('landingpage_recipient', { 'uuid' : uuid });
  //   dropdownPopoverShow = false;    
  // };

  // const lockRecipient = (uuid) => {
  //   lock_recipient('lock_recipient', { 'uuid' : uuid });
  //   dropdownPopoverShow = false;    
  // };

  const editRecipient = (uuid) => {
    edit_recipient('edit_recipient', { 'uuid' : uuid });
    dropdownPopoverShow = false;    
  };

  const toggleDropdown = (event) => {
    event.preventDefault();
    if (dropdownPopoverShow) {
      dropdownPopoverShow = false;
    } else {
      dropdownPopoverShow = true;
      createPopper(btnDropdownRef, popoverDropdownRef, {
        placement: "bottom-start",
      });
    }
  };
</script>

<div>
  <a
    class="text-blueGray-500 py-1 px-3 hover:shadow-md"
    href="#pablo"
    bind:this="{btnDropdownRef}"
    on:click="{toggleDropdown}"
  >
    <i class="fas fa-ellipsis-v"></i>
  </a>

  <div bind:this="{popoverDropdownRef}"
    class="bg-white text-base z-50 float-left py-2 list-none text-left rounded shadow-lg min-w-48 {dropdownPopoverShow ? 'block':'hidden'}"
  >

    <ul class="md:flex-col md:min-w-full flex flex-col list-none">
      
      {#if recipient.valid === true }
      <li class="items-center">
        <a on:click={(e) => editRecipient(recipient.uuid)} href="#pablo"
          class="text-xs uppercase py-3 px-2 font-bold block text-red-600 hover:shadow-md" >
          <i class="fas fa-tools mr-2 text-sm text-blueGray-300"></i>
          {$guiText.general.edit[my_lang]}        
        </a>
      </li>
      {/if}

      <!-- li class="items-center">
        <a on:click={(e) => lockRecipient(recipient.uuid)} href="#pablo" 
          class="text-xs uppercase py-3 px-2 font-bold block text-red-600 hover:shadow-md" >
          <i class="fas fa-lock mr-2 text-sm text-blueGray-300"></i>
          {$guiText.general.deactivate[my_lang]}
        </a>
      </li -->      

      <li class="items-center">
        <a on:click={(e) => deleteRecipient(recipient.uuid)} href="#pablo" 
          class="text-xs uppercase py-3 px-2 font-bold block text-red-600 hover:shadow-md" >
          <i class="fas fa-trash mr-2 text-sm text-blueGray-300"></i>
          {$guiText.general.delete[my_lang]}
        </a>
      </li>

      <!-- 
      {#if recipient.valid === true }
        <li class="items-center">
          &nbsp;
        </li>  

        <li class="items-center">
          <a on:click={(e) => landingpageRecipient(recipient.uuid)} href="#pablo" 
            class="text-xs uppercase py-3 px-2 font-bold block text-red-600 hover:shadow-md" >
            <i class="fas fa-tv mr-2 text-sm text-blueGray-300"></i>
            Got to Prosperland
          </a>
        </li>
      {/if}
      -->

    </ul>

  </div>
</div>
