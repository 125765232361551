import {apiMaster} from "../api/ApiMaster";  

export async function shopStatus (data) {
    data.authO.loading = true;
    let projectId = '';
    try {        
        projectId = data.authO.projectId;
    } catch (error) {
        return {
            message : `${data.guiTxt.general.error_unknown_occured[data.authO.defLang]} ${data.guiTxt.general.contact_your_manager[data.authO.defLang]}`,
            status: false,
            data: false
        }
    };

    let requestOptions = {
        method: 'POST',
        headers: { 
            'Content-Type': 'application/json', 
            'X-Custom-Header': 'application/json' 
        },
        body: JSON.stringify({ 'project' : projectId })
    };

    let resp = await apiMaster(
        data,
        `${data.authO.uri_rwnapi_api}/shop/status`, 
        requestOptions
    );
 
    return resp;
}