import {apiMaster} from "../api/ApiMaster";  

export async function projectPost (data) {    
    data.authO.loading = true;

    let fullPath = '';
    try {
        fullPath = `${data.authO.uri_prosper_api}/survey?surveyUuid=${data.authO.selected_template.value}`;
    } catch (error) {
        // use default template, call without id
        fullPath = `${data.authO.uri_prosper_api}/survey`;
    }

    let requestOptions = {
        method: 'POST',
        headers: { 
            'Content-Type': 'application/json', 
            'X-Custom-Header': 'application/json',
            'Authorization' : `Bearer ${data.authO.bearer}`
        },

        body: JSON.stringify({
            "name": data.authO.project,
            "tenant": data.authO.tenantUuid,
            "languageCode": data.authO.defLang, 
            "label": data.authO.project
          })
    };

    let resp = await apiMaster( data, fullPath, requestOptions );    
    return resp;

}