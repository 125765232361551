<script>
  import { navigate } from "svelte-routing";
  import { onMount, onDestroy } from "svelte";    
  import {guiText} from "../../tstore/guiText";  

  import {labelsL2Get} from "../../api/LabelsL2Get";
  // eventApi exemplarisch angebunden, kommt erst zumeinsatz, wenn
  // google kalender eingebunden wird. 
  // import {eventsGet} from "../../api/EventsGet";

  import {myAuthObject} from "../../tstore/myauth";
  import {checkForRoleAdmin, checkForRoleAudience, checkForRoleProject} from '../../lib/checkAuthObject.js'; 
  import {tenantsGet} from "../../api/TenantsGet";
  import {surveysGet} from "../../api/SurveysGet";  
  import DashHeader from "components/Dashboards/DashHeader.svelte";
  import DashPCampagne from "components/Dashboards/DashPCampagne.svelte";
  import {calendarEventPost} from "../../api/CalendarEventPost";   
  import {filterGet} from "../../api/FilterGet";      
  import Pikaday from "pikaday";

  export let my_lang;
  export let my_ac_action; // = "feedback";
  export let color = "light";

  // für ausgewählte projekte werden die links auf DE gesezt
  // perleberg: 55109280-13fc-11ee-9534-376b5dabbac4
  let urlLang = my_lang; 
  /**
  if ( $myAuthObject.projectId === "55109280-13fc-11ee-9534-376b5dabbac4" ) {
    urlLang = "de";
  }
  */

  $myAuthObject.selectedLang = urlLang;

  const linkRoot = `${$myAuthObject.uri_prosper_mn}/${$myAuthObject.whoami.uuid}/${$myAuthObject.projectId}`;
  const linkIterim = `lang=${urlLang}&status=true&useRandomData=onlyData`;

  // let button_popup_class = "bg-red-400 text-white float-right active:bg-red-500 font-bold uppercase text-xs px-4 py-2 rounded shadow "; 
  let defTxtCol = 'bg-red-800 text-white';
  if (color === 'light'){ defTxtCol= 'bg-white'; }

  const flex_starter = "relative content-top items-top justify-top w-full heightdefs ";
  const flex_starter_header = "relative flex content-center items-center justify-center w-full heightdefs ";  
  
  const flex_ender = "mr-auto text-center break-words shadow-lg rounded " + defTxtCol;
  let defClassItem = flex_starter + "lg:w-12/12 mb-2 " + flex_ender;
  let defClassItemHeader = flex_starter_header + "lg:w-12/12 mb-2 " + flex_ender;

  // let buttonBase = "text-emerald-500 background-transparent font-bold capitalize px-4 ";
  // buttonBase += "hover:text-emerald-600 text-xs outline-none focus:outline-none ";
  // buttonBase += "mr-1 mb-1 mt-1 ease-linear transition-all duration-150 "; 

  // capitalize
  let rawButtonBase = "prosper_text_a background-transparent px-4 ";
  rawButtonBase += "hover:text-emerald-600 outline-none focus:outline-none ";
  rawButtonBase += "ease-linear transition-all duration-150 "; 

  let buttonBase = rawButtonBase + " py-4 text-xs mr-1 mb-1 mt-1 ";
  // let buttonDefault = buttonBase + " font-bold";
  let defHeaderText = rawButtonBase + " font-bold py-4 text-bold mr-0 mb-10 mt-1 ";
  let checklist_content_theme_a = rawButtonBase + " py-0 text-xs mr-1 mb-1 mt-1 font-bold";
  let checklist_content_theme_b = rawButtonBase + " py-0 text-xs mr-1 mb-1 mt-1 ";

  let tenantsLabel = '';
  let surveysLabel = ''; 
  let tenantsAry = []; 
  let surveys_by_tenant = {};
  let calendarData = [];
  let filterData = [];  

 
  // let l2themeValue;
  let now_date_pre = new Date();
  let now_date = new Date(now_date_pre.getFullYear(), now_date_pre.getMonth(), now_date_pre.getDate(), 0, 0, 0);

  let picker_start;
  let picker_end; 

  let defined_event = {
    l2themeValue : '',
    calendar_title : '',
    calendar_description : '',
    picker_start : '',
    picker_start2 : '',
    picker_end : '',
    picker_end2 : '',
    language : urlLang,
    surveyId : $myAuthObject.surveyId,
    start_email : '', 
		end_email : '',
    filter : '',
    event_type : 'checkup',
    my_ac_action_header : $guiText.general.checkup[my_lang],
    prosper_checkheader_text : $guiText.general.checklist[my_lang],
    prosper_checkheader_theme : $guiText.general.theme[my_lang],
    prosper_checkheader_filter : $guiText.general.audience_selection[my_lang],    
    prosper_checkheader_title : $guiText.general.title[my_lang],
    prosper_checkheader_description : $guiText.general.description[my_lang],
    prosper_checkheader_start_date : $guiText.general.start_date[my_lang],
    prosper_checkheader_end_date : $guiText.general.end_date[my_lang],
    prosper_checkheader_start_email : $guiText.general.inform_email_start[my_lang],
    prosper_checkheader_end_email : $guiText.general.inform_email_end[my_lang]
  };

  if (my_ac_action === 'action' ) { 
    defined_event.event_type = 'checkup';
    defined_event.my_ac_action_header = $guiText.general.plan_action[my_lang]; 
    defined_event.prosper_checkheader_text = $guiText.general.plan_action[my_lang];  
    defined_event.prosper_checkheader_theme = $guiText.general.action[my_lang] ;
    defined_event.prosper_checkheader_title = $guiText.general.sub_text[my_lang];
  }
  else if (my_ac_action === 'feedback' ) { 
    defined_event.event_type = 'feedback';
    defined_event.my_ac_action_header = $guiText.general.plan_feedback[my_lang]; 
    defined_event.prosper_checkheader_text = $guiText.general.plan_feedback[my_lang];      
    defined_event.prosper_checkheader_theme = $guiText.general.kind_of_feedback[my_lang];
    }  
  else if (my_ac_action === 'publication' ) { 
    defined_event.event_type = 'publication';
    defined_event.my_ac_action_header = $guiText.general.plan_publication[my_lang]; 
    defined_event.prosper_checkheader_text = $guiText.general.publication_checklist[my_lang];          
    defined_event.prosper_checkheader_theme = $guiText.general.headline[my_lang];
    defined_event.prosper_checkheader_title = $guiText.general.sub_head[my_lang];    
    defined_event.prosper_checkheader_description = $guiText.general.body_text[my_lang];    
    defined_event.prosper_checkheader_start_date = $guiText.general.publishing_date[my_lang];
    defined_event.prosper_checkheader_end_date = $guiText.general.cancel_date[my_lang];
    }  
  else if (my_ac_action === 'now' ) { 
    defined_event.event_type = 'now';

    // picker_start.setDate(new Date());
    // prosperPikadayStartChanged();

    defined_event.my_ac_action_header = $guiText.general.do_something_now[my_lang]; 
    defined_event.prosper_checkheader_text = $guiText.general.do_something_now[my_lang];          
    defined_event.prosper_checkheader_theme = $guiText.general.kind_of_action[my_lang];    
    defined_event.prosper_checkheader_title = $guiText.general.topic[my_lang];    
    defined_event.prosper_checkheader_description = $guiText.general.body_text[my_lang];
    defined_event.prosper_checkheader_start_date = $guiText.general.right_now_start_1[my_lang];
    defined_event.prosper_checkheader_end_date = $guiText.general.a_later_date_start_2[my_lang];        
  }      

  const roleAdminStatus = checkForRoleAdmin($myAuthObject);
  const roleAudienceStatus = checkForRoleAudience($myAuthObject);
  const roleProjectStatus = checkForRoleProject($myAuthObject);
  
  onMount(async () => { 
    if ( roleAdminStatus === false && roleAudienceStatus === false && roleProjectStatus === false) { navigate('/auth/login', { replace: true }); };

    let tenantsRawResp = await tenantsGet({'authO': $myAuthObject, 'guiTxt': $guiText });
    tenantsAry = await tenantsRawResp.data; 
    let surveysRawResp = await surveysGet({'authO': $myAuthObject, 'guiTxt': $guiText });
    surveys_by_tenant = await surveysRawResp.by_tenant;

    tenantsLabel = tenantsAry[0].label;
    surveysLabel = surveys_by_tenant[tenantsAry[0].uuid][0]['label'];    

    if ( $myAuthObject.projectId === '55109280-13fc-11ee-9534-376b5dabbac4' ) {
      tenantsLabel = 'Grabow';
      surveysLabel = 'Grabow';
    }

    let eleT = document.getElementById('admin_navbar_tenant');
    eleT.innerHTML = '';
    eleT.insertAdjacentHTML('beforeend', '<i class="fas fa-industry mr-2 text-sm text-blueGray-400"></i>');
    eleT.insertAdjacentText('beforeend', tenantsLabel);
    
    let eleP = document.getElementById('admin_navbar_project');
    eleP.innerHTML = '';
    eleP.insertAdjacentHTML('beforeend', '<i class="fas fa-square-poll-horizontal mr-2 text-sm text-blueGray-400"></i>');
    eleP.insertAdjacentText('beforeend', surveysLabel);      

    // L2 Labels welche im Pulldown angezeigt werden
    let rawresp = await labelsL2Get( {'authO': $myAuthObject, 'guiTxt': $guiText } );
    let resp = await rawresp.data;

    if ( resp === false ) {
      calendarData.push({ 
        "value": "0_0_0", 
        "text": "API failed", 
        "question": `${$guiText.general.error_unknown_occured[my_lang]} ${$guiText.general.contact_your_manager[my_lang]}` 
      });
    } else { 
      calendarData = resp; 
    }

    // Liste mit definierten Events
    /** 
    let raw_defined_events = await eventsGet( {'authO': $myAuthObject, 'guiTxt': $guiText }, 
                  { "function": "", "start" : "", "end" : "" });
    let defined_events = await raw_defined_events.data;

    if ( defined_events === false ) { console.log(raw_defined_events.data);
    } else { console.log(defined_events); }        
    */

    let filterrawresp = await filterGet({'authO': $myAuthObject, 'guiTxt': $guiText });
    filterData = await filterrawresp.data;


    // init Datums-library
    const element_start = document.querySelector('#pikaday_start');    
    picker_start = new Pikaday({ field: element_start, format: 'DD.MM.YYYY' });    
    if (my_ac_action === 'now' ) { picker_start.setDate(new Date()); }

    const element_end = document.querySelector('#pikaday_end');
    picker_end = new Pikaday({ field: element_end, format: 'DD.MM.YYYY' });

  });

  onDestroy(async () => {
    picker_start.destroy();
    picker_end.destroy();
  });

  const checkEventDefinitionLogic = () => {
    /** 
     * defined_event.l2themeValue ist hier ein string !!!
     * my_ac_action === 'momentum' -> l2themeValue ist ein INT
     * my_ac_action !== 'momentum' -> l2themeValue ist ein STRING
     * 
     * event-start darf nicht in vergangenheit liegen 
     * event-start muss vor event-end liegen ! 
     */

    let pcb_state = false;

    if (  defined_event.l2themeValue.length > 3 &&  
          defined_event.calendar_title.length > 3 &&
          defined_event.calendar_description.length > 3 &&
          defined_event.picker_start.length === 10
    ) {
      if (  my_ac_action === 'action' || 
            my_ac_action === 'checkup' || 
            my_ac_action === '' || 
            my_ac_action === 'feedback' ) { pcb_state =  checkEventDefinitionLogicStartEndDate(); 
      }
      else if ( my_ac_action === 'now' ||  
                my_ac_action === 'publication' ) {
        let dstart = new Date( `${defined_event.picker_start.slice(6)}.${defined_event.picker_start.slice(3,5)}.${defined_event.picker_start.slice(0,2)}`);        
        if ( dstart >= now_date ) {
          if (  defined_event.picker_end.length === 0 ) { pcb_state = true;  } 
          else { pcb_state = checkEventDefinitionLogicStartEndDate(); }
        }
      }

    }
    return pcb_state;
  }

  const checkEventDefinitionLogicStartEndDate = () => {
    let pcb_state = false;
    if (  defined_event.picker_end.length === 10 ) {           

          let dstart = new Date( `${defined_event.picker_start.slice(6)}.${defined_event.picker_start.slice(3,5)}.${defined_event.picker_start.slice(0,2)}`);
          let dend = new Date( `${defined_event.picker_end.slice(6)}.${defined_event.picker_end.slice(3,5)}.${defined_event.picker_end.slice(0,2)}`);                      

          if( dstart >= now_date && dend >= dstart ) {  pcb_state = true;  }

        }

      return pcb_state;
  }


  const checkEventDefinitionState = () => {

    let pcb_color = "CFCECE";
    let pcb_state = false;
    let pcd_csb = document.getElementById('prosperCalendarSendButton');

    if ( checkEventDefinitionLogic() === true ) {
      pcb_state = true;
      pcb_color = "A6CD8A";
    }

    pcd_csb.setAttribute('style', `align-items: flex-end; width: 80%; color: #ffffff; background-color: #${pcb_color};`);  
    
    return pcb_state;
  }

  const prosperPikadayStartChanged = () => { 
    // 27.03.2024
    defined_event.picker_start = picker_start.toString(); 
    // 2024-03-16T23:59
    defined_event.picker_start2 = picker_start.toString('YYYY-MM-DD') + "T00:00";
    checkEventDefinitionState();    
  }

  const prosperPikadayEndChanged = () => { 
    defined_event.picker_end = picker_end.toString(); 
    defined_event.picker_end2 = picker_end.toString('YYYY-MM-DD') + "T23:59";
    checkEventDefinitionState();    
  }  

  const prosperFilterChanged = () => { checkEventDefinitionState(); }

  const prosperThemeChanged = () => {

    let l2themeText = '';
    let l2themeQuestion = '';

    for ( let citm in calendarData ) {
      if ( calendarData[citm].value === defined_event.l2themeValue ) {
        l2themeText = calendarData[citm].text;
        l2themeQuestion = calendarData[citm].question;  
        break;
      }
    }

    let l2themeValueSplits = prosper_l2_txt_set(l2themeText);

    let pct_ele = document.getElementById('prosper_calendar_title');
    pct_ele.value = l2themeValueSplits[(l2themeValueSplits.length - 1)];

    let pcd_ele = document.getElementById('prosper_calendar_description');
    pcd_ele.value = l2themeText.trim() + " : " + l2themeQuestion;

    defined_event.calendar_title = pct_ele.value;
    if ( pcd_ele.value === " : " || pcd_ele.value === ":" ) {
      defined_event.calendar_description = "";
    } else {
      defined_event.calendar_description = pcd_ele.value;
    }

    checkEventDefinitionState();
  }

  function prosper_l2_txt_set(in_o) {
    try {
        let in_text = in_o.split(' - ');
        return in_text;
    } catch {
        return [ '', ''];
    }
  }

  const toggleNote = () => {
    let pcd_note = document.getElementById('miniHeaderGridB');    
    pcd_note.innerHTML = '';    

  }

  const checkButtonClick = () => { if ( checkEventDefinitionLogic() === true ) { apiRunner(); } }

  const apiRunner = async () => {
    let rawresp = await calendarEventPost({'authO': $myAuthObject, 'guiTxt': $guiText }, defined_event);
    let resp = await rawresp.data;    

    let pcd_csb = document.getElementById('prosperCalendarSendButton');
    pcd_csb.setAttribute('style', `align-items: flex-end; width: 80%; color: #ffffff; background-color: #CFCECE;`); 

    let note_text = $guiText.general.error_sending_data[my_lang];
    let note_style = "242, 0, 0";    
    let pcd_note = document.getElementById('miniHeaderGridB');

    defined_event.l2themeValue = '';
    defined_event.filter = ''; 
    defined_event.calendar_title = '';
    defined_event.calendar_description = '';
    defined_event.picker_start = '';
    defined_event.picker_start2 = '';
    defined_event.picker_end = '';
    defined_event.picker_end2 = '';
    defined_event.start_email = ''; 
    defined_event.end_email = ''; 

    if ( resp !== false ) {
        // update kalender
        let myTurl = `${linkRoot}/calendar?${linkIterim}`;
        document.getElementById('main_popup_body').innerHTML = `<iframe src="${myTurl}" width="100%" height="680px"></iframe>`;
        note_style = "166, 205, 138";
        note_text = $guiText.general.data_was_sent_successfully[my_lang];
    };

    pcd_note.innerHTML = ` 
      <div 
        class="${rawButtonBase} font-bold py-1 text-bold mr-0 mb-1 mt-0" 
            style="font-size: 80%; background-color: rgb(${note_style});"
      > ${note_text} </div> `;    

    await setTimeout(toggleNote, 4000);

    return true;    
}

</script>

<style>


  #miniHeaderGrid {
    display: grid;
    grid-template-columns: 100%;
    grid-template-rows: 80% 20%;    
    grid-gap: 0.5em;    
  }

  #miniHeaderGridA { grid-column: 1; grid-row: 1; }
  #miniHeaderGridB { grid-column: 1; grid-row: 2; }

  main {
    display: grid;
    grid-template-columns: 25% 25% 25% 25%;
    grid-template-rows: 3vw 3vw 5vw auto;    
    grid-gap: 0.5em;
  }

  .prosper_image { object-fit: fill ; height: 15vw; }
  .prosper_image_full { object-fit: none; }  
  .prosper_image_fill { object-fit: fill; }  

  #prosper_header { grid-column: 1 / span 4; grid-row: 1; }
  #prosper_subheader { grid-column: 1 / span 4; grid-row: 2; }  

  #prosper_calendar { grid-column: 1 / span 3; grid-row: 3 / span 5; }
  #prosper_checkheader { grid-column: 4 ; grid-row: 3; }
  #prosper_checklist { grid-column: 4; grid-row: 4 / span 4; }


  .checklist_content {
    display: grid;
    grid-template-columns: 95%;
    grid-template-rows: auto;    
  }

  .checklist_content_a {
    align-items: baseline;
    text-align: left;
  }

  .checklist_input {
    width: 100%; 
    padding: 1px 1px; 
    margin: 1px 0; 
    box-sizing: border-box;
  }

  @media (max-width: 640px) {
    main {
      display: grid;
      grid-template-columns: 100%;
      grid-template-rows: auto;
      grid-gap: 1em;
    }

    #prosper_header { grid-column: 1 ; grid-row: 1; }
    #prosper_subheader { grid-column: 1 ; grid-row: 2; }  
    #prosper_calendar { grid-column: 1 ; grid-row: 3; }
    #prosper_checkheader { grid-column: 1 ; grid-row: 4; }
    #prosper_checklist { grid-column: 1 ; grid-row: 5; }

  }

</style>

{#if $myAuthObject.whoami.valid === true }

<main>
  <div id="prosper_header">
    <DashHeader 
      color="ffffff" 
      background_color="076623" 
      headline={$guiText.general.action_and_communication_center[my_lang]} show_logo="show" />
  </div>

  <div id="prosper_subheader">    
    <DashHeader color="ffffff" background_color="A6CD8A" headline="{defined_event.my_ac_action_header}" />
  </div>

  <div id="prosper_calendar">
    <div class="heightdefs">
      <div class="{defClassItem}">           

        <div id="main_popup_body">
          <DashPCampagne myTUrl={`${linkRoot}/calendar?${linkIterim}`} myWidth="100%" />
        </div>

      </div>
    </div>
  </div>

  <div id="prosper_checkheader">
    <div class="heightdefs">
      <div id="miniHeaderGrid" class="{defClassItemHeader}">                    

        <div 
          id="miniHeaderGridA" 
          class="{rawButtonBase} font-bold py-1 text-bold mr-1 mb-10 mt-5" 
          style="font-size: 100%; ">          
          {defined_event.prosper_checkheader_text}
        </div>

        <div id="miniHeaderGridB" > </div>  

      </div>
    </div>
  </div>

  <div id="prosper_checklist">
    <div class="heightdefs">
      <div class="{defClassItem}">
        <div class="checklist_content">           




          <div class="checklist_content_a {checklist_content_theme_a}">
            <label for="filter"> {defined_event.prosper_checkheader_filter} 
              <!-- span style="color:red; fontSize:12px;">*</span></label -->
          </div>
          <div class="checklist_content_a {checklist_content_theme_b}">
            <select 
              name="prosper_filter" 
              id="prosper_filter" 
              class="checklist_input {buttonBase}" 
              bind:value={defined_event.filter}
              on:change={prosperFilterChanged}              
            >
              <option value="0"> <i class="fa-solid fa-caret-down"></i>  </option>
              {#each filterData as citm}
                <option 
                  value={citm.value} 
                  class="checklist_content_a {checklist_content_theme_b}"
                  style="text-transform: capitalize;"
                >
                  {citm.text}    
                </option>
              {/each}
            </select>
          </div>









          <div class="checklist_content_a {checklist_content_theme_a}">
            <label for="l2theme"> {defined_event.prosper_checkheader_theme} <span style="color:red; fontSize:12px;">*</span></label>
          </div>
          <div class="checklist_content_a {checklist_content_theme_b}">
            <select 
              name="l2theme" 
              id="l2theme" 
              class="checklist_input {buttonBase}" 
              bind:value={defined_event.l2themeValue}
              on:change={prosperThemeChanged}              
            >
              <option value="0"> <i class="fa-solid fa-caret-down"></i>  </option>
              {#each calendarData as citm}
                <option 
                  value={citm.value} 
                  class="checklist_content_a {checklist_content_theme_b}">
                  {citm.text}
                </option>
              {/each}
            </select>
          </div>
          
          <div class="checklist_content_a {checklist_content_theme_a}"> 
            {defined_event.prosper_checkheader_title} <span style="color:red; fontSize:12px;">*</span>
          </div>          
          <div class="checklist_content_a {checklist_content_theme_b}"> 
            <input 
              type="text" 
              id="prosper_calendar_title" 
              name="prosper_calendar_title"
              bind:value={defined_event.calendar_title}              
              on:change={checkEventDefinitionState}
              class="checklist_input {checklist_content_theme_b}"
            >
          </div>
          
          <div class="checklist_content_a {checklist_content_theme_a}"> 
            {defined_event.prosper_checkheader_description} <span style="color:red; fontSize:12px;">*</span>
          </div>          
          <div class="checklist_content_a {checklist_content_theme_b}"> 
            <textarea 
              type="text" 
              rows=4
              id="prosper_calendar_description" 
              name="prosper_calendar_description"                            
              on:change={checkEventDefinitionState}
              bind:value={defined_event.calendar_description}                            
              class="checklist_input  {checklist_content_theme_b}"
            />
            <!-- /textarea -->  
          </div>

          <div class="checklist_content_a {checklist_content_theme_a}"> 
            {defined_event.prosper_checkheader_start_date} <span style="color:red; fontSize:12px;">*</span>
          </div>          
          <div class="checklist_content_a {checklist_content_theme_b}"> 
            <input 
              class="checklist_input {buttonBase}" 
              type="text" 
              autocomplete="off" 
              id="pikaday_start" 
              on:change={prosperPikadayStartChanged} 
              bind:value={defined_event.picker_start}
              >
          </div>

          {#if my_ac_action === 'action' || my_ac_action === 'checkup' || my_ac_action === '' }
            <div class="checklist_content_a {checklist_content_theme_a}"> 
              {defined_event.prosper_checkheader_start_email}
            </div>          
            <div class="checklist_content_a {checklist_content_theme_b}">
              <input 
                type="text" 
                id="prosper_start_email" 
                name="prosper_start_email"
                bind:value={defined_event.start_email}              
                on:change={checkEventDefinitionState}
                class="checklist_input {checklist_content_theme_b}"
              >            
            </div>
          {/if}

          <div class="checklist_content_a {checklist_content_theme_a}">             
            {defined_event.prosper_checkheader_end_date} 
            {#if my_ac_action !== 'now' && my_ac_action !== 'publication' } 
              <span style="color:red; fontSize:12px;">*</span> 
            {/if}
          </div>          
          <div class="checklist_content_a {checklist_content_theme_b}"> 
            <input 
              class="checklist_input {buttonBase}" 
              type="text" 
              autocomplete="off" 
              id="pikaday_end" 
              on:change={prosperPikadayEndChanged}
              bind:value={defined_event.picker_end}
              > 
          </div>

          {#if my_ac_action === 'action' || my_ac_action === 'checkup' || my_ac_action === '' }
            <div class="checklist_content_a {checklist_content_theme_a}"> 
              {defined_event.prosper_checkheader_end_email}
            </div>
            <div class="checklist_content_a {checklist_content_theme_b}"> 
              <input 
                type="text" 
                id="prosper_end_email" 
                name="prosper_end_email"
                bind:value={defined_event.end_email}              
                on:change={checkEventDefinitionState}
                class="checklist_input {checklist_content_theme_b}"
              >            
            </div>
          {/if}

        </div>

        <button          
          class=" font-bold uppercase text-xs rounded shadow hover:shadow-md outline-none focus:outline-none 
                ease-linear transition-all duration-150 relative px-4 py-1 mr-1 mt-4"
          type="button"
          style="align-items: flex-end; width: 80%; color: #ffffff; background-color: #CFCECE;"
          id="prosperCalendarSendButton"
          on:click={checkButtonClick} 
        > 
          <!-- on:click={() => toggleModalChartPieClick( `${item.label}`, `${item.id}`)} -->                
          {$guiText.general.send[my_lang]}
        </button>



      </div>
    </div>
  </div>

</main>

{/if}
