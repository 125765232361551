import {apiMaster} from "./ApiMaster";  

export async function momentumSurveyGet (data) {
    data.authO.loading = true;

    let requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ 
            "surveyBot": data.authO.projectId,
            "userId": data.authO.userUuid,
            "language": data.authO.selectedLang
        })
    };

    let resp = await apiMaster(
        data,
        `${data.authO.uri_prosper_mn}/api/momentumSurveys`, 
        requestOptions
    );

    return resp;
}
