<script>
  import { navigate } from "svelte-routing"; 
  import {selectTextOnFocus, blurOnEscape} from '../../lib/inputDirectives.js';     
  import {myAuthObject} from "../../tstore/myauth";
  import {guiText} from "../../tstore/guiText";
  import LightGreyButon from "../../components/Buttons/LightGreyButon.svelte";  
  import {otpNewPost} from "../../api/OtpNewPost";
  import {otpLoginPost} from "../../api/OtpLoginPost";  

  export let my_lang;

  let verificationDigits = [ '', '', '', '', '', '' ];
  let inputErrorMessage = '';
  $myAuthObject.otpState = false;
  $myAuthObject.loading = false;

  /**
   * ACHTUNG!!!! 
   * createOTP() wird direkt beim laden der seite aufgerufen, 
   * wird danach bsp. ein onMount() ausgeführt, dann wird createOTP() 
   * nochmal ausgeführt. es handelt sich um einen svelte "bug" 
   * folgende art der konotation führt zu einem einfachen aufruf 
  **/
  const createOTP = async () => {    
    let resp = await otpNewPost({'authO': $myAuthObject, 'guiTxt': $guiText });
  }

  const checkInputVerification = (event) => {  
    let id_pre = event.target.id.substring( 0, ( event.target.id.length - 1 ) );
    
    let trueCount = 0;
    for (let i = 0; i < verificationDigits.length; i++) {
      if ( verificationDigits[i] === '' ) {
        document.activeElement.blur();
        document.getElementById(`${id_pre}${i}`).focus();        
        $myAuthObject.otpState = false;
        break;
      } 
      else { trueCount += 1; }
    }

    if ( trueCount === verificationDigits.length ) {
      $myAuthObject.otpState = true;
    }

  }

  // const checkClickChangeEmail = () => {
  //   $myAuthObject.loading = true; 
  //   $myAuthObject.inputReady = true;     
  //   navigate('/auth/register', { replace: true });
  // }
  
  const checkButtonClick = () => {
    if ( $myAuthObject.otpState === true) {
      $myAuthObject.loading = true;
      apiRunner();
    }
  }

  const apiRunner = async () => {
    let rawresp = await otpLoginPost({'authO': $myAuthObject, 'guiTxt': $guiText }, verificationDigits);
    let resp = await rawresp.data;    
    $myAuthObject.loading = true;

    $myAuthObject.bearer = '';
    if ( resp.type === 'Bearer ' && resp.token.length > 30 ) { 
      $myAuthObject.bearer = resp.token;
      navigate('/auth/password', { replace: true });
    }
    else { 
      inputErrorMessage = rawresp.message; 
      $myAuthObject.loading = false;
      $myAuthObject.otpState = false;       
    }
    
	}
  
  </script>

  <div class="container mx-auto px-4 h-full">
    <div class="flex content-center items-center justify-center h-full">
      <div class="w-full lg:w-6/12 px-4">
        <div class="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg bg-blueGray-200 border-0" >
          <div class="flex-auto px-4 lg:px-10 py-10 pt-0">

            <form>
              <div class="relative w-full mb-3 py-5">

                <h3 class="text-2xl mb-2 font-semibold leading-none">
                  {$guiText.general.check_your_email[my_lang]}
                </h3>

                <label
                  class="block text-blueGray-400 text-sm mb-2 text-center"
                  for="grid-verify"
                >
                  {$guiText.register.general.verification_six_digits[my_lang]}
                </label>

                <label
                  class="block text-blueGray-600 text-sm mb-2 text-center"
                  for="grid-verify"
                > 
                    <b>{$myAuthObject.email}</b> 

                    <!-- button
                    class="bg-blueGray-200 text-blueGray-400 active:bg-blueGray-200 text-sm font-bold px-6 py-3 
                        outline-none focus:outline-none mr-1 mb-1 w-full ease-linear transition-all duration-150"
                    type="button"
                    on:click={checkClickChangeEmail}                      
                    > 
                      ({$guiText.register.general.change_email[my_lang]})
                    </button //-->

                </label>
                
                <section class="relative py-1 content-center px-4 mx-auto flex flex-nowrap">
                  <input
                      id="grid-email_verify_0"
                      type="text"
                      maxlength="1"                      
                      min="1"
                      max="1"
                      class="border-0 px-1 py-3 placeholder-blueGray-800 text-blueGray-900 bg-white rounded text-sm shadow 
                        focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                      bind:value={verificationDigits[0]}
                      on:input={checkInputVerification}
                      use:selectTextOnFocus 
                      use:blurOnEscape                  
                  />
                  <input
                      id="grid-email_verify_1"
                      type="text"
                      maxlength="1"                      
                      min="1"
                      max="1"                      
                      class="border-0 px-1 py-3 placeholder-blueGray-800 text-blueGray-900 bg-white rounded text-sm shadow 
                          focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                      bind:value={verificationDigits[1]}
                      on:input={checkInputVerification}
                      use:selectTextOnFocus 
                      use:blurOnEscape                                        
                  />
                  <input
                      id="grid-email_verify_2"
                      type="text"
                      maxlength="1"
                      min="1"
                      max="1"                      
                      class="border-0 px-1 py-3 placeholder-blueGray-800 text-blueGray-900 bg-white rounded text-sm shadow 
                          focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                      bind:value={verificationDigits[2]}
                      on:input={checkInputVerification}
                      use:selectTextOnFocus 
                      use:blurOnEscape                                        
                  />  
                  <div class="px-4 text-black font-bold text-xl text-center"> 
                    -
                  </div>
                  <input
                      id="grid-email_verify_3"
                      type="text"
                      maxlength="1"
                      min="1"
                      max="1"                      
                      class="border-0 px-1 py-3 placeholder-blueGray-800 text-blueGray-900 bg-white rounded text-sm shadow 
                          focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                      bind:value={verificationDigits[3]}
                      on:input={checkInputVerification}
                      use:selectTextOnFocus 
                      use:blurOnEscape                                        
                />
                <input
                      id="grid-email_verify_4"
                      type="text"
                      maxlength="1"
                      min="1"
                      max="1"
                      class="border-0 px-1 py-3 placeholder-blueGray-800 text-blueGray-900 bg-white rounded text-sm shadow 
                          focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                      bind:value={verificationDigits[4]}
                      on:input={checkInputVerification}
                      use:selectTextOnFocus 
                      use:blurOnEscape                                        
                />
                <input
                      id="grid-email_verify_5"
                      type="text"
                      maxlength="1"
                      min="1"
                      max="1"
                      class="border-0 px-1 py-3 placeholder-blueGray-800 text-blueGray-900 bg-white rounded text-sm shadow 
                          focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                      bind:value={verificationDigits[5]}
                      on:input={checkInputVerification}
                      use:selectTextOnFocus 
                      use:blurOnEscape                                        
                />
                </section>

              </div>
  
              <div class="text-center mt-6">
                {#if $myAuthObject.otpState === true}
                  {#if $myAuthObject.loading === true}

                    <LightGreyButon bg_color = "bg-blueGray-200">
                      <div slot="button_text">... {$guiText.general.checking[my_lang]}</div>
                    </LightGreyButon>

                  {:else}

                    <button
                      class="bg-blueGray-800 text-white active:bg-blueGray-600 text-sm font-bold px-6 py-3 
                          rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 w-full ease-linear 
                          transition-all duration-150"
                      type="button"
                      on:click={checkButtonClick}                      
                    > 
                      {$guiText.register.general.check[my_lang]}
                    </button>
                  {/if}
                  
                {:else}

                  <LightGreyButon bg_color = "bg-blueGray-200">
                    <div slot="button_text">{$guiText.register.general.check[my_lang]}</div>
                  </LightGreyButon>

                {/if}

              </div>

              {#if inputErrorMessage.length > 0 }
                <div>
                  <span class="block ml-2 text-xs text-red-400">
                    {inputErrorMessage}
                  </span>                  
                </div>
              {/if}

            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
  
