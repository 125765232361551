<script>
  // make dynamic date to be added to footer
  let date = new Date().getFullYear();
</script>

<footer class="block py-4">
  <div class="container mx-auto px-4">
    <hr class="mb-4 border-b-1 border-blueGray-200" />
    <div class="flex flex-wrap items-center md:justify-right justify-right">      
      <div class="w-full md:w-1/1 px-1">
        <ul class="flex flex-wrap list-none md:justify-end justify-right">
          <li>
            <a
              href="https://www.metrinomics.com"
              class="text-blueGray-600 hover:text-blueGray-800 text-sm font-semibold block py-1 px-3"
            >
              Copyright © {date} Prosperland
            </a>
          </li>

        </ul>
      </div>
    </div>
  </div>
</footer>
