import {apiMaster} from "../api/ApiMaster";  

export async function passwordPut (data, myPassword) {
    data.authO.loading = true;

    let requestOptions = {
        method: 'PUT',
        headers: { 
            'Content-Type': 'application/json', 
            'X-Custom-Header': 'application/json',
            'Authorization' : `Bearer ${data.authO.bearer}`
        },
        body: JSON.stringify({ 'password': myPassword })
      };

    let resp = await apiMaster(
        data,
        `${data.authO.uri_prosper_api}/user/password`, 
        requestOptions
    );
        
    return resp;
}