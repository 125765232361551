<script>
  import { navigate } from "svelte-routing";
  import { onMount } from "svelte";    
  import {guiText} from "../../tstore/guiText";
  import {myAuthObject} from "../../tstore/myauth";
  import {checkForRoleAdmin, checkForRoleAudience, checkForRoleProject} from '../../lib/checkAuthObject.js'; 
  import {tenantsGet} from "../../api/TenantsGet";
  import {surveysGet} from "../../api/SurveysGet";  
  import DashHeader from "components/Dashboards/DashHeader.svelte";
  import PieBPModal from "components/Dashboards/PieBPModal.svelte";

  export let my_lang;
  export let color = "light";

  let defTxtCol = 'bg-red-800 text-white';
  if (color === 'light'){ defTxtCol= 'bg-white'; }

  const flex_starter = "relative flex content-center items-center justify-center w-full heightdefs ";
  const flex_ender = "mr-auto text-center break-words shadow-lg rounded text_prosper_block font-semibold text-center " + defTxtCol;
  let defClassItem = flex_starter + " lg:w-12/12 mb-2 " + flex_ender;

  let rawButtonBase = "prosper_text_a background-transparent capitalize px-4 ";
  rawButtonBase += "hover:text-emerald-600 text-xs outline-none focus:outline-none ";
  rawButtonBase += "mr-1 mb-1 mt-1 ease-linear transition-all duration-150 "; 

  let popup_pie_label ='';
  let popup_pie_id = '';
  let tenantsLabel = '';
  let surveysLabel = ''; 
  let popup_pie_header = '';
  let tenantsAry = []; 
  let surveys_by_tenant = {};

  const pies = [ 
    { 'label' : 'happy', 
      'id': '5', 
      'png': 'fke_torte_happy', 
      'trans' : $guiText.general.happy[my_lang],
      'trans_label' : $guiText.general.happiness[my_lang], 
      'percent' : 18,
      'percent_color' : 'rgb(166, 205, 138)',
      'my_classes' :[ 'my_sub_grid_a', 'my_sub_grid_b', 'my_sub_grid_c', 'my_sub_grid_e', 'my_sub_grid_d', 'my_sub_grid_f' ] 
    },
    { 'label' : 'satisfied', 
      'id': '5_4', 
      'png': 'fke_torte_sat', 
      'trans' : $guiText.general.satisfied[my_lang],
      'trans_label' : $guiText.general.satisfaction[my_lang], 
      'percent' : 44,
      'percent_color' : 'rgb(48, 124, 62)',            
      'my_classes' :[ 'my_sub_grid_a', 'my_sub_grid_b', 'my_sub_grid_c', 'my_sub_grid_e', 'my_sub_grid_d', 'my_sub_grid_f' ]
    },
    { 'label' : 'dissatisfied', 
      'id': '5_4_3', 
      'png': 'fke_torte_unsat', 
      'trans' : $guiText.general.dissatisfied[my_lang], 
      'trans_label' : $guiText.general.dissatisfaction[my_lang], 
      'percent' : 30,
      'percent_color' : 'rgb(48, 124, 62)',      
      'my_classes' :[ 'my_sub_grid_b', 'my_sub_grid_a', 'my_sub_grid_d', 'my_sub_grid_f', 'my_sub_grid_c', 'my_sub_grid_e' ]    
    },
    { 'label' : 'unhappy', 
      'id': '5_4_3_2', 
      'png': 'fke_torte_unhappy', 
      'trans' : $guiText.general.unhappy[my_lang],
      'trans_label' : $guiText.general.unhappiness[my_lang], 
      'percent' : 8,
      'percent_color' : 'rgb(166, 205, 138)',      
      'my_classes' :[ 'my_sub_grid_b', 'my_sub_grid_a', 'my_sub_grid_d', 'my_sub_grid_f', 'my_sub_grid_c', 'my_sub_grid_e' ]    
    } 
  ];

  const roleAdminStatus = checkForRoleAdmin($myAuthObject);
  const roleAudienceStatus = checkForRoleAudience($myAuthObject);
  const roleProjectStatus = checkForRoleProject($myAuthObject);

  // Handle Pie Chart PopUp
  let showModalChartPie = false;
  function toggleModalChartPie(e=true){ showModalChartPie = !showModalChartPie; }; 
  function toggleModalChartPieClick(label, id){ 
    popup_pie_label = $guiText.general[label][my_lang];
    popup_pie_id = id;
    popup_pie_header = $guiText.general.why_are_people[my_lang] + ' ' + $guiText.general[label][my_lang] + '?';
    showModalChartPie = !showModalChartPie; 
  }; 

  onMount(async () => { 
    if ( roleAdminStatus === false && roleAudienceStatus === false && roleProjectStatus === false ) { navigate('/auth/login', { replace: true }); };

    let tenantsRawResp = await tenantsGet({'authO': $myAuthObject, 'guiTxt': $guiText });
    tenantsAry = await tenantsRawResp.data; 
    let surveysRawResp = await surveysGet({'authO': $myAuthObject, 'guiTxt': $guiText });
    surveys_by_tenant = await surveysRawResp.by_tenant;
    
    tenantsLabel = tenantsAry[0].label;
    surveysLabel = surveys_by_tenant[tenantsAry[0].uuid][0]['label'];

    if ( $myAuthObject.projectId === '55109280-13fc-11ee-9534-376b5dabbac4' ) {
      tenantsLabel = 'Grabow';
      surveysLabel = 'Grabow';
    }

    let eleT = document.getElementById('admin_navbar_tenant');
    eleT.innerHTML = '';
    eleT.insertAdjacentHTML('beforeend', '<i class="fas fa-industry mr-2 text-sm text-blueGray-400"></i>');
    eleT.insertAdjacentText('beforeend', tenantsLabel);
    
    let eleP = document.getElementById('admin_navbar_project');
    eleP.innerHTML = '';
    eleP.insertAdjacentHTML('beforeend', '<i class="fas fa-square-poll-horizontal mr-2 text-sm text-blueGray-400"></i>');
    eleP.insertAdjacentText('beforeend', surveysLabel);      
  });

</script>

<style>

  main {
    display: grid;
    grid-template-columns: 50% 50%;
    grid-template-rows: 3vw 3vw auto auto auto auto;
    grid-gap: 0.5em;
  }

  .prosper_image_sub { object-fit: cover; height: 15vw; margin: auto; }   
  #prosper_header { grid-column: 1 / span 2; grid-row: 1; }
  #prosper_subheader { grid-column: 1 / span 2; grid-row: 2; } 
  #prosper_pie_satisfied { grid-column: 1; grid-row: 3; }
  #prosper_pie_unhappy { grid-column: 2; grid-row: 3; } 
  #prosper_pie_happy { grid-column: 1; grid-row: 4; }    
  #prosper_pie_unsatisfied { grid-column: 2; grid-row: 4; }    

  .my_sub_grid {
    display: grid;
    grid-template-columns: 50% 50%;
    grid-template-rows: 15% 65% 10% 10%;
  }

  .my_sub_grid_header { grid-column: 1 / span 2; grid-row: 1 }
  .my_sub_grid_a { grid-column: 1; grid-row: 2; }
  .my_sub_grid_b { grid-column: 2; grid-row: 2; }
  .my_sub_grid_c { grid-column: 1; grid-row: 3; }
  .my_sub_grid_d { grid-column: 2; grid-row: 3; }
  .my_sub_grid_e { grid-column: 1; grid-row: 4; }
  .my_sub_grid_f { grid-column: 2; grid-row: 4; }  

  @media (max-width: 640px) {
    main {
      display: grid;
      grid-template-columns: 100%;
      grid-template-rows: auto; 
      grid-gap: 1em;
    }

    #prosper_header { grid-column: 1; grid-row: 1; } 
    #prosper_subheader { grid-column: 1; grid-row: 2; }      
    #prosper_pie_satisfied { grid-column: 1; grid-row: 3; }  
    #prosper_pie_unhappy { grid-column: 1; grid-row: 4; } 
    #prosper_pie_happy { grid-column: 1; grid-row: 5; }
    #prosper_pie_unsatisfied { grid-column: 1; grid-row: 6; }    

    .my_sub_grid {
      display: grid;
      grid-template-columns: 100%;
      grid-template-rows: auto;
      grid-gap: 1em;
    }

    .my_sub_grid_header { grid-column: 1; grid-row: 1; }
    .my_sub_grid_a { grid-column: 1; grid-row: 2; }
    .my_sub_grid_b { grid-column: 1; grid-row: 3; }
    .my_sub_grid_c { grid-column: 1; grid-row: 4; }
    .my_sub_grid_e { grid-column: 1; grid-row: 5; }
    .my_sub_grid_d { grid-column: 1; grid-row: 6; }
    .my_sub_grid_f { grid-column: 1; grid-row: 7; }        

  }

</style>


{#if showModalChartPie}  
  <PieBPModal color={color}  
    popup_pie_id={popup_pie_id} 
    popup_pie_header={popup_pie_header} 
    toggleModalChartPie={toggleModalChartPie}
    body_text={$guiText.general.by_relative_impact_and_experience_range[my_lang]} 
    popup_pie_label={popup_pie_label}
    />
{/if}

{#if $myAuthObject.whoami.valid === true }

<main>

  <div id="prosper_header">
    <DashHeader 
      color="ffffff" 
      background_color="076623"  
      headline={$guiText.general.how_do_we_see_our_workday[my_lang]} 
      show_logo="show" />
  </div>

  <div id="prosper_subheader">
    <DashHeader 
      color="ffffff" 
      background_color="A6CD8A" 
      headline={$guiText.general.our_state_of_mind_and_what_has_shaped_it[my_lang]} />
  </div> 

  {#each pies as item}

    <div id="prosper_pie_{item.label}" class="prosper_dashb_column_d_body" >
      <div class="heightdefs">
        <div class="{defClassItem}">  
          <div class="prosper_dashb_mini_container">
            <div class="my_sub_grid">

              <div class="prosper_dashb_column_d_header py-2 my_sub_grid_header">
                <a href="#{item.label}" on:click={() => toggleModalChartPieClick( `${item.label}`, `${item.id}`)} >
                  {$guiText.general.we_who_are[my_lang]} {item.trans} {$guiText.general.sind[my_lang]}             
                </a>
              </div>

              <!-- my_sub_grid_a -->
              <div class="{item.my_classes[0]} text-sm font-normal content-center items-center justify-center w-full"> 
                <a href="#{item.label}" on:click={() => toggleModalChartPieClick( `${item.label}`, `${item.id}`)} >              
                  <img class="prosper_image_sub" alt="{item.label}" src="../assets/img/{item.png}.png" />
                </a>
              </div>

              <!-- my_sub_grid_b -->
              <div  class="{item.my_classes[1]} text-sm font-normal content-center items-center justify-center w-full" 
                    style="margin: auto;" 
                > 
                <div>
                  {$guiText.general.what_makes_us[my_lang]} {item.trans} {$guiText.general.in_our_workday_reality[my_lang]}?
                </div>
                <div>
                  {$guiText.general.the_top_reasons_that_are_key_to[my_lang]} {$guiText.general[item.label][my_lang]} {$guiText.general.among_ourselves[my_lang]}
                </div>
              </div>

              <!-- my_sub_grid_c -->
              <div class="{item.my_classes[2]} text-sm">
                  &nbsp;
              </div>            

              <!-- my_sub_grid_e -->
              <div class="{item.my_classes[3]} text-sm">
                <button
                  class="  font-bold uppercase text-xs rounded shadow hover:shadow-md outline-none focus:outline-none 
                          ease-linear transition-all duration-150 relative px-4 py-1 mr-1"
                  type="button"
                  style="align-items: flex-end; width: 80%; color: #ffffff; background-color: #A6CD8A;"
                  on:click={() => toggleModalChartPieClick( `${item.label}`, `${item.id}`)}
                >                 
                  {$guiText.general.go_to_the_chart[my_lang]}
                </button>
              </div>            

              <!-- my_sub_grid_d -->
              <div class="{item.my_classes[4]} text-sm"> 
                {$guiText.general.for_how_many_of_us_is_this_true[my_lang]}? 
              </div>       
              
              <!-- my_sub_grid_f -->
              <div class="{item.my_classes[5]} text-sm"> 
                  <button
                    class=" font-bold uppercase text-xs rounded shadow hover:shadow-md outline-none focus:outline-none 
                            ease-linear transition-all duration-150 relative px-4 py-1 mr-1"
                    type="button"
                    style="width: 80%; color: #ffffff; background-color: {item.percent_color};"
                    on:click={() => toggleModalChartPieClick( `${item.label}`, `${item.id}`)}
                  > 
                    {item.percent}%
                  </button>
              </div>                     

            </div>  
          </div>
        </div>
      </div>
    </div>

  {/each}

</main>
 
{/if}




