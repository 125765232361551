import {apiMaster} from "../api/ApiMaster";  

export async function shopProductsGet (data) {
    data.authO.loading = true;

    let requestOptions = {
        method: 'POST',
        headers: { 
            'Content-Type': 'application/json', 
            'X-Custom-Header': 'application/json' 
        },
        body: JSON.stringify({})
    };

    let resp = await apiMaster(
        data,
        `${data.authO.uri_rwnapi_api}/shop/products/get`, 
        requestOptions
    );

    // für statisches entwickeln

    // let resp = {
    //     "data" : {
    //         "onboarding" : [
    //             {'value': 1, 'label': 1, 'group': 'Employees', 'price' : 1000},
    //             {'value': 5, 'label': 5, 'group': 'Employees', 'price' : 2000}
    //         ],
    //         "versions" : [
    //             {'value': 1, 'label': 1, 'group': 'Versions', 'price' : 2},
    //             {'value': 5, 'label': 5, 'group': 'Versions', 'price' : 8}
    //         ],
    //         "selected" : { 
    //             "employees" : 78,
    //             "admins" : 3,
    //             "languages" : 4
    //         }          
    //     }
    // }





    return resp;
}